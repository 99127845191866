// Imports

import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

// Components

import CourseOverview from 'scripts/components/CourseOverview';
import BallotEntry from './Entry';
import BallotReview from './Review';

// Store

import { handleSetLoading, handleRemoveLoading } from 'scripts/store/actions/loader';

// Helpers

import fetch from 'scripts/helpers/fetch';

// Ballot Page

class BallotPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			ballotContent: [],
			courseContent: [],
		};
	}

	// Component Did Mount

	async componentDidMount() {
		this.props.dispatch(handleSetLoading());

		const courseResponse = await fetch(`/courses/${this.props.match.params.url}`, 'GET');

		if (!courseResponse) {
			this.props.dispatch(handleRemoveLoading());
			return;
		}

		const ballotContentResponse = await fetch(`/ballots/${courseResponse.content.ballot_id}/items`, 'GET');
		const ballotResponse = await fetch(`/ballots/response/${courseResponse.content.course_revision_id}`, 'GET');

		const ballotValues = ballotResponse.content.length > 0 ? ballotResponse.content[0].items : [];

		const newPages = ballotContentResponse.content.ballot_items.map((item, i) => {
			const reference = ballotValues.find((stateValue) => stateValue.id === item.id);

			if (item.type === 'slider' || item.type === 'percentageSlider') {
				return {
					id: item.id,
					value: reference ? reference.score : null,
				};
			}

			if (item.type === 'categories') {
				return {
					id: item.id,
					value: reference ? reference.categories : [],
				};
			}

			if (item.type === 'text') {
				return {
					id: item.id,
					value: reference ? reference.text : '',
				};
			}

			if (item.type === 'date') {
				return {
					id: item.id,
					value: reference ? reference.date : '',
				};
			}
		});

		this.setState({
			ballotContent: ballotContentResponse.content.ballot_items,
			courseContent: courseResponse.content,
			pages: newPages,
			ballot: ballotResponse.content,
		});

		this.props.dispatch(handleRemoveLoading());
	}

	// Update Ballot

	updateBallot(id, data) {
		const copyState = Array.from(this.state.pages);
		const index = copyState.findIndex((page) => page.id === parseInt(id));

		if (index >= 0) {
			copyState[index].value = data;

			this.setState({
				pages: copyState,
			});
		} else {
			console.log('Ballot reference page not found');
		}
	}

	// Render

	render() {
		if (!this.state.ballotContent) {
			return '';
		}

		const queryParms = this.props.location.search;
		const page = queryParms.slice(7, queryParms.length);
		let Component;

		if (parseInt(page) < this.state.ballotContent.length + 1) {
			const ballot = this.state.ballotContent[parseInt(page) - 1];

			Component = (
				<BallotEntry
					{...this.props}
					state={this.state.pages}
					ballot={ballot}
					course={this.state.courseContent}
					update={this.updateBallot.bind(this)}
				/>
			);
		} else {
			Component = (
				<BallotReview
					{...this.props}
					state={this.state.pages}
					ballot={this.state.ballot}
					ballots={this.state.ballotContent}
					course={this.state.courseContent}
					update={this.updateBallot.bind(this)}
				/>
			);
		}

		if (this.state.ballotContent.length === 0) {
			return null;
		} else {
			return <>{Component}</>;
		}
	}
}

export default connect((state) => ({}))(BallotPage);
