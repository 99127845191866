// Imports

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import flagIcon from '../../../assets/images/icons/golfweek-flag.svg';

// Macros

import SmallTextBlocks from 'scripts/macros/SmallTextBlocks';

// Helpers

import makeCourseSmallBlocks from 'scripts/helpers/makeCourseSmallBlocks';
import makeLocationString from 'scripts/helpers/makeLocationString';

// Course Results

function CourseResults(props) {
	const courses = props.courses;

	if (courses.length > 0) {
		return courses.map((course, i) => {
			const textBlocks = makeCourseSmallBlocks(course);

			return (
				<div key={`results-block=${i}`}>
					<Link to={`/course/detail/${course.slug}`}>
						<h2 className="medium-headline-serif flex items-center">
							<span>{course.name}</span>

							{course.requesting_ratings && (
								<img src={flagIcon} className="requesting-ratings" aria-hidden />
							)}
						</h2>
						{/*<MediumHeadline title={ course.name } url={ `/course/detail/${ course.slug }` } tag='h2' />*/}
						<SmallTextBlocks data={textBlocks} />
					</Link>
				</div>
			);
		});
	} else {
		return null;
	}
}

// Member Results

function MemberResults(props) {
	const members = props.members;

	if (members.length > 0) {
		return members.map((member, i) => {
			const location = makeLocationString(
				member.city,
				member.state_name ? member.state_name : member.state,
				member.country_name ? member.country_name : member.country
			);

			return (
				<div className="block" block-viewport="small" key={`member-${i}`}>
					<h2 className="medium-headline-serif">
						<Link to={`/profile/other/${member.id}`}>
							{member.first_name} {member.last_name}
						</Link>
					</h2>
					<div className="small-text-blocks">
						<p>{location} </p>
					</div>
				</div>
			);
		});
	} else {
		return (
			<div className="block" block-viewport="small">
				<h4 className="small-text-blocks">No results</h4>
			</div>
		);
	}
}

class SearchResults extends React.Component {
	render() {
		const { data, selectedFilter } = this.props;
		let results;

		if (this.props.type === 'course') results = <CourseResults courses={data} />;
		if (this.props.type === 'member') results = <MemberResults members={data} />;

		return (
			<div
				className={classNames('results', {
					'animated headShake': this.props.hasBorder,
				})}
			>
				<div className="wrapper">
					{this.props.type === 'course' && (
						<div className="flex-close items-end">
							<a
								href={`/search/results?filter_by=${selectedFilter}&search=${this.props.term}`}
								className="text-brandGreen font-bold"
							>
								View All Results
							</a>
							<p className="font-sans">Search Results for &lsquo;{this.props.term}&rsquo;</p>
						</div>
					)}
					{results}
					<div className="close-disclaimer">
						<button onClick={this.props.closeEvent} className="text-brandGreen font-bold">
							<span>Close Results</span>
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default SearchResults;
