// Imports

import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

// Macros

import RatingGraph from 'scripts/macros/RatingGraph';

// Components

import { Statistics } from 'scripts/components/CourseOverview';

// Ballot Text

function BallotText(props) {
	if (props.text && props.includeText) {
		// props.text.map( ( item, i ) => {

		return <p key={`ballot-description`} dangerouslySetInnerHTML={{ __html: props.text }} />;

		//  });
	}

	return null;
}

// Disclaimer Text

function DisclaimerText(props) {
	if (props.text && props.includeText) {
		// disclaimer = props.text.map( ( item, i ) => {

		return (
			<p key={`ballot-disclaimer`} className="disclaimer">
				{props.text}
			</p>
		);

		//  });
	}

	return null;
}

// Headline Number

function HeadlineNumber(props) {
	const propsType = props.type === 'slider' || props.type === 'percentageSlider';

	if (propsType && props.value) {
		return <RatingGraph rating={props.value} small="true" />;
	}

	return null;
}

// Macro : Ballot Actions

export default function (props) {
	const { data, course, showValue, placement, value, includeText, showOverallRating } = props;

	return (
		<div>
			<div className="ballot-headline">
				<h1 className="large-headline-sans" with-columns="true">
					{placement && <span>{placement}. </span>}
					<span>{data.title}</span>
				</h1>

				{showValue && <HeadlineNumber type={data.type} value={value} />}
			</div>

			<DisclaimerText text={data.disclaimer_text} includeText={includeText} />
			<BallotText text={data.description} includeText={includeText} />

			{data && data.title === 'Overall Rating' && showOverallRating && <Statistics course={course} />}
		</div>
	);
}
