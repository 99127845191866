// Imports

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components

import MemberList from 'scripts/components/List/Member';
import BallotSummaryDetails from 'scripts/components/BallotSummaryDetails';

// Macros

import MediumHeadline from 'scripts/macros/MediumHeadline';
import DetailDescriptions from 'scripts/macros/DetailDescriptions';

// Store

import { handleWishList } from 'scripts/store/actions/wish-list';
import { handleSetLoading, handleRemoveLoading } from 'scripts/store/actions/loader';

// Helpers

import fetch from 'scripts/helpers/fetch';
import makeLocationString from 'scripts/helpers/makeLocationString';
import makeCourseInfo from 'scripts/helpers/makeCourseInfo';
import { postWishList, deleteWishList } from 'scripts/helpers/api';

// Course Rated Disclaimer

function CourseRatedDisclaimer(props) {
	return (
		<p className="warning-sans">
			<span>You have not yet rated this course</span>
		</p>
	);
}

// Course Description

function CourseDescription(props) {
	if (props.course.description) {
		return (
			<div className="description">
				<MediumHeadline data={'Description'} tag="h3" />
				<p>{props.course.description}</p>
			</div>
		);
	} else {
		return null;
	}
}

// Course Links

function CourseLinks(props) {
	const url = props.course.url;
	const contactName = props.course.contact_name;
	const contactEmail = props.course.contact_email;

	// Course Contact Name

	function CourseContactName() {
		return contactName ? <p>{contactName}</p> : null;
	}

	// Course Email

	function CourseEmail() {
		return contactEmail ? (
			<p>
				<a href={`mailto:${contactEmail}`}>{contactEmail}</a>
			</p>
		) : null;
	}

	// Course URL

	function CourseUrl() {
		return url ? (
			<p>
				<a href={url} target="_blank">
					{url}
				</a>
			</p>
		) : null;
	}

	if (url) {
		return (
			<div>
				<CourseContactName />
				<CourseEmail />
				<CourseUrl />
			</div>
		);
	} else {
		return null;
	}
}

// Course Actions

class CourseActions extends React.Component {
	// Return

	render() {
		const { wishList, isRated, course, wishListEvent, deleteWishList, ballotItems } = this.props;
		let actions;
		let ballotCta;

		if (Array.isArray(wishList)) {
			const isAlreadyWishList = wishList.findIndex((item) => item.id === course.course_revision_id);

			if (isAlreadyWishList < 0) {
				actions = (
					<button className="call-to-action" course-id={course.id} onClick={(e) => wishListEvent(e, course)}>
						Add to My Wishlist
					</button>
				);
			} else {
				actions = (
					<button className="call-to-action" course-id={course.id} onClick={(e) => deleteWishList(e, course)}>
						Remove from Wishlist
					</button>
				);
			}
		}

		if (isRated) {
			ballotCta = (
				<Link to={`/ballot/${course.slug}?entry=${ballotItems.length + 1}`} className="call-to-action">
					View Ballot
				</Link>
			);
		} else {
			ballotCta = (
				<Link to={`/ballot/${course.slug}?entry=1`} className="call-to-action">
					Rate This Course
				</Link>
			);
		}

		return (
			<div className="actions">
				{actions}
				{ballotCta}
			</div>
		);
	}
}

// Course Details

class CourseDetails extends React.Component {
	// Constructor

	constructor(props) {
		super(props);
		this.state = {
			course: null,
			otherMembers: null,
			isRated: null,
			ballot: null,
		};
	}

	// Component Did Mount

	async componentDidMount() {
		this.props.dispatch(handleSetLoading());

		const courseResponse = await fetch(`/courses/${this.props.match.params.url}`, 'GET');
		const otherMembersResponse = await fetch(`/courses/${this.props.match.params.url}/members`, 'GET');
		const ballotContentResponse = await fetch(`/ballots/${courseResponse.content.ballot_id}/items`, 'GET');
		const ballotResponse = await fetch(`/ballots/response/${courseResponse.content.course_revision_id}`, 'GET');
		let isRated = false;

		if (ballotResponse.content.length > 0) {
			if (ballotResponse.content[0].items.length > 0) {
				isRated = true;
			}
		}

		this.setState({
			course: courseResponse.content,
			otherMembers: otherMembersResponse.content.course_members,
			ballot: ballotResponse.content[0] ? ballotResponse.content[0].items : [],
			ballotItems: ballotContentResponse.content ? ballotContentResponse.content.ballot_items : [],
			isRated,
		});

		this.props.dispatch(handleRemoveLoading());
	}

	// Event : Add To Wish List

	async addToWishList(event, course) {
		this.props.dispatch(handleSetLoading());
		await postWishList(course.id);
		this.props.dispatch(handleWishList());
		this.props.dispatch(handleRemoveLoading());
	}

	// Delete from Wish List

	async deleteFromWishList(event, course) {
		this.props.dispatch(handleSetLoading());
		await deleteWishList(course.id);
		this.props.dispatch(handleWishList());
		this.props.dispatch(handleRemoveLoading());
	}

	// Render

	render() {
		if (this.state.course) {
			const course = this.state.course;
			const location = makeLocationString(course.address1, course.city, course.state, course.full_country);
			const courseInfo = makeCourseInfo(course);
			const isRated = this.state.isRated;

			return (
				<two-column-layout>
					<first-column>
						<course-overview class="view-block" block-viewport="large">
							<section>
								{isRated === false && <CourseRatedDisclaimer />}

								<h1 className="large-headline-sans">{course.name}</h1>
								<div className="small-text-blocks">
									<p>{location}</p>
									<p>
										<a href={`tel:${course.phone}`}>{course.phone}</a>
									</p>
								</div>

								<div className="row justify-between">
									<CourseLinks course={course} />
									<ul className="block">
										{course.era && (
											<li>
												<strong>Era:</strong> {course.era}
											</li>
										)}
										{course.architect.length > 0 && (
											<li>
												<strong>Architect:</strong> {course.architect.join(', ')}
											</li>
										)}
										{course.type && (
											<li>
												<strong>Type:</strong> {course.type}
											</li>
										)}
										{course.season_start && (
											<li>
												<strong>Season Start:</strong> {course.season_start}
											</li>
										)}
										{course.season_end && (
											<li>
												<strong>Season End:</strong> {course.season_end}
											</li>
										)}
										{course.greens_fees && (
											<li>
												<strong>Greens Fees:</strong> {course.greens_fees}
											</li>
										)}
										{course.dress_code && (
											<li>
												<strong>Dress Code:</strong> {course.dress_code}
											</li>
										)}
										{course.spike_policy && (
											<li>
												<strong>Spike Policy:</strong> {course.spike_policy}
											</li>
										)}
										{course.club_rentals && (
											<li>
												<strong>Club Rentals:</strong> {course.club_rentals}
											</li>
										)}
										{course.tee_times && (
											<li>
												<strong>Tee Times:</strong> {course.tee_times}
											</li>
										)}
									</ul>
								</div>

								{course.description && (
									<div className="my-4 py-4 border-t border-gray-300">
										<h2 className="block font-bold mb-4">Description</h2>

										<p className="block">{course.description}</p>
									</div>
								)}

								<CourseActions
									{...this.props}
									course={course}
									isRated={isRated}
									ballotItems={this.state.ballotItems}
									wishList={this.props.wishList}
									wishListEvent={this.addToWishList.bind(this)}
									deleteWishList={this.deleteFromWishList.bind(this)}
								/>
							</section>
						</course-overview>
					</first-column>

					{(isRated || this.state.otherMembers.length > 0) && (
						<second-column>
							{isRated && (
								<BallotSummaryDetails
									ballot={this.state.ballot}
									ballotItems={this.state.ballotItems}
									course={course}
								/>
							)}

							<MemberList data={this.state.otherMembers} />
						</second-column>
					)}
				</two-column-layout>
			);
		} else {
			return null;
		}
	}
}

export default connect((state) => ({
	loading: state.loading,
	wishList: state.wishList,
}))(CourseDetails);
