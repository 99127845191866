// Import

import makeLocationString from './makeLocationString';

// Helper : Make Description

function makeDescription(course) {
    let string = '';
    if (course.description) string += `${course.description}`;
    return string;
}

// Helper : Make Architect

function makeArchitect(course) {
    return course.architect;
}

// Helper : Make Location

function makeLocation(course) {
    let string = '';
    if (course.architect) string += `${course.architect}`;
    return string;
}

// Export

export default function (course) {
    const block = [];
    const architectString = makeArchitect(course);
    const locationString = makeLocationString(
        course.city,
        course.full_state,
        course.full_country
    );

    if (locationString !== '') block.push(locationString);
    if (architectString !== '') block.push(architectString);

    return block;
}
