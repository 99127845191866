// import

import 'whatwg-fetch';

// Session

import session from 'scripts/store/storage/session';

// Export Post Fetch

export default function (path, method = 'POST', data = {}) {
    return new Promise(async (resolve, reject) => {
        let url;

        url = process.env.REACT_APP_API_URL;

        url += path;

        try {
            const headers = {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${session.getSessionToken()}`,
                },
            };

            if (method === 'POST' || method === 'PUT')
                headers['body'] = JSON.stringify(data);
            const response = await fetch(url, headers);

            if (response.ok) {
                if (method !== 'DELETE') {
                    const json = await response.json();
                    resolve({
                        error: false,
                        content: json,
                    });
                } else {
                    resolve({
                        error: false,
                    });
                }
            } else {
                const json = await response.json();
                resolve({
                    error: true,
                    message: json,
                });
            }
        } catch (error) {
            console.log(error);
            resolve({
                error: true,
                message: 'Error Connecting',
            });
        }
    });
}

export async function fetchFile(path, filename) {
    try {
        let url = `${process.env.REACT_APP_API_URL}${path}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json, application/vnd.ms-excel',
                Authorization: `Bearer ${session.getSessionToken()}`,
            },
        })
        if (!response.ok) {
            throw new Error("Something went wrong with the request");
        }

        const blob = await response.blob();
        const blobURL = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.download = filename;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();  //afterwards we remove the element again
        return true
    } catch (error) {
        console.error("File download failed", error);
        return false
    }
}