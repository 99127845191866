// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Macros

import ValidationErrors from 'scripts/macros/ValidationErrors';

function Options(props) {
    return props.data.map((item, i) => {
        return (
            <option value={item.value} key={`${props.name}-${i}`}>
                {item.text}
            </option>
        );
    });
}

// Component Select Input

class SelectInput extends React.Component {
    // Change Event

    changeEvent(event) {
        const element = event.target;
        const name = element.getAttribute('name');
        const value = element.options[element.selectedIndex].value;

        this.props.parentEvent(name, value, [
            {
                name: 'valid',
                value: true,
            },
            {
                name: 'error',
                value: false,
            },
        ]);

        if(this.props.getState){
            this.props.getState(value);
        }
    }

    // Render

    render() {
        const data = this.props.data;
        const state = this.props.state.fields.find(
            (entry) => entry.name === data.name
        );
        const value = state.value;

        return (
            <input-wrapper
                required={state.required}
                valid={state.valid}
                error={state.error}
            >
                <label>
                    <span>{data.label}</span>
                </label>

                <select
                    value={state.value}
                    name={data.name}
                    onChange={this.changeEvent.bind(this)}
                >
                    <option value="" disabled>
                        Select {data.name}
                    </option>
                    <Options data={data.options} name={data.name} />
                </select>

                <ValidationErrors
                    errorList={data.errors}
                    activeError={state.activeError}
                />
            </input-wrapper>
        );
    }
}

export default SelectInput;
