// Imports

import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

// Macro : Block Actions

export default function (props) {
	return (
		<p>
			{props.data.map((action, i) => {
				if (action.url) {
					return (
						<Link to={action.url} key={`cta-${i}`}>
							{action.text}
						</Link>
					);
				}

				if (action.event) {
					return <button key={`cta-${i}`}>{action.text}</button>;
				}
			})}
		</p>
	);
}
