// Imports

import storage from 'scripts/store/storage/session';
import { handleRehydrateUser } from './user';

// Variables

const ADD_SESSION = 'ADD_SESSION';
const REMOVE_SESSION = 'REMOVE_SESSION';
const CHECK_SESSION = 'CHECK_SESSION';

// Helpers

function addSession(token) {
    return {
        type: ADD_SESSION,
        payload: token,
    };
}

function removeSession() {
    return {
        type: REMOVE_SESSION,
    };
}

function checkSession(boolean) {
    return {
        type: CHECK_SESSION,
        payload: boolean,
    };
}

// Dispatch : Add

function handleAddSession(token) {
    return async (dispatch) => {
        dispatch(addSession(token));
    };
}

// Dispatch : Remove

function handleRemoveSession() {
    return async (dispatch) => {
        storage.removeSession();
        dispatch(removeSession());
    };
}

// Dispatch : Check State From Localstorage

function handleCheckSession() {
    return async (dispatch) => {
        const response = await storage.checkSessionActive();

        if (response.isValid) {
            const data = response.data;
            data.user
                ? dispatch(handleRehydrateUser(data.user))
                : dispatch(handleRemoveSession(false));
        }

        dispatch(checkSession(response.isValid));
        return response.isValid ? response.data.token : null;
    };
}

// Exports

export {
    ADD_SESSION,
    REMOVE_SESSION,
    CHECK_SESSION,
    handleAddSession,
    handleRemoveSession,
    handleCheckSession,
};
