// Imports

import {
    SET_OTHER_MEMBERS_BY_COURSE,
    SET_OTHER_MEMBERS_BY_LOCATION,
} from 'scripts/store/actions/other-members';
import update from 'immutability-helper';

const initialState = {
    membersByCourse: [],
    membersByLocation: [],
};

// Reducers

export default function courses(state = initialState, action) {
    if (action.type === SET_OTHER_MEMBERS_BY_COURSE) {
        return Object.assign({}, state, {
            membersByCourse: {
                ...state.membersByCourse,
                [action.course.id]: action.payload,
            },
        });
    }

    if (action.type === SET_OTHER_MEMBERS_BY_LOCATION) {
        return Object.assign({}, state, {
            membersByLocation: action.payload,
        });
    }

    return state;
}
