// Imports

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Flag } from 'assets/images/icons/golfweek-flag.svg';

// Wrapper

import List from 'scripts/wrappers/List';

// Macros

import MediumHeadline from 'scripts/macros/MediumHeadline';
import RatingGraph from 'scripts/macros/RatingGraph';

// Helpers

import fetch, { fetchFile } from 'scripts/helpers/fetch';

// Ballot List

class BallotList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			page: props.page,
		};
	}

	render() {
		return this.props.data.map((item, i) => {
			console.log('ITEM', item);

			let date_played = new Date(item.date_played.replace(/-/g, '/'));
			let month = '' + (date_played.getMonth() + 1);
			let day = '' + date_played.getDate();
			let year = date_played.getFullYear();

			if (month.length < 2) month = '0' + month;
			if (day.length < 2) day = '0' + day;

			date_played = [month, day, year].join('/');

			return (
				<tr key={`ballot-${i}`}>
					<td className="border px-4 py-2 border-lightGray text-sm text-mediumGray">{date_played}</td>
					<td className="border px-4 py-2 border-lightGray text-mediumGray">
						<div className="flex items-center">
							<MediumHeadline
								title={item.course.name}
								url={`/ballot/${item.course.slug}?entry=${item.ballot_items_count + 1}`}
								tag="h2"
								className="!mb-0"
							/>
							{item.course.requesting_ratings && <Flag className="w-4 h-4 ml-2" />}
						</div>
					</td>
					<td className="border px-4 py-2 border-lightGray text-sm text-mediumGray hidden sm:table-cell">
						{item.course.era}
					</td>
					<td className="border px-4 py-2 border-lightGray">
						<RatingGraph rating={item.score} small={true} className="m-auto" />
					</td>
				</tr>
			);
		});
	}
}

// Ballots

function Ballots(props) {
	const [ballots, setBallots] = useState(null);
	const [pagination, setPagination] = useState(null);
	const [years, setYears] = useState(null);
	const [filtered, setFiltered] = useState(false);

	async function fetchData() {
		let url = `/ballots/response?order_by=name&per_page=10`;

		if (props.year) {
			url += `&year=${props.year}`;
		}

		if (props.page) {
			url += `&page=${props.page}`;
		}

		fetch(url, 'GET')
			.then((response) => {
				if (response.error) {
					return Promise.reject(response);
				}

				setBallots(response.content.ballots);
				setPagination(response.content.pagination);

				return response;
			})
			.catch((response) => {
				console.log('RESPONSE', response);
				setBallots([]);
				setPagination([]);
			});

		// generate a list of years going back 10 years
		let maxYear = new Date().getFullYear();
		setYears(
			Array(maxYear - 1991)
				.fill('')
				.map((v, idx) => maxYear - idx)
		);
	}

	useEffect(() => {
		fetchData();
	}, [props.page, props.year]);

	function handleYearFilter(event) {
		setFiltered(true);

		if (event.target.value) {
			props.history.push(`/profile/detail/ballot/?year=${event.target.value || ''}`);
		} else {
			props.history.push(`/profile/detail/ballot`);
		}
	}

	async function exportBallots() {
		let url = `/ballots/response/export`;

		if (props.year) {
			url += `?year=${props.year}`;
		}
		await fetchFile(url, 'ballots.csv')
	}

	return (
		<List {...props} viewport='ballots'>
			{years && (
				<div className="sm:absolute relative top-0 right-0 flex">
					<div className='mr-2'>
						<span className="mr-2">Year:</span>
						<select className="px-4 border border-mediumGray p-2 rounded w-24 h-full" onChange={handleYearFilter}>
							<option value="">All</option>
							{years.map((year) => (
								<option value={year} key={year} selected={year === props.year}>
									{year}
								</option>
							))}
						</select>
					</div>          
					{!!ballots && !!ballots.length && (
						<div className="center-action">
							<button onClick={() => exportBallots()} className="call-to-action xs">
								Export All Ratings
							</button>
						</div>
					)}
				</div>
			)}
			{ballots && !ballots.length && !filtered && (
				<p className='py-2'>
					You do not have any course ratings.
					<br />
					<br />
					<Link to="/search/courses" className="text-brandGreen font-bold">
						Rate a course
					</Link>
				</p>
			)}

			{ballots && (
				<table className="table-auto w-full mt-10 md:mt-4 !bg-white">
					<thead className="bg-darkGray text-white">
						<tr>
							<th className="text-left px-4 py-2 font-normal text-sm rounded-tl">Date Played</th>
							<th className="text-left px-4 py-2 font-normal text-sm">Course</th>
							<th className="text-left px-4 py-2 font-normal text-sm hidden sm:table-cell">Era</th>
							<th className="text-left px-4 py-2 font-normal text-sm rounded-tr">Rating</th>
						</tr>
					</thead>
					<tbody>
						{ballots && (
							<>
								<BallotList data={ballots} page={props.page} />
							</>
						)}
					</tbody>
				</table>
			)}
			{!ballots && <div className="w-full text-center p-4">Loading...</div>}
			{ballots && !ballots.length && filtered && (
				<div className="w-full text-center p-4">There are no ballots for this year.</div>
			)}
			{pagination && (
				<div className="pagination">
					{pagination.prev_page && (
						<Link
							to={`/profile/detail/ballot/?${props.year ? 'year=' + props.year : ''}&page=${
								pagination.prev_page || ''
							}`}
							className="prev self-start"
							onClick={() => setBallots(null)}
						>
							Previous
						</Link>
					)}
					{pagination.next_page && (
						<Link
							to={`/profile/detail/ballot/?${props.year ? 'year=' + props.year : ''}&page=${
								pagination.next_page || ''
							}`}
							className="next self-end"
							onClick={() => setBallots(null)}
						>
							Next
						</Link>
					)}
				</div>
			)}
		</List>
	);
}

export default Ballots;
