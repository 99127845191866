// Export

export default function (user) {
    let bag = [];

    if (user.bag_driver)
        bag.push({ club: 'Driver:', description: user.bag_driver });
    if (user.bag_fairway_wood)
        bag.push({ club: 'Fairway Wood:', description: user.bag_fairway_wood });
    if (user.bag_irons)
        bag.push({ club: 'Irons:', description: user.bag_irons });
    if (user.bag_wedges)
        bag.push({ club: 'Wedges:', description: user.bag_wedges });
    if (user.bag_putter)
        bag.push({ club: 'Putter:', description: user.bag_putter });
    if (user.bag_ball) bag.push({ club: 'Ball:', description: user.bag_ball });
    if (user.bag_grips)
        bag.push({ club: 'Grips:', description: user.bag_grips });

    return bag;
}
