// Imports

import React from 'react';
import GlobalAlert from '../components/GlobalAlert';
import PriorityCourses from '../components/PriorityCourses';
import flagIcon from '../../assets/images/icons/golfweek-flag.svg';

// Store

import { connect } from 'react-redux';

// Components

import SearchCourses from 'scripts/components/Search/Courses';
import RegularColumn from 'scripts/components/Columns/Regular';
import DateColumn from 'scripts/components/Columns/Date';
import ProfileAlerts from 'scripts/components/Alerts';
import TextAccordion from 'scripts/components/TextAccordion';
import UserProfile from 'scripts/components/UserProfile';
import BallotSummary from 'scripts/components/BallotSummary';

// Helper

import * as moment from 'moment';
import findLastBallot from 'scripts/helpers/findLastBallot';

// Dashboard

class Dashboard extends React.Component {
	// Render

	render() {
		const now = new Date().getTime();
		const userEvents = this.props.events.userEvents.filter((event) => moment(event.end_date).valueOf() < now);
		const lastBallot = findLastBallot(this.props.ballots);

		let ballotSummaryActions = [];

		if (lastBallot) {
			ballotSummaryActions = [
				{
					text: 'Edit Ballot',
					url: `/ballot/${lastBallot.course.slug}?entry=${lastBallot.ballot_items_count + 1}`,
				},
				{
					text: 'View Ballots',
					url: `/profile/detail/ballot`,
				},
			];
		}

		return (
			<two-column-layout>
				<first-column>
					<GlobalAlert message={this.props.alerts.message} />

					<SearchCourses
						largeText={`Welcome ${this.props.user.first_name}!`}
						smallText={'Select a filter and enter search terms:'}
						placeholder={''}
						type={'course'}
						filters={{
							course_name: 'Search By Course Name',
							city: 'Search by City',
							state: 'Search by State',
							country: 'Search by Country',
							era: 'Search by Era',
							architect: 'Search by Architect',
						}}
					/>

					<RegularColumn
						title={'Courses Near You'}
						type={'regular'}
						data={this.props.coursesNear}
						cta={'near-you'}
						borderRadiusModification
					/>

					<ProfileAlerts data={this.props.slots.alerts} />

					<TextAccordion headline={'News Updates'} data={this.props.slots.news} />

					<DateColumn
						title={'Calendar of Events'}
						type={'date'}
						cta={'retreats-list'}
						events={this.props.events.events}
					/>

					<DateColumn title={'Events Attended'} type={'date'} cta={'events-attended'} events={userEvents} />
				</first-column>

				<second-column>
					<UserProfile
						user={this.props.user}
						includeEditActions={false}
						imageRight={true}
						mobileVisible={false}
						includeEmail={false}
					/>

					<BallotSummary data={lastBallot} actions={ballotSummaryActions} />
				</second-column>
			</two-column-layout>
		);
	}
}

export default connect((state) => ({
	user: state.user,
	wishList: state.wishList,
	slots: state.slots,
	events: state.events,
	requestingRating: state.requestingRating,
	coursesNear: state.coursesNear,
	ballots: state.ballots,
	alerts: state.alerts,
}))(Dashboard);
