// Imports

import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

// Wrapper

import List from 'scripts/wrappers/List';

// Macros

import MediumHeadline from 'scripts/macros/MediumHeadline';
import SmallTextBlocks from 'scripts/macros/SmallTextBlocks';

// Helpers

import makeCourseSmallBlocks from 'scripts/helpers/makeCourseSmallBlocks.js';

// Wish List

function WishList(props) {
    return props.data.map((course, i) => {
        const textBlocks = makeCourseSmallBlocks(course);

        return (
            <div className="view-block" block-viewport="small" key={`wish-${i}`}>
                <MediumHeadline
                    title={course.name}
                    url={`/course/detail/${course.slug}`}
                    tag="h2"
                />
                <SmallTextBlocks data={textBlocks} />
                <button
                    course-id={course.course_id}
                    className="close"
                    onClick={props.event}
                ></button>
            </div>
        );
    });
}

// Wish

class Wish extends React.Component {
    clickEvent(event) {
        event.preventDefault();
        const target = event.target;
        this.props.deleteEvent(target.getAttribute('course-id'));
    }

    render() {
        const data = this.props.data;

        return (
            <List {...this.props}>
				{!data.length && (
					<p class="mb-2">You don't have any courses in your wishlist.</p>
				)}

                <div className="rows">
                    <WishList data={data} event={this.clickEvent.bind(this)} />
                </div>
            </List>
        );
    }
}

export default Wish;
