// Imports

import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logos/golf-week-light.svg';

// Helpers

import fetch from 'scripts/helpers/fetch.js';

// Forgot Password

class ForgotPassword extends React.Component {
    // Constructor

    constructor(props) {
        super(props);
        this.state = {
            email: null,
            message: null,
        };
    }

    inputChange(event) {
        this.setState({
            email: event.target.value,
            message: null,
        });
    }

    async authenticate(event) {
        event.preventDefault();

        const response = await fetch('/users/forgot-password', 'POST', {
            email: this.state.email,
        });

        // if ( response.content.message  ) {
        this.setState({
            message: response.content
                ? response.content.message
                : 'There was an error resetting your password, please try again later.',
        });
        // }
    }

    render() {
        return (
            <centered-form>
                <div className="lock">
                    <img src={logo} alt="Golfweek Raters Logo" />

                    <form>
                        {this.state.message && (
                            <div className="error">
                                <p>{this.state.message}</p>
                            </div>
                        )}

                        <input-wrapper>
                            <input
                                type="email"
                                placeholder="Enter email"
                                autoComplete="email"
                                onChange={this.inputChange.bind(this)}
                            />
                        </input-wrapper>

                        <input
                            type="submit"
                            className="call-to-action"
                            onClick={this.authenticate.bind(this)}
                        />

                        <Link to="/">Back to Login</Link>
                    </form>
                </div>
            </centered-form>
        );
    }
}

export default ForgotPassword;
