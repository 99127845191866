// Imports

import React, { useEffect, useState } from 'react';

// Components

import RetreatList from 'scripts/components/List/Retreat';
import EventsAttendedList from 'scripts/components/List/EventsAttended';
import UserProfile from 'scripts/components/UserProfile';
import BallotSummary from 'scripts/components/BallotSummary';

// Store

import { connect } from 'react-redux';

// Helpers

import findLastBallot from 'scripts/helpers/findLastBallot';
import * as moment from 'moment';
import fetch from 'scripts/helpers/fetch';

// Search Retreats
const EventsAttended = ({ ballots, user }) => {
	const lastBallot = findLastBallot(ballots);
	const [userEvents, setUserEvents] = useState();
	const [pagination, setPagination] = useState();

	useEffect(() => {
		getEvents();
	}, []);

	const getEvents = async (page = 1) => {
		const response = await fetch(`/raters/${user.id}/events?page=${page}`, 'GET');

		setUserEvents(response.content.rater_events);
		setPagination(response.content.pagination);
	};

	let ballotSummaryActions = [];

	if (lastBallot) {
		ballotSummaryActions = [
			{
				text: 'Edit Ballot',
				url: `/ballot/${lastBallot.course.slug}?entry=${lastBallot.ballot_items_count}`,
			},
			{
				text: 'View Ballots',
				url: `/profile/detail/ballot`,
			},
		];
	}

	return (
		<two-column-layout>
			<first-column>
				{userEvents && pagination && (
					<EventsAttendedList
						getEvents={getEvents}
						title={'Retreats Attended'}
						data={userEvents}
						pagination={pagination}
						borderRadiusModification
					/>
				)}
			</first-column>

			<second-column>
				<UserProfile
					user={user}
					includeEditActions={false}
					imageRight={true}
					mobileVisible={false}
					includeEmail={false}
				/>

				<BallotSummary data={lastBallot} actions={ballotSummaryActions} />
			</second-column>
		</two-column-layout>
	);
};

// class EventsAttended extends React.Component {
// 	render() {
// 		const lastBallot = findLastBallot(this.props.ballots);
// 		const { events, user } = this.props;
//
// 		// await fetch(`/raters/${this.props.user.id}/events`, 'GET');
//
// 		let ballotSummaryActions = [];
//
// 		if (lastBallot) {
// 			ballotSummaryActions = [
// 				{
// 					text: 'Edit Ballot',
// 					url: `/ballot/${lastBallot.course.slug}?entry=${lastBallot.ballot_items_count}`,
// 				},
// 				{
// 					text: 'View Ballots',
// 					url: `/profile/detail/ballot`,
// 				},
// 			];
// 		}
//
// 		return (
// 			<two-column-layout>
// 				<first-column>
// 					<EventsAttendedList title={'Retreats Attended'} data={userEvents} borderRadiusModification />
// 				</first-column>
//
// 				<second-column>
// 					<UserProfile
// 						user={user}
// 						includeEditActions={false}
// 						imageRight={true}
// 						mobileVisible={false}
// 						includeEmail={false}
// 					/>
//
// 					<BallotSummary data={lastBallot} actions={ballotSummaryActions} />
// 				</second-column>
// 			</two-column-layout>
// 		);
// 	}
// }

export default connect((state) => ({
	user: state.user,
	courses: state.courses,
	events: state.events,
	ballots: state.ballots,
}))(EventsAttended);
