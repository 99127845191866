// Imports

import React from 'react';

// Progress Bar

class ProgressBar extends React.Component {
    // Render

    render() {
		const { data, total } = this.props;

        const style = {
            width: `${(data / total) * 100}%`,
        };

        return (
            <progress-bar class="view-block">
                <section>
                    <div>
                        <p>Progress</p>
                        <h1>Question {data} of {total}</h1>
                    </div>

                    <div className="bar">
                        <span style={style}></span>
                    </div>
                </section>
            </progress-bar>
        );
    }
}

export default ProgressBar;
