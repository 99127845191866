// Imports

import React from 'react';
import { ReactSVG } from 'react-svg';

// Wrappers

import BallotWrapper from 'scripts/wrappers/Ballot';

// Selector

class Selection extends React.Component {
    // Constructor

    constructor(props) {
        super(props);
        this.state = {};
    }

    // Update Event

    updateEvent(event) {
        event.preventDefault();

        const target = event.target;
        const data = Array.from(this.state);
        const parent =
            target.nodeName !== 'BUTTON' ? target.parentNode : target;
        const id = parseInt(parent.getAttribute('reference-id'));

        const index = data.findIndex((reference) => reference === id);
        if (index >= 0) data.splice(index, 1);
        if (index < 0 && !isNaN(id)) data.push(id);
        this.props.update(this.props.ballot.id, data);
    }

    // Render

    render() {
        const data = this.props.ballot;
        const state = Array.isArray(this.props.state)
            ? this.props.state.find((reference) => reference.id === data.id)
            : [];
        this.state = state.value;

        if (data.categories && data.categories.length > 0) {
            return (
                <BallotWrapper {...this.props}>
                    <category-selector>
                        {data.categories.map((entry, i) => {
                            const reference = state.value.find(
                                (value) => value === entry.id
                            );
                            const isActive = !!reference;

                            const image = require(`../../../assets/images/icons/${entry.icon}`);

                            return (
                                <button
                                    key={`category-item-${i}`}
                                    onClick={this.updateEvent.bind(this)}
                                    active={isActive.toString()}
                                    reference-id={entry.id}
                                >
                                    <ReactSVG src={image} />
                                    <span>{entry.title}</span>
                                </button>
                            );
                        })}
                    </category-selector>
                </BallotWrapper>
            );
        } else {
            return null;
        }
    }
}

export default Selection;
