// Imports

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SignupLink from './SignupLink';

// Wrapper

import List from 'scripts/wrappers/List';

// Macros

import DateWithUnderline from 'scripts/macros/DateWithUnderline';
import MediumHeadline from 'scripts/macros/MediumHeadline';

// Helpers

import makeLocationString from 'scripts/helpers/makeLocationString.js';
import fetch from 'scripts/helpers/fetch';

// Retreat List

function RetreatList(props) {
	return props.events.map((event, i) => {
		const locationText = makeLocationString(event.course_city, event.course_state, event.course_country_name);

		return (
			<div
				className="view-block flex-align flex flex-col-reverse items-start md:flex-row"
				block-viewport="small"
				key={`wish-${i}`}
			>
				<div className="w-full md:w-2/3 md:pr-8">
					<MediumHeadline title={event.name} tag="h2" />
					<div className="small-text-blocks">
						{event.course_name && <p>{event.course_name}</p>}
						{event.description && <p>{event.description}</p>}
						<SignupLink endDate={event.end_date} link={event.link} />
						{locationText}
					</div>
				</div>
				<DateWithUnderline data={event} className="w-full md:w-1/3 md:pl-8" />
			</div>
		);
	});
}

// Wish

const Retreat = (props) => {
	const [events, setEvents] = useState([]);
	const [pagination, setPagination] = useState([]);

	const page = props.page;

	useEffect(() => {
		async function fetchData() {
			let url = `/events?page=${page ?? 1}`;

			try {
				const response = await fetch(url, 'GET');
				if (response.error) {
					return Promise.reject(response);
				}
				setEvents(response.content.events);
				setPagination(response.content.pagination);
			} catch {
				setEvents([]);
				setPagination([]);
			}
		}
		fetchData();
	}, [page]);

	if (events.length === 0) {
		return <List title={'Calendar of Events'} />;
	}

	return (
		<List {...props}>
			<div className="rows">
				<RetreatList events={events} />

				{pagination && (
					<div className="pagination">
						{pagination.prev_page && (
							<Link
								to={`/search/retreats?page=${pagination.prev_page || ''}`}
								className="prev self-start"
								onClick={() => setEvents([])}
							>
								Previous
							</Link>
						)}
						{pagination.next_page && (
							<Link
								to={`/search/retreats?page=${pagination.next_page || ''}`}
								className="next self-end"
								onClick={() => setEvents([])}
							>
								Next
							</Link>
						)}
					</div>
				)}
			</div>
		</List>
	);
};

export default Retreat;
