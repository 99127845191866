// Imports

import React from 'react';

// Macro : Rating

export default function (props) {
    let rating = props.rating;

    if (rating > 10) rating = rating * 0.1;

    if (rating === null || rating === '-') {
        let svgSize = `0 0 72 72`;
        let pathSize =
            'M36 66C52.5685 66 66 52.5685 66 36C66 19.4315 52.5685 6 36 6';

        return (
            <div className="rating" small-size={props.small}>
                <svg viewBox={svgSize}>
                    <path className="background" d={pathSize} />
                </svg>
                <p className="rating-text" type="dash">
                    <span></span>
                </p>
            </div>
        );
    } else {
    	const strokeWidth = props.small ? 21 : 12;
		const radius = 50 - (strokeWidth / 2);
        const amount = `${2 * Math.PI * radius * rating/10}, ${2 * Math.PI * radius}`;
        let svgSize = `0 0 100 100`;

        return (
            <div className={`rating ${props.small ? 'w-10 h-10' : 'w-20 h-20'} ${props.className ? props.className : ''}`}>
				<svg className="canvas w-full h-full" viewBox={svgSize}>
					<circle
						className="background"
						cx="50"
						cy="50"
						r={radius}
						strokeWidth={strokeWidth}
						fill="none"
					/>
					<circle
						className="overlay"
						cx="50"
						cy="50"
						r={radius}
						strokeWidth={strokeWidth}
						fill="none"
						strokeDasharray={amount}
					/>
				</svg>
                <p className={`rating-text text-darkGray ${props.small ? 'text-sm' : 'text-3xl'}`}>{rating}</p>
            </div>
        );
    }
}
