// Check for Required

function checkRequried(value) {
    if (value.length === 0) return false;
    return true;
}

// Check for String

function checkForStrings(value) {
    if (/[^A-Za-z]/.test(value) === true) return false;
    return true;
}

// Check For Errors

export default function (element, list) {
    let isValid = true;

    return new Promise((resolve, reject) => {
        for (let i = 0; i < list.length; i++) {
            const error = list[i];

            if (error.name === 'required') {
                isValid = checkRequried(element.value);
            }

            if (error.name === 'string' && isValid === true) {
                isValid = checkForStrings(element.value);
            }

            if (!isValid) {
                resolve({
                    isValid,
                    error,
                });
                return;
            }

            if (i === list.length - 1) {
                resolve({
                    isValid,
                    error,
                });
            }
        }
    });
}
