// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Component

import Slide from './Slide.js';

// Slider

class Slider extends React.Component {
    // Constructor

    constructor(props) {
        super(props);
        this.sliderWrapper = React.createRef();
    }

    // Component Did Mount

    async componentDidMount() {
        this.sliderWrapperRef = this.sliderWrapper.current;
        this.wrapperRef = this.sliderWrapperRef.querySelector(
            'overflow-wrapper'
        );
        this.touchPosition = null;

        const slides = Array.from(
            this.sliderWrapperRef.querySelectorAll('slider-slide')
        );
        const index = slides.findIndex(
            (element) => element.getAttribute('active') === 'true'
        );
        const slidesLength = slides.length;
        const slideSize = await this.sizeSlides(
            this.sliderWrapperRef.offsetWidth,
            slides
        );

        this.wrapperRef.style.width = `${slideSize * slides.length}px`;
        this.wrapperRef.style.transform = `translateX( -${
            slideSize * parseInt(index - 1)
        }px )`;

        this.wrapperRef.style.visibility = 'visible';

        this.resizeEventReference = this.resizeEvent.bind(this);
        window.addEventListener('resize', this.resizeEventReference);

        this.touchStartReference = this.touchStartEvent.bind(this);
        this.touchEndReference = this.touchEndEvent.bind(this);

        this.sliderWrapperRef.addEventListener(
            'touchstart',
            this.touchStartReference
        );
        this.sliderWrapperRef.addEventListener(
            'touchend',
            this.touchEndReference
        );
    }

    // Component Will Unmount

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeEventReference);

        this.sliderWrapperRef.removeEventListener(
            'touchstart',
            this.touchStartReference
        );
        this.sliderWrapperRef.removeEventListener(
            'touchend',
            this.touchEndReference
        );
    }

    // Component Did Update

    componentDidUpdate(prevProps) {
        const animationValue = this.props.state.animationValue;
        const percentageSlide = this.props.state.percentageSlide;
        const percentageMove = this.props.state.rangeSlidePercentageMove;

        if (animationValue) {
            const slides = Array.from(
                this.sliderWrapperRef.querySelectorAll('slider-slide')
            );
            const index = slides.findIndex(
                (element) => element.getAttribute('active') === 'true'
            );
            const isRight = animationValue > index;

            this.props.setAnimationValue(1000);
            this.moveToNumber(animationValue, isRight);
            this.props.setValue('animationValue', null);

            setTimeout(() => {
                this.props.setValue('value', animationValue);
            }, this.props.state.animationSpeed + 100);
        }

        if (percentageSlide !== prevProps.state.percentageSlide) {
            this.wrapperRef.style.transform = `translateX( -${
                this.wrapperRef.offsetWidth * percentageSlide
            }px )`;
        }

        if (!animationValue && !percentageSlide) {
            if (
                percentageMove &&
                percentageMove !== prevProps.state.percentageMove
            ) {
                const containerWidth = this.wrapperRef.offsetWidth;
                let peices = containerWidth / 11;
                let resized = containerWidth - peices * 4;

                if (this.props.state.includesDecimals) {
                    peices = containerWidth / 101;
                    resized = containerWidth - peices * 4;
                }

                this.wrapperRef.style.transform = `translateX( -${
                    resized * percentageMove + peices
                }px )`;
                this.wrapperRef.style.transition = `none`;
            }
        }
    }

    // Helper : Size Slides

    sizeSlides(wrapperSize, slides) {
        return new Promise((resolve, reject) => {
            const slideSize = wrapperSize / this.props.numberToShow;
            const height = slides[0].offsetHeight;

            this.sliderWrapperRef.style.height = `${height}px`;

            slides.forEach((slide, i) => {
                slide.style.width = `${slideSize}px`;

                if (i === slides.length - 1) {
                    resolve(slideSize);
                }
            });
        });
    }

    // Helper : Center Index - Check for wrong index due to slider within Carousel functionality

    centerIndex(previousIndex, upcomingIndex) {
        const slides = Array.from(
            this.sliderWrapperRef.querySelectorAll('slider-slide')
        );

        if (upcomingIndex < 0) {
            return 0;
        }

        if (Math.abs(upcomingIndex - previousIndex) > 1) {
            return upcomingIndex + 1;
        }

        return upcomingIndex;
    }

    // Move To Number

    moveToNumber(value, isRight) {
        return new Promise(async (resolve, reject) => {
            const slides = Array.from(
                this.sliderWrapperRef.querySelectorAll('slider-slide')
            );
            const index = slides.findIndex(
                (element) => element.getAttribute('value') === value.toString()
            );
            const size = slides[0].offsetWidth;

            const movement = isRight ? index - 1 : index - 2;
            const position = this.centerIndex(index, movement);

            setTimeout(() => {
                this.wrapperRef.style.transform = `translateX( -${
                    size * position
                }px )`;
                this.wrapperRef.style.transition = `transform ${this.props.state.animationSpeed}ms`;
            }, 100);

            resolve();
        });
    }

    // Update Parent Value

    updateParentState(isRight) {
        const slides = Array.from(
            this.sliderWrapperRef.querySelectorAll('slider-slide')
        );
        const currentValue = slides.findIndex(
            (element) => element.getAttribute('active') === 'true'
        );
        const belowMax = isRight ? currentValue < slides.length - 2 : true;
        const aboveMin = !isRight ? currentValue > 2 : true;

        if (belowMax && aboveMin) {
            let index = isRight ? currentValue + 1 : currentValue - 1;
            let value = slides[index].getAttribute('value');

            this.props.setValue('animateController', parseFloat(value));

            setTimeout(() => {
                this.props.setValue('value', parseFloat(value));
                this.props.setValue('animateController', null);
            }, this.props.state.animationSpeed + 100);

            return value;
        } else {
            return null;
        }
    }

    // Event : Reszie

    async resizeEvent() {
        const slides = Array.from(
            this.sliderWrapperRef.querySelectorAll('slider-slide')
        );
        const index = slides.findIndex(
            (element) => element.getAttribute('active') === 'true'
        );

        const slidesLength = slides.length;
        const slideSize = await this.sizeSlides(
            this.sliderWrapperRef.offsetWidth,
            slides
        );

        this.wrapperRef.style.width = `${slideSize * slides.length}px`;
        this.wrapperRef.style.transform = `translateX( -${
            slideSize * parseInt(index - 1)
        }px )`;
    }

    // Event : Touch Start

    touchStartEvent(event) {
        const properties = event.touches[0];
        this.touchPosition = properties.clientX;
    }

    // Event : Touch End

    touchEndEvent(event) {
        const properties = event.changedTouches[0];
        const position = properties.clientX;

        if (position > this.touchPosition) {
            const value = this.updateParentState(false);
            if (value && value > 0) {
                this.props.setValue('value', '-');
                this.moveToNumber(value, false);
                this.props.disabledControls();
            }
        } else {
            const value = this.updateParentState(true);
            if (value && value < 11) {
                this.props.setValue('value', '-');
                this.moveToNumber(value, true);
                this.props.disabledControls();
            }
        }
    }

    // Event : Click

    buttonEvent(event) {
        const target = event.target;
        const element = target.nodeName === 'SPAN' ? target.parentNode : target;
        const isRight = element.getAttribute('data-direction') === 'right';
        const value = this.updateParentState(isRight);

        this.props.setValue('value', '-');
        this.moveToNumber(value, isRight);
        this.props.disabledControls();
    }

    // Event : Slide Click

    slideEvent(value) {
        const slides = Array.from(
            this.sliderWrapperRef.querySelectorAll('slider-slide')
        );
        const currentValue = slides.findIndex(
            (element) => element.getAttribute('active') === 'true'
        );
        const isRight = value < currentValue;

        this.props.setValue('value', '-');
        this.moveToNumber(value, isRight);
        this.props.disabledControls();
        this.props.setValue('animateController', parseFloat(value));

        setTimeout(() => {
            this.props.setValue('value', parseFloat(value));
            this.props.setValue('animateController', null);
        }, this.props.state.animationSpeed + 100);
    }

    // Render

    render() {
        const slides = this.props.state.slides;
        const value = this.props.state.value
            ? this.props.state.value.toString()
            : '-';
        let leftButtonState = this.props.state.controlsDisabled;
        let rightButtonState = this.props.state.controlsDisabled;
        let Slides;

        // Create Slides

        if (Array.isArray(slides)) {
            Slides = slides.map((item, i) => {
                return (
                    <Slide
                        value={item.value}
                        key={`slide-${i}`}
                        active={value === item.value.toString()}
                        clickEvent={this.slideEvent.bind(this)}
                    />
                );
            });
        }

        // Add Button Checks

        if (value === '-' || value === '1') {
            leftButtonState = true;
        }

        if (value === '10') {
            rightButtonState = true;
        }

        return (
            <number-slider>
                <button
                    data-direction="left"
                    disabled={leftButtonState}
                    onClick={this.buttonEvent.bind(this)}
                >
                    <span className="sr-only">Left</span>
                    <span></span>
                </button>

                <button
                    data-direction="right"
                    disabled={rightButtonState}
                    onClick={this.buttonEvent.bind(this)}
                >
                    <span className="sr-only">Right</span>
                    <span></span>
                </button>

                <slider-wrapper ref={this.sliderWrapper}>
                    <overflow-wrapper>
                        <slider-slide></slider-slide>
                        {Slides}
                        <slider-slide></slider-slide>
                    </overflow-wrapper>
                </slider-wrapper>
            </number-slider>
        );
    }
}

export default Slider;
