// Export

export default function (number) {
    const iteration = parseInt(number);
    let array = [];

    for (let i = 0; i <= iteration; i++) {
        if (iteration === 10) {
            if (i !== 0) {
                array.push({
                    value: i,
                });
            }
        } else {
            const value = parseFloat((i * 0.1).toFixed(1));

            if (value >= 1) {
                array.push({
                    value,
                });
            }
        }
    }

    array.unshift({
        value: '-',
    });

    return array;
}
