// Import

import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleRemoveSession } from 'scripts/store/actions/session';

// Header

class Dropdown extends React.Component {
    constructor(props) {
        super(props);

        this.escapeEventReference = this.escapeEvent.bind(this);
        this.tabEventReference = this.tabEvent.bind(this);
        this.headerProfile = React.createRef();
        this.button = null;

        this.state = {
        	hidden: true,
		}
    }

    // Tab Event

    tabEvent(event, element) {
        if (event.keyCode === 9 || event.key === 'Tab' || event.code === 'Tab') {
            if (!this.headerProfile.contains(event.target)) {
                this.close();
            }
        }
    }

    // Escape Event

    escapeEvent(event) {
        if (
            event.keyCode === 27 ||
            event.key === 'Escape' ||
            event.code === 'Escape'
        ) {
            this.close();
            this.button.focus();
        }
    }

    // Close

    close() {
    	this.setState({
			hidden: true,
		});
        document.removeEventListener('keydown', this.escapeEventReference);
        document.removeEventListener('keyup', this.tabEventReference);
    }

    // Open

    open() {
		this.setState({
			hidden: false,
		});
        document.addEventListener('keydown', this.escapeEventReference);
        document.addEventListener('keyup', this.tabEventReference);
    }

    // Click Event

    async clickEvent(event) {
        event.preventDefault();

        const target = event.target;
		this.button = target;

		const isHidden = this.state.hidden;

		if (isHidden === true) {
			this.open();
		} else {
			this.close();
		}
    }

    // Event : Logout

    logout(event) {
        // const isLogout = this.headerProfile.getAttribute('data-action') === 'logout';
		//
        // if (isLogout) {
        //     this.props.dispatch(handleRemoveSession());
        // }
		this.props.dispatch(handleRemoveSession());
    }

    // Render

    render() {
        const data = this.props.data;
        const links = data.links.map((link, i) => {
            if (link.type === 'navLink') {
                return (
                    <NavLink
                        exact
                        activeClassName="active"
                        to={link.to}
						onClick={this.props.closeEvent}
                        key={`dropdown-link-${i}`}
                    >
						<link.image className="w-8 h-8 block md:hidden fill-current mr-4" />
                        <span>{link.text}</span>
                    </NavLink>
                );
            }

            if (link.type === 'button') {
                return (
                    <button
                        key={'dropdown-link' + i}
                        data-action={link.action}
                        onClick={this.logout.bind(this)}
                    >
						<link.image className="w-8 h-8 block md:hidden fill-current mr-4" />
                        <span>{link.text}</span>
                    </button>
                );
            }
        });

        return (
            <header-dropdown id={data.id} class="mobile-menu" aria-hidden={this.state.hidden}>
                <button
                    aria-controls="header-profile"
                    className="desktop-button"
                    onClick={this.clickEvent.bind(this)}
                >
                    <span>{data.buttonTitle}</span>
                </button>
                <div id="header-profile" mobile-menu="true" ref={this.headerProfile} aria-hidden={this.state.hidden}>
                    <div className="close-wrapper">
                        <button
                            className="close"
                            reference={data.id}
                            onClick={this.props.closeEvent}
                        />
                    </div>
                    {links}
                </div>
            </header-dropdown>
        );
    }
}

export default connect((state) => ({
    session: state.session,
}))(Dropdown);
