// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Macros

import BallotActions from 'scripts/macros/BallotActions';
import BallotText from 'scripts/macros/BallotText';

// Wrappers

import BallotWrapper from 'scripts/wrappers/Ballot';

// Comment Text

class TextArea extends React.Component {
    // Constructor

    constructor(props) {
        super(props);
        this.textArea = React.createRef();
    }

    // Component Did Mount

    componentDidMount() {
        this.textAreaRef = this.textArea.current;
    }

    // Update Event

    updateEvent(event) {
        this.props.update(this.props.ballot.id, event.target.value);
    }

    // Render

    render() {
        const ballot = this.props.ballot;
        const data = this.props.data;
        const state = Array.isArray(this.props.state)
            ? this.props.state.find((reference) => reference.id === ballot.id)
            : '';

        return (
            <BallotWrapper {...this.props}>
                <textarea
                    ref={this.textArea}
                    placeholder="Your comments"
                    value={state.value}
                    onChange={this.updateEvent.bind(this)}
					className="border border-mediumGray"
                ></textarea>
            </BallotWrapper>
        );
    }
}

export default TextArea;
