// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Components

import Search from 'scripts/components/Search';
import MemberList from 'scripts/components/List/Member';
import UserProfile from 'scripts/components/UserProfile';

// Store

import { connect } from 'react-redux';
import { handleSetLoading, handleRemoveLoading } from 'scripts/store/actions/loader';

// Helpers

import makeLocationString from 'scripts/helpers/makeLocationString';

// Search Raters

class SearchRaters extends React.Component {
	// Render

	render() {
		const user = this.props.user;
		const location = makeLocationString(
			user.city,
			user.state_name ? user.state_name : user.state,
			user.country_name ? user.country_name : user.country
		);
		const otherLocationTitle = `Other Members near ${location}`;
		return (
			<two-column-layout>
				<first-column>
					<Search
						largeText={'Search Raters'}
						smallText={'Search:'}
						placeholder={'Enter Rater Name, City, etc'}
						type={'member'}
					/>

					<MemberList
						title={otherLocationTitle}
						type={'member'}
						data={this.props.otherMembers.membersByLocation}
						borderRadiusModification
					/>

					{user.courses.map((course) => (
						<MemberList
							key={course.id}
							title={`Other Members of ${course.name}`}
							type={'member'}
							data={this.props.otherMembers.membersByCourse[course.id]}
						/>
					))}
				</first-column>

				<second-column>
					<UserProfile
						user={user}
						includeEditActions={false}
						imageRight={true}
						mobileVisible={false}
						includeEmail={false}
					/>
				</second-column>
			</two-column-layout>
		);
	}
}

export default connect((state) => ({
	user: state.user,
	loading: state.loading,
	courses: state.courses,
	otherMembers: state.otherMembers,
}))(SearchRaters);
