// Imports

import React from 'react';
import { isBefore, format, addMinutes, isSameMonth, getYear } from 'date-fns';
import { Link } from 'react-router-dom';

// Function Date

const DateFormatter = (props) => {
	const { data, padding, includeYear } = props;

	const withPadding = padding ? padding : false;
	const startDate = new Date(data.start_date);
	const endDate = new Date(data.end_date);
	const startMonth = format(addMinutes(startDate, startDate.getTimezoneOffset()), 'MMMM');
	const endMonth = format(addMinutes(endDate, endDate.getTimezoneOffset()), 'MMM');
	const startDay = format(addMinutes(startDate, startDate.getTimezoneOffset()), 'd');
	const endDay = format(addMinutes(endDate, startDate.getTimezoneOffset()), 'd');
	const year = getYear(startDate);

	let dateString = startDay;

	if (startDay !== endDay) {
		dateString += ` - ${endDay}`;
	}

	return (
		<div className={`date ${props.className}`} with-padding={withPadding.toString()}>
			{isSameMonth(startDate, endDate) && <span className="uppercase">{startMonth}</span>}
			{!isSameMonth(startDate, endDate) && (
				<span className="!flex justify-between">
					<span className="!inline">{startMonth}</span> <span className="!inline">{endMonth}</span>
				</span>
			)}
			{includeYear ? <p>{year}</p> : <p>{dateString}</p>}
		</div>
	);
};

// Macro : Date With Underline
const DateWithUnderline = (props) => {
	const { url, data, className, includeYear } = props;

	let content;
	let startDate = new Date(data.start_date);
	let now = new Date();

	if (url && !isBefore(startDate, now)) {
		content = (
			<Link to={url}>
				<DateFormatter data={data} className={className} includeYear={includeYear} />
			</Link>
		);
	} else {
		content = <DateFormatter data={data} className={className} includeYear={includeYear} />;
	}

	return <>{content}</>;
};

DateWithUnderline.defaultProps = {
	url: null,
	includeYear: false,
};

export default DateWithUnderline;
