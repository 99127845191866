// Imports

import fetch from 'scripts/helpers/fetch';

// Variables

const SET_EVENTS = 'SET_EVENTS';

// Types

function setEvents(data) {
    return {
        type: SET_EVENTS,
        payload: data,
    };
}

// Dispatch : Handle Get Event from API

function handleSetEvents(user) {
    return async (dispatch) => {
        const response = await fetch('/events', 'GET');
        const userEvents = await fetch(`/raters/${user.id}/events`, 'GET');

        // Error

        if (response.error) {
            return response;
        }

        // Success

        if (!response.error) {
            const obj = {
                events: response.content.events,
                userEvents: userEvents.content.rater_events,
            };

            dispatch(setEvents(obj));
            return response;
        }
    };
}

// Exports

export { SET_EVENTS, handleSetEvents };
