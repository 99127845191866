// Imports

import React, { useEffect, useState } from 'react';

// Wrapper

import List from 'scripts/wrappers/List';

// Macros

import MediumHeadline from 'scripts/macros/MediumHeadline';
import SmallTextBlocks from 'scripts/macros/SmallTextBlocks';

// Helpers

import makeCourseSmallBlocks from 'scripts/helpers/makeCourseSmallBlocks.js';
import { Link } from 'react-router-dom';
import fetch from 'scripts/helpers/fetch';

// Courses

function CoursesList(props) {
	return props.data.map((course, i) => {
		const textBlocks = makeCourseSmallBlocks(course);

		return (
			<div className="view-block" block-viewport="small" key={`course-${i}`}>
				<MediumHeadline title={course.name} url={`/course/detail/${course.slug}`} tag="h2" />
				<SmallTextBlocks data={textBlocks} />
			</div>
		);
	});
}

// List : Courses

const Courses = (props) => {
	const [courses, setCourses] = useState([]);
	const [perPage, setPerPage] = useState(25);
	const [pagination, setPagination] = useState(null);
	const [search, setSearch] = useState(null);

	const page = props.page;

	async function fetchData() {
		let url = `/courses?page=${page ?? 1}`;

		fetch(url, 'POST', {
			order_by: "name",
			requesting_ratings: 1,
			per_page: perPage,
			sort: "asc",
			search:search
		})
			.then((response) => {
				if (response.error) {
					return Promise.reject(response);
				}

				setCourses(response.content.courses);
				setPagination(response.content.pagination);
				setSearch(response.content.search);
			})
			.catch((response) => {
				setCourses([]);
				setPagination([]);
			});
	}

	useEffect(() => {
		setCourses([]);
		setPagination([]);

		fetchData();
	}, [page, perPage]);

	if (courses.length === 0) {
		return '';
	}

	const dropdownOptions = () => {
		return (
			<div className="flex items-center mb-4 sm:absolute sm:right-0 sm:top-0 lg:relative xl:absolute xl:right-0 xl:top-0">
				<label htmlFor="results-per-page" className="mr-2 text-sm">Results Per Page</label>
				<select id="results-per-page" className="p-2 border border-darkGrayTint rounded" onChange={changePerPage} value={perPage}>
					<option value="25">25</option>
					<option value="50">50</option>
					<option value="100">100</option>
				</select>
			</div>
		)
	}

	const changePerPage = (event) => {
		setPerPage(event.target.value);
	}

	return (
		<List {...props} dropdown={dropdownOptions()}>
			<div className="rows">
				<CoursesList data={courses} />

				{pagination && (
					<div className="pagination">
						{pagination.prev_page && (
							<Link
								to={`/search/courses?page=${pagination.prev_page || ''}`}
								className="prev self-start"
								onClick={() => setCourses([])}
							>
								Previous
							</Link>
						)}
						{pagination.next_page && (
							<Link
								to={`/search/courses?page=${pagination.next_page || ''}`}
								className="next self-end"
								onClick={() => setCourses([])}
							>
								Next
							</Link>
						)}
					</div>
				)}
			</div>
		</List>
	);
};

export default Courses;
