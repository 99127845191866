// Imports

import React from 'react';
import _ from 'lodash';
import clone from 'lodash/clone';
import debounce from 'lodash/debounce';

import SearchResults from './results';
import { postSearchCourse, postSearchRater } from 'scripts/helpers/api';

import { ReactComponent as CloseRounded } from 'assets/images/icons/close-rounded.svg';
import { ReactComponent as DownChevron } from 'assets/images/icons/chevron-down.svg';

// Components

// Helpers

// Search

class SearchCourses extends React.Component {
	// Constructor

	constructor(props) {
		super(props);
		this.form = React.createRef();

		const selectedFilter = _.chain(this.props.filters).keys().first();

		this.state = {
			value: '',
			showResults: false,
			results: [],
			hasBorder: false,
			selectedFilter: selectedFilter,
			filtersOpen: false,
			focusedFilter: -1,
			orderBy: 'name',
			sort: 'asc',
		};

		this.originalState = clone(this.state);
	}

	// Component Did Mount

	componentDidMount() {
		this.wrapperRef = React.createRef();
		this.formRef = this.form.current;
		this.resultsReg = this.formRef.querySelector('.results');
		this.inputTextRef = React.createRef();
		this.body = document.querySelector('body');

		this.resizeEventReference = this.resizeEvent.bind(this);
		window.addEventListener('resize', this.resizeEventReference);
		document.addEventListener('mousedown', this.handleClickOutside.bind(this));

		if (this.props.searchParams) {
			setTimeout(() => {
				this.inputTextRef.current.value = this.props.searchParams.search;
			}, 0);

			this.setState({
				selectedFilter: this.props.searchParams.filter_by,
				value: this.props.searchParams.search,
			});
		}
	}

	// Component Will Unmount

	componentWillUnmount() {
		this.resultsReg.style.height = `inherit`;
		this.body.style.overflow = 'inherit';
		this.body.removeAttribute('style');

		window.removeEventListener('resize', this.resizeEventReference);
		document.addEventListener('mousedown', this.handleClickOutside.bind(this));
	}

	handleClickOutside(event) {
		if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
			this.setState({
				showResults: false,
				filtersOpen: false,
			});

			this.body.style.overflow = 'inherit';
		}
	}

	// Resize Event

	resizeEvent() {
		if (this.state.showResults) this.sizeResults();
	}

	// Size Results

	sizeResults() {
		const position = this.formRef.getBoundingClientRect();
		const height = window.innerHeight - position.top - this.formRef.offsetHeight - 20;

		this.resultsReg.style.height = `${height}px`;
		this.body.style.overflow = 'hidden';
	}

	handleKeyPress(event) {
		if (event.keyCode === 13 || event.which === 13) {
			event.preventDefault();

			this.setState({
				hasBorder: true,
			});

			setTimeout(() => {
				this.setState({
					hasBorder: false,
				});
			}, 500);
		}
	}

	// Close Event

	closeEvent(event) {
		event.preventDefault();

		this.resultsReg.style.height = `inherit`;
		this.body.style.overflow = 'inherit';

		this.setState({
			showResults: false,
		});
	}

	inputFocus(event) {
		event.preventDefault();

		this.setState({
			showResults: false,
			filtersOpen: false,
		});
	}

	inputChange = debounce((value) => {
		const type = this.props.type;
		let data = [];

		this.setState({
			value,
		});

		const postData = {
			search: value,
			filter_by: this.state.selectedFilter,
			order_by: this.state.orderBy,
			sort: this.state.sort,
		};

		if (value) {
			const response = type === 'course' ? postSearchCourse(postData) : postSearchRater(postData);

			response.then((response) => {
				if (type === 'course') data = response.content ? response.content.courses : [];
				if (type === 'member') data = response.content ? response.content.raters : [];

				if (data.length > 0) {
					this.setState({
						results: data,
						showResults: true,
					});

					this.sizeResults();
				}
			});
		} else {
			this.setState({
				results: [],
				showResults: false,
			});
		}
	}, 200);

	handleClear(event) {
		event.preventDefault();

		this.setState(this.originalState);

		this.inputTextRef.current.value = '';
		this.inputTextRef.current.focus();
		this.body.style.overflow = 'inherit';
	}

	toggleFiltersDropdown(event) {
		event.preventDefault();

		this.setState({
			filtersOpen: !this.state.filtersOpen,
		});
	}

	handleFilterSelect(event, selectedFilter) {
		event.preventDefault();

		this.setState({
			selectedFilter,
			filtersOpen: false,
		});

		this.inputTextRef.current.focus();

		this.inputChange(this.state.value);
	}

	render() {
		const { largeText, smallText, placeholder, type, filters } = this.props;
		const { results, showResults, hasBorder, value, selectedFilter, filtersOpen } = this.state;

		const inputFocusHandler = this.inputFocus.bind(this);

		return (
			<component-search class="view-block" with-results={this.state.showResults}>
				<section>
					<h1>{largeText}</h1>
					<form ref={this.form}>
						<label>{smallText}</label>

						<div ref={this.wrapperRef}>
							<div className="relative w-2/3">
								<input
									type="text"
									placeholder={placeholder}
									onKeyPress={this.handleKeyPress.bind(this)}
									onChange={(e) => this.inputChange(e.target.value)}
									className={`text-darkGray !rounded-l !rounded-r-none shadow bg-white border border-solid !border-lightGreen`}
									tabIndex={0}
									onFocus={inputFocusHandler}
									ref={this.inputTextRef}
								/>

								{this.state.value && (
									<button
										className="absolute inset-y-0 right-0 mr-4 opacity-25 hover:opacity-50 focus:opacity-50 focus:outline-none"
										onClick={this.handleClear.bind(this)}
										tabIndex={0}
									>
										<CloseRounded className="w-5 h-5" />
									</button>
								)}
								<SearchResults
									term={value}
									type={type}
									data={results}
									closeEvent={this.closeEvent.bind(this)}
									hasBorder={hasBorder}
									selectedFilter={selectedFilter.toString()}
								/>
							</div>
							<div className="relative w-1/3 h-auto">
								<button
									className="border border-solid border-lightGreen w-full h-full bg-white font-bold text-darkGray rounded-r"
									onClick={this.toggleFiltersDropdown.bind(this)}
									tabIndex={0}
								>
									<div className="flex justify-center items-center">
										<span>{filters[selectedFilter] || ''}</span>
										<DownChevron className="w-5 h-5 ml-2 fill-current" />
									</div>
								</button>
								{filtersOpen && (
									<div className="border border-lightGreen border-t-none shadow-xl rounded-b absolute left-0 w-full bg-white z-20 font-bold text-lightGreen text-center -mt-px">
										{Object.keys(filters).map((item, key) => {
											if (item === selectedFilter) {
												return;
											}
											return (
												<a
													href="#"
													className="block py-5 mx-5 text-lightGreen m-0 block border-b border-lightGray focus:text-brandGreen hover:text-brandGreen"
													key={key}
													tabIndex={0}
													onClick={(e) => this.handleFilterSelect(e, item)}
												>
													{filters[item]}
												</a>
											);
										})}
									</div>
								)}
							</div>
						</div>
					</form>
				</section>
			</component-search>
		);
	}
}

export default SearchCourses;
