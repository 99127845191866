// Imports

import { SET_COURSES_NEAR } from 'scripts/store/actions/courses-near';

// Reducers

export default function courses(state = [], action) {
    if (action.type === SET_COURSES_NEAR) {
        if (action.payload !== undefined) {
            return action.payload;
        }
    }

    return state;
}
