// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Wrapper

import List from 'scripts/wrappers/List';

// Clubs

function ClubList(props) {
    return props.data.map((item, i) => {
        return (
            <div key={`club-${i}`}>
                <h2 className="medium-headline-serif">{item.club}</h2>
                <p>{item.description}</p>
            </div>
        );
    });
}

// Courses

class Club extends React.Component {
    render() {
        const data = this.props.data;

        return (
            <List {...this.props}>
                <div className="rows view-block" view-block-viewport="small">
                    <ClubList data={data} />
                </div>
            </List>
        );
    }
}

export default Club;
