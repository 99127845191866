// Imports

import { SET_BALLOTS } from 'scripts/store/actions/ballots';

// Reducers

export default function courses(state = [], action) {
    if (action.type === SET_BALLOTS) {
        if (action.payload !== undefined) {
            return action.payload;
        }
    }

    return state;
}
