// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Macros

import BallotText from 'scripts/macros/BallotText';
import BallotActions from 'scripts/macros/BallotActions';

// Helpers

import makeArrayFromNumber from 'scripts/helpers/makeArrayFromNumber.js';

// Controller Buttons

function ControllerButtons(props) {
    if (props.list.length > 0) {
        return props.list.map((slide, i) => {
            const value = props.includesDecimals
                ? `${slide.value}.0`
                : slide.value;

            if (slide.value !== '-' && slide.value != '0') {
                return (
                    <button
                        disabled={props.isDisabled}
                        value={slide.value}
                        key={`controller-button-${i}`}
                        onClick={props.buttonEvent}
                    >
                        {value}
                    </button>
                );
            }
        });
    } else {
        return null;
    }
}

// Controller

class Controller extends React.Component {
    // Constructor

    constructor(props) {
        super(props);

        const numbers = makeArrayFromNumber(10);

        this.numberContainer = React.createRef();
        this.rangeContainer = React.createRef();

        this.slidesArray = numbers;
        this.dragging = false;
        this.position = 0;
    }

    // Component Did Mount

    componentDidMount() {
        this.numberContainerRef = this.numberContainer.current;
        this.rangeContainerRef = this.rangeContainer.current;

        this.selectorRef = this.rangeContainerRef.querySelector('p');
        this.conainerWidth = this.rangeContainerRef.offsetWidth;

        this.resizeEventReference = this.resizeEvent.bind(this);
        window.addEventListener('resize', this.resizeEventReference);

        // Init

        this.resizeEventReference();
        this.setSelectorToValue(this.props.state.value, false);
    }

    // Component Will Unmount

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeEventReference);
    }

    // Component Did Update

    componentDidUpdate(prevProps) {
        const animationValue = this.props.state.animationValue;
        const animationController = this.props.state.animateController;
        const value = this.props.state.value;

        if (animationValue) {
            this.setSelectorToValue(animationValue, true);
        }

        if (animationController) {
            this.setSelectorToValue(animationController, true);
        }
    }

    // Resize Event

    resizeEvent() {
        const value = this.props.state.value;

        if (value !== '-') {
            this.setSelectorToValue(value, false);
        }
    }

    // Set Selector To Value

    setSelectorToValue(value, transition = true) {
        if (value !== '-' && this.selectorRef && this.numberContainerRef) {
            const baseNumber = Math.floor(value * 1);
            const decimal = value.toString().split('.')[1];
            const element = this.numberContainerRef.querySelector(
                `button[value="${baseNumber}"]`
            );

            const containerPosition = this.rangeContainerRef.getBoundingClientRect();
            const position = element.getBoundingClientRect();

            let offset = 0;

            if (decimal) {
                const compareValue =
                    baseNumber > 2 ? baseNumber - 1 : baseNumber + 1;
                const compareElement = this.numberContainerRef.querySelector(
                    `button[value="${compareValue}"]`
                );
                const comparePosition = compareElement.getBoundingClientRect();
                const difference = Math.abs(
                    position.left - comparePosition.left
                );
                offset = (difference / 10) * decimal;
            }

            this.position = position.left - containerPosition.left + offset;
            this.selectorRef.style.left = `${this.position}px`;

            if (transition) {
                this.selectorRef.style.transition = `left ${this.props.state.animationSpeed}ms`;
            } else {
                this.selectorRef.style.transition = `none`;
            }
        }
    }

    // Event : Mouse Down

    onMouseDown(event) {
        if (!this.dragging) {
            this.dragging = true;
        } else {
            this.dragging = false;
        }
    }

    // Event : Mouse Move

    onMouseMove(event) {
        if (this.dragging) {
            const containerPosition = this.rangeContainerRef.getBoundingClientRect();
            const elementSize = this.selectorRef.offsetWidth;
            let position = event.clientX - containerPosition.left;

            const precision = position / this.conainerWidth;
            const maxWidth = this.conainerWidth - elementSize / 2;
            const isNotBelowZero = position < 0;
            const isAboveContainerWidth = position > maxWidth;

            if (isAboveContainerWidth) position = maxWidth;
            if (isNotBelowZero) position = 0;

            this.selectorRef.style.left = `${position}px`;
            this.position = position;

            this.props.setValue('rangeSlidePercentageMove', precision);
            this.props.setValue('controlsDisabled', false);
        }
    }

    // Event : Mouse Up

    releaseEvent() {
        if (this.dragging) {
            this.dragging = false;

            if (!this.props.includesDecimals) {
                const width = this.conainerWidth / 10;
                const precision = Math.round(
                    (this.position / (this.conainerWidth + width)) * 10
                ).toFixed(1);
                const number = Math.round(precision) + 1;

                setTimeout(() => {
                    this.props.setValue('rangeSlidePercentageMove', null);
                    this.props.setValue('animationValue', number);
                    this.setSelectorToValue(number, false);
                }, 100);
            } else {
                const width = this.conainerWidth / 10;
                const precision = (
                    (this.position / (this.conainerWidth + width)) *
                    10
                ).toFixed(1);
                const number = precision * 10;
                const value = precision / 1;
                const calcuation = value + 1;

                setTimeout(() => {
                    this.props.setValue('rangeSlidePercentageMove', null);
                    this.props.setValue('animationValue', calcuation);
                    this.setSelectorToValue(calcuation, false);
                }, 100);
            }
        }
    }

    // Button Event

    buttonEvent(event) {
        const target = event.target;
        const value = target.getAttribute('value');

        this.props.setValue('animationValue', value);
        this.props.disabledControls();
    }

    // Render

    render() {
        const isDisabled = this.props.state.controlsDisabled;
        const includesDecimals = this.props.state.includesDecimals;
        const value = this.props.state.value;
        const showing = value !== '-';

        return (
            <>
                <range-slider ref={this.rangeContainer}>
                    <div>
                        <p
                            onMouseDown={this.onMouseDown.bind(this)}
                            onMouseMove={this.onMouseMove.bind(this)}
                            onMouseUp={this.releaseEvent.bind(this)}
                            onMouseLeave={this.releaseEvent.bind(this)}
                            show-disabled={isDisabled.toString()}
                        >
                            <span></span>
                        </p>
                    </div>
                </range-slider>

                <number-controller>
                    <div className="numbers" ref={this.numberContainer}>
                        <div className="wrapper">
                            <ControllerButtons
                                list={this.slidesArray}
                                buttonEvent={this.buttonEvent.bind(this)}
                                includesDecimals={includesDecimals}
                                isDisabled={isDisabled}
                            />
                        </div>
                    </div>

                    <div className="colors">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </number-controller>
            </>
        );
    }
}

export default Controller;
