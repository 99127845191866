// Imports

import { SET_EVENTS } from 'scripts/store/actions/events';

const initialState = {
    events: [],
    userEvents: [],
};

// Reducers

export default function events(state = initialState, action) {
    if (action.type === SET_EVENTS) {
        if (action.payload !== undefined) {
            return action.payload;
        }
    }

    return state;
}
