// Imports

import { SET_USER } from 'scripts/store/actions/user.js';

// Inital State

const initialState = {
	image: null,
	description: null,
	raterSince: null,
	member: null,
	first_name: null,
	last_name: null,
	address1: null,
	address2: null,
	state: null,
	postal_code: null,
	city: null,
	country: null,
	country_code:null,
	state_code:null,
	gender: null,
	birthdate: null,
	handicap: null,
	ghin_number: null,
	bio: null,
	partner_is_rater: false,
	bag_driver: null,
	bag_fairway_wood: null,
	bag_irons: null,
	bag_wedges: null,
	bag_putter: null,
	bag_ball: null,
	bag_grips: null,
	courses: [],
};

// Reducers

export default function user(state = initialState, action) {
	if (action.type === SET_USER) {
		return Object.assign({}, state, action.payload);
	}

	return state;
}
