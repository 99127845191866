// Imports

import React from 'react';
import noPhotoPlaceholder from '../../../assets/images/placeholders/no-photo-placeholder.svg';

// Components

import BallotSummary from 'scripts/components/BallotSummary';
import TextInput from 'scripts/components/Form/TextInput';
import SelectInput from 'scripts/components/Form/SelectInput';
import TextAreaInput from 'scripts/components/Form/TextAreaInput';
import OptionsSelectInput from 'scripts/components/Form/OptionsSelectInput';

// Store

import { connect } from 'react-redux';
import { handleSetUser, handleRefreshUser } from 'scripts/store/actions/user';
import { handleSetLoading, handleRemoveLoading } from 'scripts/store/actions/loader';

// Session

import session from 'scripts/store/storage/session';

// Helpers

import scrollTo from 'scripts/helpers/scrollTo.js';
import convertBirthDate from 'scripts/helpers/convertBirthDate';
import findLastBallot from 'scripts/helpers/findLastBallot';
import { handleRehydrateUser } from '../../store/actions/user';
import moment from 'moment';
import fetch from 'scripts/helpers/fetch';
import Checkbox from 'scripts/components/Form/Checkbox';

const firstNameData = {
	label: 'First Name',
	name: 'first_name',
	placeholder: 'Enter first name',
	errors: [
		{
			name: 'required',
			text: 'First Name is Required',
		},
		{
			name: 'string',
			text: 'Input Can Only Contain Letters',
		},
	],
};

const lastNameData = {
	label: 'Last Name',
	name: 'last_name',
	placeholder: 'Enter last name',
	errors: [
		{
			name: 'required',
			text: 'Last Name is Required',
		},
		{
			name: 'string',
			text: 'Input Can Only Contain Letters',
		},
	],
};

const emailData = {
	label: 'Email Address',
	name: 'email',
	tooltip: 'Email support to change email',
	placeholder: 'Enter email address',
	errors: [
		{
			name: 'required',
			text: 'Email Address is Required',
		},
	],
};

const passwordData = {
	label: 'Password',
	type: 'password',
	name: 'password',
	placeholder: 'Enter password',
	tooltip: 'Only enter password if you want to create a new password',
};

const addressData = {
	label: 'Address',
	name: 'address1',
	placeholder: 'Enter address',
	errors: [
		{
			name: 'required',
			text: 'Address is Required',
		},
	],
};

const address2Data = {
	label: 'Address 2',
	name: 'address2',
	placeholder: 'Enter address line 2',
};

const locationData = {
	label: 'City',
	name: 'city',
	placeholder: 'Enter your city',
	errors: [
		{
			name: 'required',
			text: 'Location is Required',
		},
	],
};

const postalCodeData = {
	label: 'Postal Code',
	name: 'postal_code',
};

const phone1Data = {
	label: 'Phone 1',
	name: 'phone_1',
};

const phone2Data = {
	label: 'Phone 2',
	name: 'phone_2',
};

const genderData = {
	label: 'Gender',
	name: 'gender',
	options: [
		{
			value: '',
			text: '',
		},
		{
			value: 'male',
			text: 'Male',
		},
		{
			value: 'female',
			text: 'Female',
		},
	],
	errors: [
		{
			name: 'required',
			text: 'Gender is Required',
		},
	],
};

const dobData = {
	label: 'Date of Birth',
	name: 'birthdate',
	placeholder: 'xx/xx/xxxx',
	errors: [
		{
			name: 'date',
			text: 'Must be in the format mm/dd/yyyy',
		},
	],
};

const clubData = {
	label: 'Your Club',
	name: 'club[]',
};

const handicapData = {
	label: 'HCI',
	name: 'handicap',
};

const ghinData = {
	label: 'GHIN #',
	name: 'ghin_number',
};

const bioData = {
	label: 'Bio',
	placeholder: 'Enter a brief bio for yourself',
	name: 'bio',
};

const partnerIsRaterData = {
	label: 'Partner/Spouse is also rater?',
	checkboxLabel: 'Yes',
	name: 'partner_is_rater',
};

const driverData = {
	label: 'Driver',
	name: 'bag_driver',
};

const fairwayData = {
	label: 'Fairway Wood',
	name: 'bag_fairway_wood',
};

const ironsData = {
	label: 'Irons',
	name: 'bag_irons',
};

const wedgesData = {
	label: 'Wedges',
	name: 'bag_wedges',
};

const putterData = {
	label: 'Putter',
	name: 'bag_putter',
};

const ballData = {
	label: 'Ball',
	name: 'bag_ball',
};

const gripsData = {
	label: 'Grips',
	name: 'bag_grips',
};

// User Image Button

function UserImageButton(props) {
	const user = props.user;

	if (user.profile_image_link || props.stateImage) {
		const source = props.stateImage ? props.stateImage : user.profile_image_link;
		const style = {
			backgroundImage: `url( ${source} )`,
		};

		return (
			<button onClick={props.update}>
				<div className="user-profile-image" style={style} />
			</button>
		);
	} else {
		return (
			<button onClick={props.update}>
				<img src={noPhotoPlaceholder} alt="placeholder image" />
			</button>
		);
	}
}

// Profile Edit

class ProfileEdit extends React.Component {
	// Constructor

	constructor(props) {
		super(props);
		const { user, dispatch } = this.props;
		dispatch(handleRefreshUser());
		const birthdate = user.birthdate ? convertBirthDate.formatHuman(user.birthdate) : '';

		this.form = React.createRef();

		this.state = {
			loading: false,
			formValid: false,
			numberOfClub: 1,
			image: user.image ? user.image : null,
			country: { label: 'Country', name: 'country', options: [] },
			states: { label: 'State', name: 'state', options: [] },
			fields: [
				{
					name: 'profile_image',
					required: false,
					valid: true,
					error: false,
					activeError: null,
					value: user.profile_image ? user.profile_image : '',
				},
				{
					name: 'first_name',
					required: true,
					valid: !!user.first_name,
					error: false,
					activeError: null,
					value: user.first_name ? user.first_name : '',
				},
				{
					name: 'last_name',
					required: true,
					valid: !!user.last_name,
					error: false,
					activeError: null,
					value: user.last_name ? user.last_name : '',
				},
				{
					name: 'email',
					required: true,
					valid: !!user.email,
					error: false,
					activeError: null,
					value: user.email ? user.email : '',
					disabled: true,
				},
				{
					name: 'password',
					valid: !!user.password,
					error: false,
					activeError: null,
					value: user.password ? user.password : '',
				},
				{
					name: 'address1',
					required: true,
					valid: !!user.address1,
					error: false,
					activeError: null,
					value: user.address1 ? user.address1 : '',
				},
				{
					name: 'address2',
					required: false,
					valid: !!user.address2,
					error: false,
					activeError: null,
					value: user.address2 ? user.address2 : '',
				},
				{
					name: 'city',
					required: true,
					valid: !!user.city,
					error: false,
					activeError: null,
					value: user.city ? user.city : '',
				},
				{
					name: 'state',
					required: false,
					valid: !!user.state,
					error: false,
					activeError: null,
					value: user.state ? user.state_code : '',
				},
				{
					name: 'state_code',
					required: false,
					valid: !!user.state_code,
					error: false,
					activeError: null,
					value: user.state_code ? user.state_code : '',
				},
				{
					name: 'country_code',
					required: true,
					valid: !!user.country,
					error: false,
					activeError: null,
					value: user.country ? user.country_code : '',
				},
				{
					name: 'postal_code',
					required: true,
					valid: !!user.postal_code,
					error: false,
					activeError: null,
					value: user.postal_code ? user.postal_code : '',
				},
				{
					name: 'phone_1',
					required: true,
					valid: !!user.phone_1,
					error: false,
					activeError: null,
					value: user.phone_1 ? user.phone_1 : '',
				},
				{
					name: 'phone_2',
					required: false,
					valid: !!user.phone_2,
					error: false,
					activeError: null,
					value: user.phone_2 ? user.phone_2 : '',
				},
				{
					name: 'country',
					required: true,
					valid: !!user.country,
					error: false,
					activeError: null,
					value: user.country ? user.country_code : '',
				},
				{
					name: 'gender',
					required: false,
					valid: !!user.gender,
					error: false,
					activeError: 'required',
					value: user.gender ? user.gender : 'default',
				},
				{
					name: 'birthdate',
					required: false,
					valid: !!user.birthdate,
					error: false,
					activeError: null,
					value: birthdate,
				},
				{
					name: 'club',
					required: false,
					valid: false,
					error: false,
					value: user.club,
					elements: [],
				},
				{
					name: 'handicap',
					required: false,
					valid: false,
					error: false,
					activeError: null,
					value: user.handicap ? user.handicap : '',
				},
				{
					name: 'ghin_number',
					required: false,
					valid: false,
					error: false,
					activeError: null,
					value: user.ghin_number ? user.ghin_number : '',
				},
				{
					name: 'bio',
					required: false,
					valid: false,
					error: false,
					activeError: null,
					value: user.bio ? user.bio : '',
				},
				{
					name: 'partner_is_rater',
					required: false,
					valid: false,
					error: false,
					activeError: null,
					value: user.partner_is_rater ? user.partner_is_rater : false,
				},
				{
					name: 'bag_driver',
					required: false,
					valid: false,
					error: false,
					activeError: null,
					value: user.bag_driver ? user.bag_driver : '',
				},
				{
					name: 'bag_fairway_wood',
					required: false,
					valid: false,
					error: false,
					activeError: null,
					value: user.bag_fairway_wood ? user.bag_fairway_wood : '',
				},
				{
					name: 'bag_irons',
					required: false,
					valid: false,
					error: false,
					activeError: null,
					value: user.bag_irons ? user.bag_irons : '',
				},
				{
					name: 'bag_wedges',
					required: false,
					valid: false,
					error: false,
					activeError: null,
					value: user.bag_wedges ? user.bag_wedges : '',
				},
				{
					name: 'bag_putter',
					required: false,
					valid: false,
					error: false,
					activeError: null,
					value: user.bag_putter ? user.bag_putter : '',
				},
				{
					name: 'bag_ball',
					required: false,
					valid: false,
					error: false,
					activeError: null,
					value: user.bag_ball ? user.bag_ball : '',
				},
				{
					name: 'bag_grips',
					required: false,
					valid: false,
					error: false,
					activeError: null,
					value: user.bag_grips ? user.bag_grips : '',
				},
			],
		};
	}

	// Component Did Mount

	componentDidMount() {
		this.fetchCountry();
		const user = this.props.user;
		if (user.country) {
			this.fetchState(user.country);
		}
		this.formRef = this.form.current;
	}

	fetchCountry() {
		let url = '/country';
		fetch(url, 'GET')
			.then((response) => {
				let options = response.content.map((value) => {
					return { value: value.code, text: value.name };
				});
				const countryData = {
					label: 'Country',
					name: 'country',
					options: options,
				};
				this.setState((state) => {
					return { ...state, country: countryData };
				});
			})
			.catch((error) => {
				console.error(error);
			});
	}

	fetchState(value) {
		let url = `/country/${value}`;
		fetch(url, 'GET')
			.then((response) => {
				let options = response.content.map((value) => {
					return { value: value.code, text: value.code };
				});
				const stateData = {
					label: 'State',
					name: 'state',
					options: options,
				};
				this.setState((state) => {
					return { ...state, states: stateData };
				});
			})
			.catch((error) => {
				console.error(error);
			});
	}

	// Image Uplaod

	imageUpload(event) {
		event.preventDefault();

		const input = this.formRef.querySelector('input[type="file"]');

		input.addEventListener('change', async (event) => {
			const reader = new FileReader();
			const fields = Array.from(this.state.fields);

			reader.readAsDataURL(event.target.files[0]);
			reader.onloadend = function (image) {
				fields.forEach((field) => {
					if (field['name'] === 'profile_image') field['value'] = image.srcElement.result;
				});

				this.setState({
					image: image.srcElement.result,
					fields: fields,
				});
			}.bind(this);
		});
		input.click();
	}

	// Input Events From Child

	inputEvent(name, value, type, optionsValues) {
		const fieldsArray = Array.from(this.state.fields);

		fieldsArray.forEach((field) => {
			if (field.name === name) {
				field.value = value;
				type.forEach((attr) => {
					field[attr.name] = attr.value;
				});

				if (optionsValues) {
					field.elements = optionsValues;
				}
			}
		});

		this.setState({
			fields: fieldsArray,
		});
	}

	// Set View to First Error

	setViewToFirstError() {
		const firstError = this.formRef.querySelector(`input-wrapper[error='true']`);
		scrollTo(firstError, 30, 50);
	}

	// Validate Fields

	validateFields(target) {
		return new Promise((resolve, reject) => {
			const fields = this.state.fields;
			const formData = new FormData(target);
			let isValid = true;

			fields.forEach((field, i) => {
				// Validate Fields

				//Throw in fallback in case there are states that could be selected
				if (field.name === 'state') {
					if (this.state.states.options.length > 0) {
						field.required = true;
					}
				}

				if (field.required && !field.valid) {
					isValid = false;
					field.error = true;
				}

				// Create Object for Post

				if (field.name === 'birthdate') {
					if (moment(field.value, 'MM/DD/YYYY', true).isValid()) {
						const birthdateValue = field.value ? convertBirthDate.formatMachine(field.value) : '';
						formData.set(field.name, birthdateValue);
					} else {
						isValid = false;
						field.error = true;
						field.valid = false;
					}
				}

				// Conditions for error and success

				if (i === fields.length - 1) {
					if (!isValid) {
						this.setState({
							fields: fields,
						});

						setTimeout(() => {
							this.setViewToFirstError();
						}, 100);

						resolve({ valid: false });
					} else {
						resolve({
							valid: true,
							data: formData,
						});
					}
				}
			});
		});
	}

	// Submit Event

	async submitEvent(event) {
		event.preventDefault();
		const response = await this.validateFields(event.target);

		let url;

		url = `/raters/${this.props.user.id}`;

		if (response.valid) {
			this.props.dispatch(handleSetLoading());

			response.data.append('profile_image', this.state.image ? this.state.image : '');

			let object = {};
			response.data.forEach((value, key) => {
				let cleanedKey = key.replace('[]', '');

				if (!Reflect.has(object, cleanedKey) && key.includes('[]')) {
					object[cleanedKey] = [];
				}

				if (Reflect.has(object, cleanedKey)) {
					object[cleanedKey].push(value);
					return;
				}

				// Convert the booleans
				if (value === 'true' || value === 'false') {
					object[cleanedKey] = value === 'true';
					return;
				}

				object[cleanedKey] = value;
			});

			fetch(url, 'PUT', object)
				.then((response) => response.content)
				.then((json) => {
					console.log(json);
					this.props.dispatch(handleRehydrateUser(json));
					this.props.dispatch(handleRemoveLoading());
					this.props.history.push('/profile');
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}

	// Render

	render() {
		const user = this.props.user;
		const lastBallot = findLastBallot(this.props.ballots);
		const countryData = this.state.country;
		const stateData = this.state.states;

		return (
			<two-column-layout>
				<first-column>
					<update-form class="view-block" block-viewport="large">
						<section>
							<h1 className="sr-only">Edit Profile Form</h1>

							{this.state.fields.length > 0 && (
								<form onSubmit={this.submitEvent.bind(this)} ref={this.form}>
									<div className="photo-with-action">
										<UserImageButton
											stateImage={this.state.image}
											user={user}
											update={this.imageUpload.bind(this)}
										/>
										<input type="file" className="image-upload" />
										<input type="submit" className="call-to-action" value="Update Profile" />
									</div>

									{/* First and Last Name */}

									<div className="row">
										<TextInput
											data={firstNameData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
										<TextInput
											data={lastNameData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
									</div>

									{/* Email Address and Password */}

									<div className="row">
										<TextInput
											data={emailData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
										<TextInput
											data={passwordData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
									</div>

									{/* Location */}

									<div className="row">
										<TextInput
											data={addressData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
										<TextInput
											data={address2Data}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
									</div>

									{/* Gender, and DOB */}

									<div className="row">
										<SelectInput
											data={countryData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
											getState={this.fetchState.bind(this)}
										/>
										<SelectInput
											data={stateData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
									</div>

									<div className="row">
										<TextInput
											data={locationData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
										<TextInput
											data={postalCodeData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
									</div>

									<div className="row">
										<TextInput
											data={phone1Data}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
										<TextInput
											data={phone2Data}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
									</div>

									<div className="row">
										<SelectInput
											data={genderData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
										<TextInput
											data={dobData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
									</div>

									{/* Club, Handicap, and GHIN */}

									<div className="row">
										<OptionsSelectInput
											data={clubData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
											courses={this.props.user.courses}
											text_courses={this.props.user.text_memberships}
										/>

										<div className="row" viewport="small">
											<TextInput
												data={handicapData}
												parentEvent={this.inputEvent.bind(this)}
												state={this.state}
											/>
											<TextInput
												data={ghinData}
												parentEvent={this.inputEvent.bind(this)}
												state={this.state}
											/>
										</div>
									</div>

									{/* BIO */}

									<div className="row">
										<TextAreaInput
											data={bioData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
										<Checkbox
											data={partnerIsRaterData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
									</div>

									<h2 className="medium-headline-sans">What's in Your Bag?</h2>

									{/* Driver and Fairway Wood */}

									<div className="row">
										<TextInput
											data={driverData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
										<TextInput
											data={fairwayData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
									</div>

									{/* Irons and Wedges */}

									<div className="row">
										<TextInput
											data={ironsData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
										<TextInput
											data={wedgesData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
									</div>

									<div className="row">
										<TextInput
											data={putterData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
										<TextInput
											data={ballData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
									</div>

									<div className="row">
										<TextInput
											data={gripsData}
											parentEvent={this.inputEvent.bind(this)}
											state={this.state}
										/>
									</div>

									<input type="submit" className="call-to-action" value="Update Profile" />
								</form>
							)}
						</section>
					</update-form>
				</first-column>

				<second-column>
					<BallotSummary data={lastBallot} />
				</second-column>
			</two-column-layout>
		);
	}
}

export default connect((state) => ({
	user: state.user,
	ballots: state.ballots,
}))(ProfileEdit);
