// Imports

import * as moment from 'moment';
import user from './user';

// Variables

const sessionKey = 'session';

// Private : Rehydrate Session

function rehydrateSession() {
    return new Promise((resolve, reject) => {
        const userData = user.getUser();
        const session = getSessionToken();

        resolve({
            user: JSON.parse(userData),
            token: session,
        });
    });
}

// Set Session

function setSession(token) {
    const obj = {
        token: token,
        timestamp: new Date().getTime(),
    };

    localStorage.setItem(sessionKey, JSON.stringify(obj));
}

// Remove Session

function removeSession() {
    localStorage.removeItem(sessionKey);
    localStorage.removeItem(user.userKey);
}

// Check Session

function checkSessionActive() {
    return new Promise(async (resolve, reject) => {
        const session = JSON.parse(localStorage.getItem(sessionKey));

        if (session) {
            const dateString = session.timestamp;
            const now = new Date().getTime();
            const sessionDate = moment(dateString).add(60, 'minutes').valueOf();
            const isActiveSession = sessionDate > now;

            if (isActiveSession) {
                const data = await rehydrateSession();
                updateSessionTimestamp();

                resolve({
                    isValid: true,
                    data,
                });
            } else {
                resolve({ isValid: false });
                removeSession();
            }
        } else {
            resolve({ isValid: false });
        }
    });
}

// Get Session Token

function getSessionToken() {
    const session = JSON.parse(localStorage.getItem(sessionKey));

    if (session) {
        return session.token;
    }
}

// Update Session Timestamp

function updateSessionTimestamp() {
    const session = JSON.parse(localStorage.getItem(sessionKey));

    if (session) {
        const obj = {
            token: session.token,
            timestamp: new Date().getTime(),
        };

        localStorage.setItem(sessionKey, JSON.stringify(obj));
    }
}

// Export

export default {
    setSession,
    removeSession,
    checkSessionActive,
    getSessionToken,
    updateSessionTimestamp,
};
