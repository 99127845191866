// Imports

import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

// Macro : Call To Actions

export default function (props) {
    return (
        <div className="actions">
            {props.list &&
                props.list.map((link, i) => {
                    if (link.url) {
                        return (
                            <Link
                                to={link.url}
                                className="call-to-action"
                                key={`cta-${i}`}
                            >
                                {link.text}
                            </Link>
                        );
                    }
                })}
        </div>
    );
}
