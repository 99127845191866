import React, { useEffect, useState } from 'react';
import fetch from '../../helpers/fetch';

import { connect } from 'react-redux';

const differenceString = (result) => {
	if (result > 0) {
		return result;
	} else {
		return <span className={'text-red'}>({Math.abs(result)})</span>;
	}
};

const TwoColumnSummary = ({ title, userData, overallData }) => {
	const userCount = userData ? userData.count : 0;
	const userAverage = userData ? userData.average : 0;
	const overallCount = overallData ? overallData.count : 0;
	const overallAverage = overallData ? overallData.average : 0;

	const differenceAverage = `${parseFloat(userAverage - overallAverage).toFixed(2)}`;
	const differenceCount = userCount - overallCount;

	return (
		<div className="flex mt-2">
			<p className="text-left text-sm flex-none w-1/6">{title}</p>
			<div className="grid grid-cols-3 gap-4 text-center flex-1">
				<div className="flex sm:justify-between text-sm">
					<p className="flex-1 border-r-2 border-lightGray">{userCount}</p>
					<p className="flex-1">{userAverage}</p>
				</div>
				<div className="flex sm:justify-between text-sm">
					<p className="flex-1 border-r-2 border-lightGray">{overallCount}</p>
					<p className="flex-1">{overallAverage}</p>
				</div>
				<div className="flex sm:justify-between text-sm">
					<p className="flex-1 border-r-2 border-lightGray">{differenceString(differenceCount)}</p>
					<p className="flex-1">{differenceString(differenceAverage)}</p>
				</div>
			</div>
		</div>
	);
};

const OverallBallotSummary = ({ user: rater }) => {
	const [ballotSummary, setBallotSummary] = useState(null);

	useEffect(() => {
		async function fetchData() {
			const response = await fetch(`/ballots/summary`, 'GET').then((response) => response.content);

			setBallotSummary(response);
		}
		fetchData();
	}, []);

	if (ballotSummary) {
		const { overall_results: overall, user_results: user } = ballotSummary;

		return (
			<overall-ballot-summary class="view-block">
				<h1 className="medium-headline-sans flex">Ballot Summary</h1>
				<div className="flex">
					<strong className="text-left text-sm flex-none w-1/6">PANELIST</strong>
					<div className="grid grid-cols-3 gap-4 text-center flex-1">
						<div>
							<strong className="text-sm">
								{rater.first_name} {rater.last_name}
							</strong>
							<div className="flex sm:justify-between text-sm italic">
								<p className="flex-1"># Rated</p>
								<p className="flex-1">Avg. Rating</p>
							</div>
						</div>
						<div>
							<strong className="text-sm">Overall Panel</strong>
							<div className="flex sm:justify-between text-sm italic">
								<p className="flex-1">Avg. # Rated</p>
								<p className="flex-1">Avg. Rating</p>
							</div>
						</div>
						<div>
							<strong className="text-sm">Difference</strong>
							<div className="flex sm:justify-between text-sm italic">
								<p className="flex-1">Avg. # Rated</p>
								<p className="flex-1">Avg. Rating</p>
							</div>
						</div>
					</div>
				</div>
				<TwoColumnSummary
					title={'Modern Courses'}
					userData={user.modern_ballots}
					overallData={overall.modern_ballots}
				/>
				<TwoColumnSummary
					title={'Classic Courses'}
					userData={user.classic_ballots}
					overallData={overall.classic_ballots}
				/>
				<TwoColumnSummary title={'US Courses'} userData={user.us_ballots} overallData={overall.us_ballots} />
				<TwoColumnSummary
					title={'Non-US Courses'}
					userData={user.nonus_ballots}
					overallData={overall.nonus_ballots}
				/>
			</overall-ballot-summary>
		);
	} else {
		return <div></div>;
	}
};

export default OverallBallotSummary;
