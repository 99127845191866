// Imports

import fetch from 'scripts/helpers/fetch';

// Variables

const SET_ALERTS = 'SET_ALERTS';
const SET_NEWS = 'SET_NEWS';

// Types

function setAlerts(payload) {
    return {
        type: SET_ALERTS,
        payload: payload,
    };
}

function setNews(payload) {
    return {
        type: SET_NEWS,
        payload: payload,
    };
}

// Dispatch : Handle Get Slots Data

function handleSetSlots() {
    return async (dispatch) => {
        const responseAlerts = await fetch('/content?slot=alerts', 'GET');
        const responseText = await fetch('/content?slot=news', 'GET');

        // Success

        if (!responseAlerts.error) {
            dispatch(setAlerts(responseAlerts.content.content));
        }

        if (!responseText.error) {
            dispatch(setNews(responseText.content.content));
        }
    };
}

// Exports

export { SET_ALERTS, SET_NEWS, handleSetSlots };
