// Imports

import fetch from './fetch.js';

// Post Wish List

export function postWishList(id) {
    return new Promise(async (resolve, reject) => {
        const response = await fetch('/wishlist', 'POST', {
            course_id: id,
        });

        resolve(response);
    });
}

// Delete Wish List

export function deleteWishList(id) {
    return new Promise(async (resolve, reject) => {
        const response = await fetch(`/wishlist/${id}`, 'DELETE');
        resolve(response);
    });
}

// Add Event To User

export function postEvent(userId, eventId) {
    return new Promise(async (resolve, reject) => {
        const response = await fetch(`/raters/${userId}/events`, 'POST', {
            event_id: eventId,
        });
        resolve(response);
    });
}

// Search Courses By String

export function postSearchCourse(data, page = 1) {
    return new Promise(async (resolve, reject) => {
        const response = await fetch(`/courses?page=${page}`, 'POST', data);
        resolve(response);
    });
}

// Search Member By String

export function postSearchRater(string) {
    return new Promise(async (resolve, reject) => {
        const response = await fetch(`/raters`, 'POST', {
            search: string,
			date:Math.floor(Date.now())
        });
        resolve(response);
    });
}
