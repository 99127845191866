// Imports

import React from 'react';
import rankingImage from '../../../assets/images/ranking.jpg';
import * as moment from 'moment';
import { differenceInCalendarYears, isFuture, parseISO } from 'date-fns';

// Macros

import RatingGraph from 'scripts/macros/RatingGraph';

// Ballot Summary Submit

class BallotSummarySubmit extends React.Component {
	render() {
		if (this.props.ballots) {
			const overallBallot = this.props.ballots[11];

			const overallBallotState = this.props.state.find((item) => item.id === overallBallot.id);
			let containsError = this.props.state.filter((item) => item.value === null || item.value === '-');

			const datePlayed = this.props.state[0];
			if (
				datePlayed.value === '' ||
				isFuture(parseISO(datePlayed.value)) ||
				differenceInCalendarYears(new Date(), parseISO(datePlayed.value)) > 20
			) {
				containsError.push(datePlayed);
			}

			const showError = containsError.length > 0;
			let headline;
			let message;
			let lastSubmittedMessage;

			let ballot = this.props.ballot[0];
			let lastUpdated = ballot ? moment.utc(ballot.updated_at).format('MM/DD/YYYY') : null;
			lastSubmittedMessage = `You've successfully submitted your last ballot on ${lastUpdated}.`;

			if (showError) {
				headline = `Your rating is not ready to be submitted`;
				message = `Please choose a rating for all highlighted course categories before submitting your ballot.`;
			} else {
				headline = `Your rating is ready to be submitted!`;
				message = `You’ve given ${this.props.coursesData.name} an overall rating of ${overallBallotState.value}.`;
			}

			return (
				<ballot-summary class="view-block">
					<section>
						<h1 contains-error={showError.toString()}>{headline}</h1>

						<div className="info" ballot-page="true">
							<RatingGraph rating={overallBallotState.value} />

							<div>
								<p>{message}</p>

								{lastUpdated && <p>{lastSubmittedMessage}</p>}

								<p>
									<a href={rankingImage} target="_blank">
										View Ranking Guidelines
									</a>
								</p>
							</div>
						</div>

						<div className="center-action">
							<button onClick={this.props.submit} className="call-to-action">
								Submit Your Ballot
							</button>
						</div>
					</section>
				</ballot-summary>
			);
		} else {
			return null;
		}
	}
}

export default BallotSummarySubmit;
