// Imports

import React from 'react';
import BallotWrapper from 'scripts/wrappers/Ballot';
import { isFuture, parseISO, differenceInCalendarDays, isAfter, isBefore } from 'date-fns';

export const getDateError = (value) => {
	let error = {
		message: '',
		shouldStop: false,
	};

	if (value === '' || value === null) {
		error = {
			message: 'Please review the date entered and make any corrections to move ahead to the next section.',
			shouldStop: true,
		};

		return error;
	}

	if (
		isAfter(parseISO(value), new Date(1994, 11, 31, 23, 59, 59)) &&
		differenceInCalendarDays(new Date(), parseISO(value)) > 30
	) {
		error = {
			message:
				'You have entered a date played older than a month. It is the GW policy that all ratings ' +
				'should be entered within 1 month of date played. Please ensure that you enter all future ' +
				'ratings in a timely fashion (within 1 month of date played). ' +
				'Your compliance is being monitored by the rating staff.',
			shouldStop: false,
		};
	}

	if (isBefore(parseISO(value), new Date(1995, 0, 1, 0, 0, 0)) || isFuture(parseISO(value))) {
		error = {
			message: 'Please review the date entered and make any corrections to move ahead to the next section.',
			shouldStop: true,
		};
	}

	return error;
};

class DateBallot extends React.Component {
	// Constructor

	constructor(props) {
		super(props);
		this.textArea = React.createRef();
		this.state = {
			error: {
				message: '',
				shouldStop: false,
			},
		};
	}

	// Component Did Mount

	componentDidMount() {
		this.textAreaRef = this.textArea.current;
		const { ballot, location } = this.props;
		const state = Array.isArray(this.props.state)
			? this.props.state.find((reference) => reference.id === ballot.id)
			: '';

		if (this.props.reviewPage) {
			this.setState({
				error: getDateError(state.value),
			});
			return;
		}

		if (location && location.state && location.state.prevEntry) {
			if (location.state.prevEntry === 2) {
				this.setState({
					error: getDateError(state.value),
				});
				return;
			}
		}

		this.setState({
			error: {
				message: '',
				shouldStop: false,
			},
		});
	}

	// Update Event

	updateEvent(event) {
		this.props.update(this.props.ballot.id, event.target.value);
		const error = getDateError(event.target.value);
		this.setState({
			error,
		});
	}

	// Render

	render() {
		const { ballot } = this.props;
		const { error } = this.state;
		const state = Array.isArray(this.props.state)
			? this.props.state.find((reference) => reference.id === ballot.id)
			: '';

		return (
			<BallotWrapper {...this.props} errorMessage={error}>
				<input
					type="date"
					placeholder="yyyy-mm-dd"
					value={state.value}
					onChange={this.updateEvent.bind(this)}
					onKeyUp={this.updateEvent.bind(this)}
					ref={this.textArea}
					className="p-4 rounded border border-mediumGray w-full mb-4 mt-2"
					min="1992-01-01"
					max={new Date().toISOString().split('T')[0]}
				/>
			</BallotWrapper>
		);
	}
}

export default DateBallot;
