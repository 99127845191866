// Imports

import React from 'react';
import ReactHtmlParser, {convertNodeToElement} from 'react-html-parser';

import slice from 'lodash/slice';

// Components

import Accordion from 'scripts/wrappers/Accordion';

function AccordionList(props) {
	const { title, content } = props;

	const transform = (node, index) =>{
		if (node.type === "tag" && node.name === "a") {
			node.attribs.class = "link";
			return convertNodeToElement(node, index, transform);
		}
	};

	const options = {
		decodeEntities: true,
		transform
	};

	let parser = ReactHtmlParser(content, options);

	//    max-height: 0;
	//     transition: max-height 0.15s ease-out;

	return (
		<text-accordion class="view-block">
			<section>
				<h1 className="medium-headline-sans">{title}</h1>
				<Accordion>{parser}</Accordion>
			</section>
		</text-accordion>
	);
}

// News Accordion

class TextAccordion extends React.Component {
	render() {
		const data = this.props.data;

		return data.map((item, index) => {
			return <AccordionList key={index} {...item} />;
		});
	}
}

export default TextAccordion;
