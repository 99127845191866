// Variables

const userKey = 'user';

// Set User

function setUser(user) {
    console.log('updating the timestamp');
    console.log(user);
    user.timestamp = new Date().getTime();
    localStorage.setItem(userKey, JSON.stringify(user));
}

// Get User

function getUser() {
    return localStorage.getItem(userKey);
}

// Export

export default {
    userKey,
    setUser,
    getUser,
};
