import React from 'react';
import { ReactComponent as HandbookIcon } from '../../../assets/images/icons/handbook.svg';

class RaterHandbook extends React.Component {
    render() {
        const { handbook } = this.props;
        if (handbook) {
            return (
                <a href={handbook} target="_blank" download>
					<HandbookIcon className="w-8 h-8 block md:hidden fill-current mr-4" />
                    <span>Rater Handbook</span>
                </a>
            );
        }

        return null;
    }
}

export default RaterHandbook;
