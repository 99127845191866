// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Fixed Position Component

class FixedPositionComponent extends React.Component {
    // Constructor

    constructor() {
        super();
        this.container = React.createRef();
        this.isScrolling = false;
    }

    // Component Did Mount

    componentDidMount() {
        this.containerRef = this.container.current;
        this.wrapperRef = this.containerRef.querySelector('.wrapper');

        this.resizeEventReference = this.resizeEvent.bind(this);
        this.scrollEventReference = this.scrollEvent.bind(this);

        window.addEventListener('resize', this.resizeEventReference);
        window.addEventListener('scroll', this.scrollEventReference);
    }

    // Component Will Unmount

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeEventReference);
        window.removeEventListener('scroll', this.scrollEventReference);
    }

    // Event : Resize

    resizeEvent() {
        this.setComponentHeight();
        this.setComponentWidth();
    }

    // Set Height

    setComponentHeight() {
        this.containerRef.style.height = `${this.wrapperRef.offsetHeight}px`;
    }

    // Set Width

    setComponentWidth() {
        this.wrapperRef.style.width = `${this.containerRef.offsetWidth}px`;
    }

    // Event : Scroll

    scrollEvent() {
        const position = this.containerRef.getBoundingClientRect();
        const width = this.containerRef.offsetWidth;

        if (position.top <= 20) {
            this.wrapperRef.style.position = 'fixed';
            this.wrapperRef.style.top = '20px';
            this.setComponentWidth();
            if (!this.isScrolling) this.setComponentHeight();
            this.isScrolling = true;
        } else {
            this.wrapperRef.style.position = 'relative';
            this.wrapperRef.style.top = 'inherit';
            this.wrapperRef.style.width = 'inherit';
            this.isScrolling = false;
        }
    }

    // Render

    render() {
        return (
            <fixed-position ref={this.container}>
                <div className="wrapper">{this.props.children}</div>
            </fixed-position>
        );
    }
}

export default FixedPositionComponent;
