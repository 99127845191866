// Imports

import React from 'react';

// Text

function Text(props) {
    return props.data.map((item, i) => {
        if (typeof item === 'string') {
            return <p key={`detail-text-${i}`}>{item}</p>;
        }
    });
}

// Container

class Container extends React.Component {
    render() {
        return (
            <div className="small-text-blocks text-mediumGray">
                <Text data={this.props.data} />
            </div>
        );
    }
}

export default Container;
