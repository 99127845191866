// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Slider

class Slider extends React.Component {
    // Event : Click

    buttonEvent(event) {
        const target = event.target;
        const value = target.getAttribute('value');

        if (value !== '-') {
            this.props.clickEvent(value);
        }
    }

    // Render

    render() {
        const value = this.props.value;
        let active = this.props.active;
        let disabled = !this.props.active;
        let text = null;

        if (value == '-') disabled = true;

        if (value === 1 || value === 1.0) text = 'Poor';
        if (value === 5 || value === 5.0) text = 'Average';
        if (value === 10 || value === 10.0) text = 'Excellent';

        return (
            <slider-slide
                value={value}
                disabled={disabled}
                noSelect="true"
                active={active}
            >
                <p value={value} onClick={this.buttonEvent.bind(this)}>
                    {value}
                </p>
                <span>{text}</span>
            </slider-slide>
        );
    }
}

export default Slider;
