// Imports

import React from 'react';
import { Link } from 'react-router-dom';

// Wrapper

import List from 'scripts/wrappers/List';

// Helpers

import makeLocationString from 'scripts/helpers/makeLocationString';

// Image

function Image(props) {
	if (props.image) {
		return <img src={`${props.image}`} alt={`${props.name}`} />;
	} else {
		return null;
	}
}

// Handicap

function Handicap(props) {
	if (!props.handicap && !props.status) return '';
	if (!props.handicap && props.status) return <p>{props.status}</p>;
	if (props.handicap && !props.status) return <p>{props.handicap} HC</p>;
	return (
		<p>
			{props.status}, {props.handicap} HC
		</p>
	);
}

function RaterSince(props) {
	let string = '';
	if (props.year_joined) string += `Member since ${props.year_joined}`;
	return <p>{string}</p>;
}

// Member List

function MemberList(props) {
	if (props.data.length === 0) {
		return <h1 className="medium-headline-sans flex">No other members were found for this location</h1>;
	}

	return props.data.map((member, i) => {
		const location = makeLocationString(
			member.city,
			member.state_name ? member.state_name : member.state,
			member.country_name ? member.country_name : member.country
		);

		return (
			<div className="view-block" block-viewport="small" key={`member-${i}`}>
				<Image image={member.image} name={member.name} />
				<Link to={`/profile/other/${member.id}`} className="large-headline-serif">
					{member.first_name} {member.last_name}
				</Link>
				<div className="small-text-blocks">
					<p>{location}</p>
					<RaterSince year_joined={member.year_joined}></RaterSince>
					<Handicap status={member.status} handicap={member.handicap} />
				</div>
			</div>
		);
	});
}

// Member

class Member extends React.Component {
	render() {
		if (this.props.data) {
			const data = Array.from(this.props.data);
			const list = data.slice(0, 4);

			return (
				<List {...this.props}>
					<div className="member-list">
						<MemberList data={list} />
					</div>
				</List>
			);
		} else {
			return null;
		}
	}
}

export default Member;
