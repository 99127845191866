// Imports

import React from 'react';
import { isFuture, parseISO, differenceInCalendarYears, differenceInCalendarDays } from 'date-fns';

// Components

import CourseOverview from 'scripts/components/CourseOverview';
import BallotSummarySubmit from 'scripts/components/BallotSummarySubmit';
import TextAreaBallot from 'scripts/components/Ballot/TextArea';
import SelectionBallot from 'scripts/components/Ballot/Selection';
import SliderBallot from 'scripts/components/Ballot/Slider';
import DateBallot, { getDateError } from 'scripts/components/Ballot/Date';

// Store

import { connect } from 'react-redux';
import { handleBallots } from 'scripts/store/actions/ballots';
import { handleSetAlert } from 'scripts/store/actions/alerts';

// Wrapper

import FixedPositionComponent from 'scripts/wrappers/FixedPositionComponent';

// Helpers

import scrollTo from 'scripts/helpers/scrollTo';
import fetch from 'scripts/helpers/fetch';

// Components

function ComponentsBlock(props) {
	return props.ballots.map((ballot, i) => {
		if (ballot.type) {
			const key = `ballot-block-${i}`;
			const state = props.state.find((item) => item.id === ballot.id);
			let error = state ? state.value === null || state.value === '' || state.value === '-' : false;

			if (ballot.type === 'date') {
				if (
					state.value === '' ||
					isFuture(parseISO(state.value)) ||
					differenceInCalendarYears(new Date(), parseISO(state.value)) > 20 ||
					differenceInCalendarDays(new Date(), parseISO(state.value)) > 30
				) {
					error = true;
				}
			}

			let Component;

			if (ballot.type === 'text') {
				Component = (
					<TextAreaBallot
						{...props}
						ballot={ballot}
						Tag={'course-comment-rating'}
						includeSubmit={false}
						reviewPage={true}
					/>
				);
			}

			if (ballot.type === 'categories') {
				Component = (
					<SelectionBallot {...props} ballot={ballot} Tag={'course-select-rating'} reviewPage={true} />
				);
			}

			if (ballot.type === 'slider') {
				Component = (
					<SliderBallot
						{...props}
						ballot={ballot}
						error={error}
						Tag={'course-select-rating'}
						reviewPage={true}
					/>
				);
			}

			if (ballot.type === 'percentageSlider') {
				Component = (
					<SliderBallot
						{...props}
						ballot={ballot}
						error={error}
						Tag={'course-select-rating'}
						reviewPage={true}
						flag={true}
					/>
				);
			}

			if (ballot.type === 'date') {
				Component = (
					<DateBallot {...props} ballot={ballot} error={error} Tag={'course-date-rating'} reviewPage={true} />
				);
			}

			return <div key={key}>{Component}</div>;
		}
	});
}

// Ballot Review

class BallotReview extends React.Component {
	// Constructor

	constructor(props) {
		super(props);
		this.container = React.createRef();
	}

	// Component Did Mount

	componentDidMount() {
		this.containerRef = this.container.current;
	}

	// Submit Review Form

	async submitReviewForm(event) {
		let error = this.props.state.find((entry) => entry.value === null || entry.value === '-');
		const datePlayed = this.props.state[0];
		const { shouldStop } = getDateError(datePlayed.value);
		if (shouldStop) {
			error = datePlayed;
		}

		if (error) {
			const element = this.containerRef.querySelector(`[data-id="${error.id}"]`);
			scrollTo(element);
		}

		if (!error) {
			const obj = {};
			const response = {};
			obj['course_revision_id'] = this.props.course.course_revision_id;
			this.props.state.forEach((item) => {
				if (item.id === '45' || item.id === '46') {
					if (item.value.indexOf('/') !== -1) {
						//Date was submitted with / inside value
					}
				} else {
					response[item.id] = item.value;
				}
			});
			obj['responses'] = response;

			const serverResponse = await fetch(`/ballots/response`, 'POST', obj);

			if (!error) {
				this.props.dispatch(handleBallots());
				this.props.history.push('/');

				this.props.dispatch(handleSetAlert('Thank you! Your rating has been submitted!'));
			}
		}
	}

	// Render

	render() {
		const ballots = this.props.ballots;
		const course = this.props.course;

		return (
			<two-column-layout>
				<first-column ref={this.container}>
					<CourseOverview course={this.props.course} />
					<ComponentsBlock {...this.props} includeActions={false} />

					<div className="view-block">
						<div className="center-action">
							<button onClick={this.submitReviewForm.bind(this)} className="call-to-action">
								Submit Your Ballot
							</button>
						</div>
					</div>
				</first-column>

				<second-column>
					<FixedPositionComponent>
						<BallotSummarySubmit
							state={this.props.state}
							ballot={this.props.ballot}
							ballots={ballots}
							coursesData={course}
							submit={this.submitReviewForm.bind(this)}
						/>
					</FixedPositionComponent>
				</second-column>
			</two-column-layout>
		);
	}
}

export default connect((state) => ({}))(BallotReview);
