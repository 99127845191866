import React from 'react';

class SignupLink extends React.Component {
    render() {
        let { endDate, link } = this.props;

        if (new Date(endDate) > new Date()) {
            return (
                <a href={link} target="_blank" className="link">
                    More Information
                </a>
            );
        }

        return null;
    }
}

export default SignupLink;
