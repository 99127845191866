// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Macros

import DateWithUnderline from 'scripts/macros/DateWithUnderline';
import CallToActions from 'scripts/macros/CallToActions';

// Generic Block

class GenericBlock extends React.Component {
    render() {
        return (
            <generic-block class="view-block">
                <section>
                    {this.props.date && (
                        <DateWithUnderline date={this.props.date} />
                    )}

                    {this.props.callToActions && (
                        <CallToActions list={this.props.callToActions} />
                    )}
                </section>
            </generic-block>
        );
    }
}

export default GenericBlock;
