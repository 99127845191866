// Imports

import React from 'react';
import { Link } from 'react-router-dom';

// Macro : Medium Headline

export default function (props) {
    const data = props.data;
    const Tag = props.tag;
    let headline;

    if (props.url) {
        headline = (
            <Tag className={`medium-headline-serif ${props.className}`}>
                <Link to={`${props.url}`}>{props.title}</Link>
            </Tag>
        );
    } else if (props.title) {
        headline = <Tag className={`medium-headline-serif ${props.className}`}>{props.title}</Tag>;
    } else {
        headline = <Tag className={`medium-headline-serif ${props.className}`}>{data}</Tag>;
    }

    return <div>{headline}</div>;
}
