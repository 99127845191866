// Imports

import { applyMiddleware as dispatch } from 'redux';

// Variables

const SET_ALERT = 'SET_ALERT';
const CLEAR_ALERT = 'CLEAR_ALERT';

// Types

function setAlert(data) {
    return {
        type: SET_ALERT,
        payload: data,
    };
}

function clearAlert() {
    return {
        type: CLEAR_ALERT,
    };
}

function handleSetAlert(value) {
    return async (dispatch) => {
        dispatch(setAlert(value));

        setTimeout(() => {
            dispatch(clearAlert());
        }, 10000);
    };
}

// Exports

export { SET_ALERT, CLEAR_ALERT, handleSetAlert };
