// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Macros

import ValidationErrors from 'scripts/macros/ValidationErrors';

// Helpers

import checkForError from 'scripts/helpers/checkForInputError';

// Component : Text Area Input

class TextAreaInput extends React.Component {
    // Blur Event

    async blurEvent(event) {
        const element = event.target;
        const name = element.getAttribute('name');
        const errorInfo = Array.isArray(this.errorList)
            ? await checkForError(element, this.errorList)
            : { isValid: true };

        this.props.parentEvent(name, element.value, [
            {
                name: 'valid',
                value: errorInfo.isValid,
            },
            {
                name: 'error',
                value: !errorInfo.isValid,
            },
            {
                name: 'activeError',
                value: errorInfo.error ? errorInfo.error.name : null,
            },
        ]);
    }

    // Focus Event

    focusEvent(event) {
        const element = event.target;
        const name = element.getAttribute('name');

        this.props.parentEvent(name, element.value, [
            {
                name: 'error',
                value: false,
            },
            {
                name: 'valid',
                value: false,
            },
        ]);
    }

    // Render

    render() {
        const data = this.props.data;
        const state = this.props.state.fields.find(
            (entry) => entry.name === data.name
        );

        return (
            <input-wrapper
                required={state.required}
                valid={state.valid}
                error={state.error}
            >
                <label>
                    <span>{data.label}</span>
                </label>

                <textarea
                    name={data.name}
                    placeholder={data.placeholder}
                    onBlur={this.blurEvent.bind(this)}
                    onFocus={this.focusEvent.bind(this)}
                    defaultValue={state.value}
                ></textarea>

                <ValidationErrors
                    errorList={data.errors}
                    activeError={state.activeError}
                />
            </input-wrapper>
        );
    }
}

export default TextAreaInput;
