// Variables

const SET_LOADING = 'SET_LOADING';

// Helpers

function setLoading(bool) {
    return {
        type: SET_LOADING,
        payload: bool,
    };
}

// Dispatch : Add

function handleSetLoading() {
    return async (dispatch) => {
        dispatch(setLoading({ loading: true }));
    };
}

// Dispatch : Remove

function handleRemoveLoading() {
    return async (dispatch) => {
        dispatch(setLoading({ loading: false }));
    };
}

// Exports

export { SET_LOADING, handleSetLoading, handleRemoveLoading };
