// Imports

import React from 'react';

// Store

import { connect } from 'react-redux';

// User Profile

class Tooltip extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    // Render

    handleMouseOver() {
        this.setState({ show: true });
    }

    handleMouseOut() {
        let self = this;
        setTimeout(function () {
            self.setState({ show: false });
        }, 400);
    }

    render() {
        const { text } = this.props;
        const { show } = this.state;

        return (
            <div
                onMouseEnter={this.handleMouseOver.bind(this)}
                onMouseLeave={this.handleMouseOut.bind(this)}
                data-tooltip={true}
                style={{ position: 'relative' }}
            >
                {text && (
                    <div className={'tooltip fade-in ' + (show ? 'on' : '')}>
                        {text}
                    </div>
                )}
                {this.props.children}
            </div>
        );
    }
}

export default connect((state) => ({}))(Tooltip);
