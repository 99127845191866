// Imports

import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

// Store

import { connect } from 'react-redux';

// Helpers

import makeLocationString from 'scripts/helpers/makeLocationString';
import makeUserSummary from 'scripts/helpers/makeUserSummary';

// Actions

function Actions(props) {
	if (props.includeEditActions) {
		if (!props.includeEmail) {
			return (
				<Link to={'/profile/edit'} className="call-to-action">
					Edit Profile
				</Link>
			);
		}

		if (props.includeEmail) {
			if (props.user.email) {
				return (
					<a className="call-to-action" href={`mailto:${props.user.email} `}>
						Contact Rater
					</a>
				);
			} else {
				return null;
			}
		}
	} else {
		return null;
	}
}

// User Profile

class UserProfile extends React.Component {
	// Render

	render() {
		const user = this.props.user;
		const includeEditActions = this.props.includeEditActions;
		const includeEmail = this.props.includeEmail ? this.props.includeEmail : false;
		const isColumns = !this.props.imageRight;
		const imagePosition = this.props.imageRight ? 'right' : 'left';
		const isMobileVisibile = this.props.mobileVisible;
		let location = makeLocationString(
			user.city,
			user.state_name ? user.state_name : user.state,
			user.country_name ? user.country_name : user.country
		);
		let summary = makeUserSummary(user);
		let text;
		let description;
		let image;

		// Text

		if (Array.isArray(summary)) {
			text = summary.map((text, i) => {
				return <p key={`user-text-${i}`}>{text}</p>;
			});
		}

		// Description

		if (user.bio) {
			description = <p className="description">{user.bio}</p>;
		}

		// Image

		if (user.profile_image_link) {
			const style = {
				backgroundImage: `url( ${user.profile_image_link} )`,
			};
			image = <div className="user-profile-image" style={style}></div>;
		}

		return (
			<user-profile class="view-block" columns={isColumns} mobile-visible={isMobileVisibile}>
				<section>
					{imagePosition == 'left' && image}

					<div className="info">
						{imagePosition == 'right' && image}

						<div className="actions">
							<div className='flex-grow'>
								<h1>
									{user.first_name} {user.last_name}
								</h1>
								<h3>{location}</h3>
							</div>
							<Actions includeEditActions={includeEditActions} includeEmail={includeEmail} user={user} />
						</div>
						<div className="small-text-blocks">{text}</div>

						{description}
					</div>
				</section>

				<Actions includeEditActions={includeEditActions} includeEmail={includeEmail} user={user} />
			</user-profile>
		);
	}
}

export default connect((state) => ({}))(UserProfile);
