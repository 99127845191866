// Imports

import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { components } from 'react-select';
import _ from 'lodash';

// Helpers

import fetch from '../../helpers/fetch';

// Component : Options Select With Input

const NoOptionsMessage = (props) => {
    return (
        <components.NoOptionsMessage
            {...props}
            children="Start typing to find courses"
        />
    );
};

const MultiValue = (props) => {
    return (
        <components.MultiValue
            {...props}
            className={props.data.__isNew__ ? '' : 'exists'}
        >
            <span>{props.data.label}</span>
            <input
                type="hidden"
                name={props.data.__isNew__ ? 'club_texts[]' : 'club_ids[]'}
                value={props.data.value ? props.data.value : props.data.label}
            />
        </components.MultiValue>
    );
};

class OptionsSelectInput extends React.Component {
    loadOptions(inputValue) {
        if (inputValue) {
            return new Promise(async (resolve, reject) => {
                const response = await fetch(
                    `/courses/autocomplete/${inputValue}`,
                    'GET'
                )
                    .then((response) => response.content.courses)
                    .then((data) =>
                        data.map((item) => ({
                            value: item.id,
                            label: item.name,
                        }))
                    )
                    .catch((error) => error);
                resolve(response);
            });
        }
    }

    // Render

    render() {
        const { courses, text_courses } = this.props;

        let courseData = [];
        _.each(courses, (value) =>
            courseData.push({ label: value.name, value: value.id })
        );
        _.each(text_courses, (value) =>
            courseData.push({ label: value, value: value, __isNew__: true })
        );

        return (
            <div className="clubOptions">
                <input-wrapper>
                    <label>
                        <span className="club-label">Your Club(s)</span>
                    </label>

                    <AsyncCreatableSelect
                        isMulti
                        loadOptions={this.loadOptions}
                        className="creatable-container"
                        classNamePrefix="creatable"
                        components={{ MultiValue, NoOptionsMessage }}
                        defaultValue={courseData}
                    />
                </input-wrapper>
            </div>
        );
    }
}

export default OptionsSelectInput;
