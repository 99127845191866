// Imports

import * as moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';

// Alert List

class Alert extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showing: true,
		};
	}

	componentDidMount() {
		const alerts = JSON.parse(localStorage.getItem('alerts')) || {};

		if (Object.keys(alerts, this.props.data.id) && alerts[this.props.data.id] === true) {
			this.setState({
				showing: false,
			});
		}
	}

	handleClose(event) {
		const alerts = JSON.parse(localStorage.getItem('alerts')) || {};

		alerts[this.props.data.id] = true;

		localStorage.setItem('alerts', JSON.stringify(alerts));

		this.setState({
			showing: false,
		});
	}

	render() {
		const date = moment();
		const { data } = this.props;
		console.log('ITEM', data);
		const startDate = moment(data.scheduled_start ? data.scheduled_start : data.created_at);

		const daysAgo = Math.abs(startDate.diff(date, 'days'));

		if (this.state.showing === false) {
			return '';
		}

		return (
			<alerts-block class="view-block" ref={this.container}>
				<h1 className="sr-only">Alerts</h1>
				<div className="decoration" />
				<button onClick={this.handleClose.bind(this)} />
				<div>
					<h2>{data.title}</h2>
					<p dangerouslySetInnerHTML={{ __html: data.content }}></p>
					<span>{`${daysAgo} days ago`}</span>
				</div>
			</alerts-block>
		);
	}
}

// Alerts

class Alerts extends React.Component {
	// Constructor

	constructor(props) {
		super(props);
		this.container = React.createRef();

		this.state = {
			showing: true,
			activeSession: false,
		};
	}

	render() {
		const { data } = this.props;

		return (
			<>
				{data.map((item, i) => (
					<Alert key={i} data={item} />
				))}
			</>
		);
	}
}

export default Alerts;
