// Imports

import fetch from 'scripts/helpers/fetch';

// Variables

const SET_WISH_LIST = 'SET_WISH_LIST';

// Types

function setWishList(data) {
    return {
        type: SET_WISH_LIST,
        payload: data,
    };
}

// Dispatch : Handle Set Wish List From API

function handleWishList() {
    return async (dispatch) => {
        await fetch('/wishlist', 'GET')
            .then((response) =>
                dispatch(setWishList(response.content.rater_wishlist))
            )
            .catch((error) => console.error(error));
    };
}

// Exports

export { SET_WISH_LIST, handleWishList };
