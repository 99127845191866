// Imports

import React from 'react';

// Components

import RetreatList from 'scripts/components/List/Retreat';
import UserProfile from 'scripts/components/UserProfile';
import BallotSummary from 'scripts/components/BallotSummary';

// Store

import { connect } from 'react-redux';

// Helpers

import findLastBallot from 'scripts/helpers/findLastBallot';

// Search Retreats

class SearchRetreats extends React.Component {
	// Render

	render() {
		const lastBallot = findLastBallot(this.props.ballots);
		console.log(this.props);
		let ballotSummaryActions = [];

		if (lastBallot) {
			ballotSummaryActions = [
				{
					text: 'Edit Ballot',
					url: `/ballot/${lastBallot.course.slug}?entry=${lastBallot.ballot_items_count}`,
				},
				{
					text: 'View Ballots',
					url: `/profile/detail/ballot`,
				},
			];
		}

		return (
			<two-column-layout>
				<first-column>
					<RetreatList title={'Calendar of Events'} page={this.props.page} borderRadiusModification />
				</first-column>

				<second-column>
					<UserProfile
						user={this.props.user}
						includeEditActions={false}
						imageRight={true}
						mobileVisible={false}
						includeEmail={false}
					/>

					<BallotSummary data={lastBallot} actions={ballotSummaryActions} />
				</second-column>
			</two-column-layout>
		);
	}
}

export default connect((state) => ({
	user: state.user,
	courses: state.courses,
	events: state.events,
	ballots: state.ballots,
}))(SearchRetreats);
