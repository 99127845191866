// Imports

import fetch from 'scripts/helpers/fetch';

// Variables

const SET_BALLOTS = 'SET_BALLOTS';

// Types

function setBallots(data) {
    return {
        type: SET_BALLOTS,
        payload: data,
    };
}

// Dispatch : Handle Get Ballots

function handleBallots() {
    return async (dispatch) => {
        await fetch('/ballots/response?order_by=created_at&sort=desc', 'GET')
            .then((response) => dispatch(setBallots(response.content.ballots)))
            .then((response) => response)
            .catch((error) => error);
    };
}

// Exports

export { SET_BALLOTS, handleBallots };
