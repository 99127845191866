// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Components

import Slider from './Slider';
import Controller from './Controller';

// Helpers

import makeArrayFromNumber from 'scripts/helpers/makeArrayFromNumber.js';

// Wrappers

import BallotWrapper from 'scripts/wrappers/Ballot';

// Slider Component

class SliderComponent extends React.Component {
    // Constructor

    constructor(props) {
        super(props);

        const parentState = this.props.state.find(
            (state) => state.id === this.props.ballot.id
        );
        const numberofSlides = this.props.flag ? 100 : 10;
        const slidesArray = makeArrayFromNumber(numberofSlides);
        const initalState = parentState.value ? parentState.value : '-';

        this.state = {
            value: initalState,
            controlsDisabled: false,
            slides: slidesArray,
            animationValue: null,
            includesDecimals: this.props.flag ? true : false,
            animationSpeed: 500,
            animateController: null,
            percentageSlide: null,
            rangeSlidePercentageMove: null,
        };
    }

    // Set Value

    setValue(key, value) {
        this.setState({
            [key]: value,
        });

        if (key === 'value') this.updateEvent();
    }

    // Disabled Controls

    disabledControls() {
        this.setState({
            controlsDisabled: true,
        });

        setTimeout(() => {
            this.setState({
                controlsDisabled: false,
            });
        }, this.state.animationSpeed + 150);
    }

    // Modify Animation Time

    setAnimationValue(value) {
        const currentValue = this.animationSpeed;

        this.setState({ animationSpeed: value });

        setTimeout(() => {
            this.setState({ animationSpeed: currentValue });
        }, value);
    }

    // Set Pecentage Slide

    setPercentageSlide(value) {
        this.setState({ percentageSlide: value });
    }

    // Parent : Update Event

    updateEvent() {
        const id = this.props.ballot.id;
        const value = this.state.value;

        this.props.update(id, value);
    }

    // Render

    render() {
        const numberToShow = 3;

        return (
            <BallotWrapper {...this.props}>
                <div ref={this.container}>
                    <Slider
                        numberToShow={numberToShow}
                        state={this.state}
                        setValue={this.setValue.bind(this)}
                        disabledControls={this.disabledControls.bind(this)}
                        setAnimationValue={this.setAnimationValue.bind(this)}
                    />

                    <Controller
                        state={this.state}
                        setValue={this.setValue.bind(this)}
                        disabledControls={this.disabledControls.bind(this)}
                        setPercentageSlide={this.setPercentageSlide.bind(this)}
                        includesDecimals={this.state.includesDecimals}
                    />
                </div>
            </BallotWrapper>
        );
    }
}

export default SliderComponent;
