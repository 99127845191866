// Helper : Make Era String

function makeObject(type, course) {
    return {
        name: type,
        description: course[type],
    };
}

// Export

export default function (course) {
    const types = ['era', 'architect', 'type'];
    const info = [];

    types.forEach((item) => {
        if (course[item]) {
            const obj = makeObject(item, course);
            info.push(obj);
        }
    });

    return info;
}
