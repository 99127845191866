// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Macros

import BlockActions from 'scripts/macros/BlockActions';

// HardCoded CTA Actions

const ctaActions = [
	{
		name: 'near-you',
		actions: [
			{
				text: 'Adjust Location',
				url: '/profile/edit',
			},
		],
	},
	{
		name: 'requesting',
		actions: [
			{
				text: 'View More',
				url: '/search/courses',
			},
		],
	},
	{
		name: 'retreats-list',
		actions: [
			{
				text: 'View More',
				url: '/search/retreats',
			},
		],
	},
	{
		name: 'events-attended',
		actions: [
			{
				text: 'View More',
				url: '/search/events-attended',
			},
		],
	},
	{
		name: 'wish-list',
		actions: [
			{
				text: 'View More',
				url: '/profile/detail/wish-list',
			},
		],
	},
];

// Columns

class Columns extends React.Component {
	// Render

	render() {
		const cta = this.props.cta ? ctaActions.find((item) => item.name === this.props.cta) : null;
		const modifyBorder = this.props.borderRadiusModification;
		const { titleIcon } = this.props;

		return (
			<component-columns class="view-block" block-viewport="large" remove-radius={modifyBorder}>
				<section>
					<div className="headline-with-action">
						<h1 className="medium-headline-sans">
							{this.props.title}

							{titleIcon && <img src={titleIcon} className="title-icon" aria-hidden />}
						</h1>
						{cta && <BlockActions data={cta.actions} />}
					</div>

					<div className="columns">{this.props.children}</div>
				</section>
			</component-columns>
		);
	}
}

export default Columns;
