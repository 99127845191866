// Imports

import fetch from 'scripts/helpers/fetch';

// Variables

const SET_OTHER_MEMBERS_BY_COURSE = 'SET_OTHER_MEMBERS_BY_COURSE';
const SET_OTHER_MEMBERS_BY_LOCATION = 'SET_OTHER_MEMBERS_BY_LOCATION';

// Types

function setMembersByCourse(data, course) {
    return {
        type: SET_OTHER_MEMBERS_BY_COURSE,
        payload: data,
        course: course,
    };
}

function setMembersByLocation(data) {
    return {
        type: SET_OTHER_MEMBERS_BY_LOCATION,
        payload: data,
    };
}

// Dispatch : Handle Set Members By User Location

function handleSetOtherMembersByLocation(user) {
    return async (dispatch) => {
        const membersByLocation = await fetch(
            `/raters-near/${user.id}`,
            'GET'
        );
        const filteredByLocaion = membersByLocation.filter(
            (member) => member.id !== user.id
        );

        dispatch(setMembersByLocation(filteredByLocaion));
    };
}

// Dispatch : Handle Set Members By User Course

function handleSetOtherMembers(user) {
    return async (dispatch) => {
        if (user.courses.length > 0) {
            for (var i = 0; i < user.courses.length; i++) {
                const course = user.courses[i];
                const membersByCourseResponse = await fetch(
                    `/courses/${course.id}/members`,
                    'GET'
                ).then((response) => {
                    const membersByCourse =
                        membersByCourseResponse.content.course_members;
                    const filteredByCourses = membersByCourse.filter(
                        (member) => member.id !== user.id
                    );

                    if (filteredByCourses.length > 0) {
                        dispatch(setMembersByCourse(filteredByCourses, course));
                    }

                    return Promise.reject(response);
                }).catch(error => {
					console.error(error);
				});
            }
        }

        if (user.id && user.city) {
            await fetch(`/raters-near/${user.id}`, 'GET')
                .then((response) => {
                    const membersByLocation = response.content.raters;
                    const filteredByLocation = membersByLocation.filter(
                        (member) => member.id !== user.id
                    );
                    dispatch(setMembersByLocation(filteredByLocation));
                })
                .catch((error) => console.error(error));
        }
    };
}

// Exports

export {
    SET_OTHER_MEMBERS_BY_COURSE,
    SET_OTHER_MEMBERS_BY_LOCATION,
    handleSetOtherMembersByLocation,
    handleSetOtherMembers,
};
