// Imports

import { SET_ALERT, CLEAR_ALERT } from 'scripts/store/actions/alerts';

const initialState = {
    message: null,
};

// Reducers

export default function alert(state = initialState, action) {
    if (action.type === SET_ALERT) {
        return { ...state, message: action.payload };
    } else if (action.type === CLEAR_ALERT) {
        return { ...state, message: null };
    }

    return state;
}
