// Imports

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './styles/master.css';

// Polyfill

import '@babel/polyfill';

// Application

import App from 'scripts/app.js';
import store from 'scripts/store';

// Variables

const mount = document.querySelector('#app');

// React Mount

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	mount
);
