// Imports

import { SET_WISH_LIST } from 'scripts/store/actions/wish-list';

// Reducers

export default function courses(state = [], action) {
    if (action.type === SET_WISH_LIST) {
        if (action.payload !== undefined) {
            return action.payload;
        }
    }

    return state;
}
