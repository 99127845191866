// Imports

import React from 'react';
import Tooltip from '../Tooltip';

// Macros

import ValidationErrors from 'scripts/macros/ValidationErrors';

// Helpers

import checkForError from 'scripts/helpers/checkForInputError';

// Component : Text Area

class Checkbox extends React.Component {
	// Change Event

	changeEvent(event) {
		const element = event.target;
		const name = element.getAttribute('name');

		this.props.parentEvent(name, element.checked, [
			{
				name: 'valid',
				value: false,
			},
			{
				name: 'error',
				value: false,
			},
		]);
	}

	// Render

	render() {
		const data = this.props.data;
		const state = this.props.state.fields.find((entry) => entry.name === data.name);

		return (
			<input-wrapper required={state && state.required} valid={state && state.valid} error={state && state.error}>
				<label className="invisible">
					<span>{data.label}</span>
				</label>

				<Tooltip text={data.tooltip}>
					<input
						type={'checkbox'}
						value={state.value}
						placeholder={data.placeholder}
						name={data.name}
						disabled={state.disabled ? state.disabled : false}
						onChange={this.changeEvent.bind(this)}
						checked={state.value}
					/>

					<span className="relative ml-2">{data.label}</span>
				</Tooltip>

				<ValidationErrors errorList={data.errors} activeError={state.activeError} />
			</input-wrapper>
		);
	}
}

export default Checkbox;
