// Imports

import { createStore } from 'redux';

import reducers from './reducers';
import middleware from './middleware';

// Export

export default createStore(reducers, middleware);
