// Imports

import { SET_REQUEST_RATING } from 'scripts/store/actions/requesting-rating';

// Reducers

export default function courses(state = [], action) {
    if (action.type === SET_REQUEST_RATING) {
        return action.payload;
    }

    return state;
}
