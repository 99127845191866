// Imports

import React from 'react';

// Store

import { connect } from 'react-redux';

// Macros

import CallToActions from 'scripts/macros/CallToActions';

// Helpers

// Priority Courses

class PriorityCourses extends React.Component {
    render() {
        const { data, title, titleIcon, actions, blurb } = this.props;

        if (data) {
            return (
                <priority-courses class="view-block">
                    <section>
                        <div className="info">
                            <div>
                                <h1 className="medium-headline-sans">
                                    {title}

                                    {titleIcon && (
                                        <img
                                            src={titleIcon}
                                            className="title-icon inline"
                                            aria-hidden
                                        />
                                    )}
                                </h1>

                                <div className="small-text-blocks">
                                    {blurb && <p>{blurb}</p>}
                                </div>
                            </div>
                        </div>

                        <CallToActions list={actions} />
                    </section>
                </priority-courses>
            );
        } else {
            return null;
        }
    }
}

export default connect((state) => ({}))(PriorityCourses);
