// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Store

import { connect } from 'react-redux';

// Macros

import SmallTextBlocks from 'scripts/macros/SmallTextBlocks';
import RatingGraph from 'scripts/macros/RatingGraph';
import CallToActions from 'scripts/macros/CallToActions';

// Helpers

import * as moment from 'moment';
import { ReactSVG } from 'react-svg';

// Categories

function Categories(props) {
    const { list } = props;

    if (list.length > 0) {
        return (
            <div className="category-display">
                {list.map((category, i) => {
                    return (
                        <div key={`category-details-${i}`}>
                            <ReactSVG src={`/images/icons/${category.icon}`} />
                            {category.title}
                        </div>
                    );
                })}
            </div>
        );
    } else {
        return null;
    }
}

// Information

function Information(props) {
    const { ballot } = props;

    if (ballot) {
        const now = moment(new Date());
        const momentDate = moment(ballot.created_at);
        const days = now.diff(momentDate, 'days');
        const date =
            days === 0
                ? `You rated this course today`
                : `You rated this course ${days} days ago`;
        const raters = `This course has been rated by ${ballot.other_raters} raters`;
        const data = [date, raters];

        return <SmallTextBlocks data={data} />;
    } else {
        return null;
    }
}

// Ballot Summary Details

class BallotSummaryDetails extends React.Component {
    render() {
        const { course, ratedBallots, ballotItems } = this.props;

        const ballotReference = ratedBallots.find(
            (ballot) => ballot.course.slug === course.slug
        );
        const categoryList = ballotItems.find(
            (ballot) => ballot.type === 'categories'
        );

        if (course) {
            const actions = [
                {
                    text: 'Edit Ballot',
                    url: `/ballot/${course.slug}?entry=${ballotItems.length + 1}`,
                },
                {
                    text: 'View Ballots',
                    url: `/profile/detail/ballot`,
                },
            ];

            return (
                <ballot-summary class="view-block">
                    <section>
                        <h1>Your Ballot</h1>
                        <div className="info left-aligned">
                            {ballotReference && (
                                <RatingGraph rating={ballotReference.score} />
                            )}
                            <div>
                                <Information ballot={ballotReference} />
                            </div>
                        </div>

                        <Categories list={categoryList.categories} />
                        <CallToActions list={actions} />
                    </section>
                </ballot-summary>
            );
        } else {
            return null;
        }
    }
}

export default connect((state) => ({
    ratedBallots: state.ballots,
}))(BallotSummaryDetails);
