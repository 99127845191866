// Imports

import React from 'react';
import ReactDOM from 'react-dom';
import ReactHtmlParser from 'react-html-parser';

// Accordion

class Accordion extends React.Component {
	// Constructor

	constructor(props) {
		super(props);
		this.container = React.createRef();
		this.containerRef = React.createRef();
		this.wrapperRef = React.createRef();
		this.wrapperHeightRef = React.createRef();
		this.buttonRef = React.createRef();

		this.state = {
			open: false,
			height: 0,
			showButton: false,
		};
	}

	// Component Did Mount

	componentDidMount() {
		const height = this.wrapperHeightRef.current.clientHeight;
		let preview;

		if(this.props.hidePreview){
			preview = 0;
		}
		else{
			preview = 100;
		}

		this.setState({
			height: height,
			showButton: height > 0,
		});
		this.wrapperRef.current.style.height = `${preview}px`;

		this.resizeEventReference = this.resizeEvent.bind(this);
		window.addEventListener('resize', this.resizeEventReference);
	}

	// Component Will Unmount

	componentWillUnmount() {
		window.removeEventListener('resize', this.resizeEventReference);
	}

	// Resize Event

	resizeEvent() {
		const isHidden = this.wrapperRef.current.getAttribute('aria-hidden');

		const height = this.wrapperHeightRef.current.offsetHeight;
		this.setState({height: height});

		if (isHidden === 'false') {
			this.wrapperRef.current.style.height = `${this.state.height + 20}px`;
			this.wrapperRef.current.style.transition = `none`;
		}
	}

	// Button Event

	buttonEvent() {
		const isHidden = this.wrapperRef.current.getAttribute('aria-hidden');

		if (isHidden === 'true') {
			this.expand();
		} else {
			this.collapse();
		}
	}

	// Expand

	expand() {
		this.wrapperRef.current.style.height = `${this.state.height + 20}px`;
		this.wrapperRef.current.style.transition = `height 1s`;
		this.wrapperRef.current.setAttribute('aria-hidden', false);

		this.buttonRef.current.setAttribute('active', '');

		this.setState({
			open: true,
		});
	}

	// Collapse

	collapse() {
		let preview;

		if(this.props.hidePreview){
			preview = 0;
		}
		else{
			preview = 100;
		}
		this.wrapperRef.current.style.height = `${preview}px`;

		this.wrapperRef.current.style.transition = `height 1s`;
		this.wrapperRef.current.setAttribute('aria-hidden', true);

		this.buttonRef.current.removeAttribute('active');

		this.setState({
			open: false,
		});
	}

	// Render

	render() {
		const ariaReference = `accordion-${Math.random(1, 100)}`;
		const text = this.state.open ? 'Collapse' : 'Expand';

		return (
			<accordion-block ref={this.container} expanded={this.state.open}>
				<accordion-wrapper ref={this.wrapperRef} id={ariaReference} aria-hidden="true">
					<wrap-height ref={this.wrapperHeightRef}>{this.props.children}</wrap-height>
				</accordion-wrapper>

				{this.state.showButton && (
					<accordion-button>
						<button
							ref={this.buttonRef}
							onClick={this.buttonEvent.bind(this)}
							aria-controls={ariaReference}
						>
							<span></span>
							{text}
						</button>
					</accordion-button>
				)}
			</accordion-block>
		);
	}
}

export default Accordion;
