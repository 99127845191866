// Imports

import React from 'react';
import SignupLink from './SignupLink';

// Wrapper

import List from 'scripts/wrappers/List';

// Macros

import DateWithUnderline from 'scripts/macros/DateWithUnderline';

// Helpers

// Retreat List

function EventsAttendedList(props) {
	return props.events.map((event, i) => {
		return (
			<div key={`event-${i}`} className="p-4 rounded sm:rounded-none shadow sm:shadow-none bg-white">
				<DateWithUnderline data={event} className="w-full" includeYear={true} />

				<p className="text-sm font-bold">{event.name}</p>
			</div>
		);
	});
}

// Wish

class EventsAttended extends React.Component {
	render() {
		const { data, pagination, getEvents } = this.props;

		const pages = [...Array(pagination.pages).keys()];

		return (
			<List {...this.props}>
				<div className="grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-8">
					<EventsAttendedList events={data} />
				</div>

				<div className="mx-auto w-full">
					<nav className="space-x-2 number-pagination" aria-label="Pagination">
						{pagination.prev_page_url && (
							<a
								href={pagination.prev_page_url}
								onClick={(e) => {
									e.preventDefault();
									getEvents(pagination.prev_page);
								}}
							>
								<svg
									className="h-5 w-5"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
										clipRule="evenodd"
									/>
								</svg>
								<span>prev</span>
							</a>
						)}

						{pages.map((item) => {
							const pageNumber = item + 1;
							const isActive = pagination.current_page === pageNumber;

							return (
								<a
									href={`${pagination.page_url}&page=${pageNumber}`}
									aria-current="page"
									key={item}
									className={isActive ? 'active' : ''}
									onClick={(e) => {
										e.preventDefault();
										getEvents(pageNumber);
									}}
								>
									{pageNumber}
								</a>
							);
						})}

						{pagination.next_page_url && (
							<a
								href={pagination.next_page_url}
								onClick={(e) => {
									e.preventDefault();
									getEvents(pagination.next_page);
								}}
							>
								<span>next</span>
								<svg
									className="h-5 w-5"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
										clipRule="evenodd"
									/>
								</svg>
							</a>
						)}
					</nav>
				</div>
			</List>
		);
	}
}

export default EventsAttended;
