// Imports

import { SET_ALERTS, SET_NEWS } from 'scripts/store/actions/slots';

const initialState = {
    alerts: [],
    news: [],
};

// Reducers

export default function courses(state = initialState, action) {
    if (action.type === SET_ALERTS) {
        return Object.assign({}, state, {
            alerts: action.payload,
        });
    }

    if (action.type === SET_NEWS) {
        return Object.assign({}, state, {
            news: action.payload,
        });
    }

    return state;
}
