// Imports

import fetch from 'scripts/helpers/fetch';
import sessionStorage from 'scripts/store/storage/session';
import userStorage from 'scripts/store/storage/user';
import { handleAddSession } from './session';
import { handleSetOtherMembers } from 'scripts/store/actions/other-members';
import { handleCoursesNear } from 'scripts/store/actions/courses-near';
import { handleSetSlots } from 'scripts/store/actions/slots';
import { handleSetEvents } from 'scripts/store/actions/events';
import { handleRequestRating } from 'scripts/store/actions/requesting-rating';
import { handleWishList } from 'scripts/store/actions/wish-list';
import { handleBallots } from 'scripts/store/actions/ballots';
import { handleSetHandbook } from './handbook';

// Variables

const SET_USER = 'SET_USER';

// Types

function setUser(user) {
    return {
        type: SET_USER,
        payload: user,
    };
}

// Dispatch : Handle Get User from API

function handleGetUser(credentials) {
    return async (dispatch) => {
        const response = await fetch('/users/login', 'POST', credentials);

        // Error

        if (response.error) {
            return response;
        }

        // Success

        if (!response.error) {
            const user = response.content.user;

            // Set User

            userStorage.setUser(user);
            dispatch(setUser(user));

            // Set Unquie Token

            sessionStorage.setSession(response.content.token);
            dispatch(handleAddSession(response.content.token));

            // Get All Session Data

            await dispatch(handleGetUserCourses(user));
            dispatch(handleSetOtherMembers(user));
            dispatch(handleCoursesNear(user));
            dispatch(handleSetEvents(user));
            dispatch(handleSetSlots());
            dispatch(handleRequestRating());
            dispatch(handleWishList());
            dispatch(handleBallots());
            dispatch(handleSetHandbook());

            return response;
        }
    };
}

// Dispatch : Handle Get User Courses From API

function handleGetUserCourses(user) {
    return async (dispatch) => {
        const response = await fetch(`/raters/${user.id}/courses`, 'GET');

        // Error

        if (response.error) {
            return response;
        }

        // Success

        if (!response.error) {
            // Set User

            user.courses = response.content.rater_memberships;

            dispatch(setUser(user));
            userStorage.setUser(user);

            return response;
        }
    };
}

// Dispatch : Update User

function handleUpdateUser(user) {
    return async (dispatch) => {
        const response = await fetch('/users/self', 'PUT', user);

        // Error

        if (response.error) {
            return response;
        }

        // Success

        if (!response.error) {
            dispatch(setUser(user));
            sessionStorage.updateSessionTimestamp();
            userStorage.setUser(user);
            return response;
        }
    };
}

// Dispatch : Update User

function handleRefreshUser(user) {
    return async (dispatch) => {
        const response = await fetch('/users/self', 'GET');

        // Error

        if (response.error || typeof user === 'undefined') {
            return response;
        }

        // Success

        if (!response.error) {
			const user=response.content
            dispatch(setUser(user));
            sessionStorage.updateSessionTimestamp();
            userStorage.setUser(user);
            return response;
        }
    };
}

// Dispatch : Set User

function handleSetUser(user) {
    return async (dispatch) => {
        dispatch(setUser(user));
        sessionStorage.updateSessionTimestamp();
        userStorage.setUser(user);
    };
}

// Dispatch : Rehydrate User from storage

function handleRehydrateUser(user) {
    return async (dispatch) => {
        dispatch(setUser(user));

        // Get All Session Data

        await dispatch(handleGetUserCourses(user));
        dispatch(handleSetOtherMembers(user));
        dispatch(handleCoursesNear(user));
        dispatch(handleSetEvents(user));
        dispatch(handleSetSlots());
        dispatch(handleRequestRating());
        dispatch(handleWishList());
        dispatch(handleBallots());
        dispatch(handleSetHandbook());
    };
}

// Exports

export {
    SET_USER,
    handleGetUser,
    handleGetUserCourses,
    handleRehydrateUser,
    handleUpdateUser,
    handleSetUser,
    handleRefreshUser,
};
