// Import

import * as moment from 'moment';

// Export

function formatHuman(string) {
    return moment.utc(string).format('MM/DD/YYYY');
}

function formatMachine(string) {
    const month = string.slice(0, 2);
    const day = string.slice(3, 5);
    const year = string.slice(6, 11);

    return `${year}-${month}-${day}`;
}

// Object Response

const obj = {
    formatHuman,
    formatMachine,
};

// Export

export default obj;
