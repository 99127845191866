// Imports

import {
    ADD_SESSION,
    REMOVE_SESSION,
    CHECK_SESSION,
} from 'scripts/store/actions/session';

// Inital State

const initialState = {
    authenticated: false,
    token: null,
};

// Reducers

export default function user(state = initialState, action) {
    const obj = { authenticated: false };

    if (action.type === ADD_SESSION) {
        obj['authenticated'] = true;
        obj['token'] = action.payload;
        return Object.assign({}, state, obj);
    }

    if (action.type === REMOVE_SESSION) {
        obj['authenticated'] = false;
        return Object.assign({}, state, obj);
    }

    if (action.type === CHECK_SESSION) {
        obj['authenticated'] = action.payload;
        return Object.assign({}, state, obj);
    }

    return state;
}
