// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Macros

import DateWithUnderline from 'scripts/macros/DateWithUnderline';
import CallToActions from 'scripts/macros/CallToActions';

// Helpers

import makeLocationString from 'scripts/helpers/makeLocationString.js';

// Course Overview

export const Statistics = ({ course }) => {
	return (
		<div className="text-justify pt-4">
			<div>
				<slot className="text">Panel Average </slot> - {course.average_rating}
			</div>
			<div>Panel High Score - {course.highest_rating}</div>
			<div>Panel Low Score - {course.lowest_rating}</div>
		</div>
	);
};

class CourseOverview extends React.Component {
	render() {
		const course = this.props.course;
		const ballot = this.props.ballot;
		const location = makeLocationString(course.city, course.state, course.full_country);

		return (
			<course-overview class="view-block">
				<section>
					<h1 className="large-headline-serif">{course.name}</h1>
					<div className="flex flex-col sm:flex-row justify-between gap-4" without-margin="true">
						<div>
							{location}
							<p>
								<a href={`tel:${course.phone}`}>{course.phone}</a>
							</p>
						</div>
						{ballot && ballot.title === 'Overall Rating' && <Statistics course={course} />}
					</div>
				</section>
			</course-overview>
		);
	}
}

export default CourseOverview;
