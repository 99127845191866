// Imports

import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

// Components

import GenericBlock from 'scripts/components/Generic';

// Store

import { handleSetLoading, handleRemoveLoading } from 'scripts/store/actions/loader';

// Macros

import DateWithUnderline from 'scripts/macros/DateWithUnderline';
import SmallTextBlock from 'scripts/macros/SmallTextBlocks';
import MediumHeadline from 'scripts/macros/MediumHeadline';

// Helpers

import fetch from 'scripts/helpers/fetch';
import makeLocationString from 'scripts/helpers/makeLocationString';
import { postEvent } from 'scripts/helpers/api';

// Course Description

function CourseDescription(props) {
	if (props.event.description) {
		return (
			<div className="description">
				<MediumHeadline data={'Description'} tag="h3" />
				<p>{props.event.description}</p>
			</div>
		);
	} else {
		return null;
	}
}

// Events Details

class EventDetails extends React.Component {
	// Constructor

	constructor(props) {
		super(props);
		this.state = {
			event: null,
			userEvents: null,
		};
	}

	// Component Did Mount

	async componentDidMount() {
		this.props.dispatch(handleSetLoading());

		const eventsResponse = await fetch(`/events/${this.props.match.params.url}`, 'GET');
		const userEvents = await fetch(`/raters/${this.props.user.id}/events`, 'GET');

		this.setState({
			event: eventsResponse.content,
			userEvents: userEvents.content.rater_events,
		});

		this.props.dispatch(handleRemoveLoading());
	}

	// Event : Add To Event to User

	async addEvent(event) {
		const target = event.target;
		const eventId = target.getAttribute('event-id');

		this.props.dispatch(handleSetLoading());
		const response = await postEvent(this.props.user.id, eventId);

		// Success

		if (!response.error) {
			this.setState({
				userEvents: response.content.rater_events,
			});
		}

		// To Do : Error Handling

		if (response.error) {
		}

		this.props.dispatch(handleRemoveLoading());
	}

	// Render

	render() {
		const event = this.state.event;

		if (event) {
			const isAttending = this.state.userEvents.findIndex((list) => list.id === event.id);
			const location = makeLocationString(event.course_city, event.course_state, event.course_country_name);

			return (
				<two-column-layout>
					<first-column>
						<course-overview class="view-block" block-viewport="large">
							<h1 className="large-headline-sans">{event.name}</h1>
							<h2 className="regular-headline-serif">{event.course_name}</h2>
							<div className="small-text-blocks">
								<p>{location}</p>
								<p>
									<a href={`tel:${event.course_phone}`}>{event.course_phone}</a>
								</p>
							</div>

							<CourseDescription event={event} />
						</course-overview>
					</first-column>

					<second-column mobile-visible="true">
						<generic-block class="view-block">
							<section>
								<DateWithUnderline padding={true} data={event} />
								<br />

								{/* {isAttending < 0 && (
									<button
										event-id={event.id}
										className="call-to-action"
										onClick={this.addEvent.bind(this)}
									>
										Join This Event
									</button>
								)} */}
							</section>
						</generic-block>
					</second-column>
				</two-column-layout>
			);
		} else {
			return null;
		}
	}
}

export default connect((state) => ({
	loading: state.loading,
	courses: state.courses,
	events: state.events,
	user: state.user,
}))(EventDetails);
