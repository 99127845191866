// Imports

import { SET_HANDBOOK } from 'scripts/store/actions/handbook';

const initialState = {
    handbook: null,
};

// Reducers

export default function handbook(state = initialState, action) {
    if (action.type === SET_HANDBOOK) {
        if (action.payload !== undefined) {
            return action.payload;
        }
    }

    return state;
}
