// Imports

import React from 'react';
import querystring from 'querystring';
import { ReactComponent as FlagIcon } from 'assets/images/icons/golfweek-flag.svg';

// Store

import { connect } from 'react-redux';

// Components

import SearchCourses from 'scripts/components/Search/Courses';
import UserProfile from 'scripts/components/UserProfile';
import BallotSummary from 'scripts/components/BallotSummary';

// Helpers

import findLastBallot from 'scripts/helpers/findLastBallot';

// Rate Courses

import { postSearchCourse } from 'scripts/helpers/api';
import MediumHeadline from 'scripts/macros/MediumHeadline';
import SmallTextBlocks from 'scripts/macros/SmallTextBlocks';
import makeCourseSmallBlocks from 'scripts/helpers/makeCourseSmallBlocks';
import List from 'scripts/wrappers/List';

class SearchResults extends React.Component {
	constructor(props) {
		super(props);

		this.searchParams = querystring.parse(window.location.search.replace('?', ''));

		this.state = {
			courses: [],
			pagination: {},
			orderBy: 'name',
			sort: 'asc',
			perPage: '25',
			requesting_ratings: this.searchParams.requesting_ratings === 'true' ? true : false,
		};
	}

	searchCourses() {
		const orderBy = `${this.state.sort === 'asc' ? '' : '-'}${this.state.orderBy}`;
		postSearchCourse(
			{
				filter_by: this.searchParams.filter_by,
				search: this.searchParams.search,
				order_by: orderBy,
				requesting_ratings: this.state.requesting_ratings,
				per_page: this.state.perPage,
			},
			this.searchParams.page
		)
			.then((response) => {
				if (response.content.courses) {
					this.setState({
						courses: response.content.courses,
						pagination: response.content.pagination,
					});
				}
			})
			.catch((error) => {
				// Need to send them to a 404 page
				// This would probably occur when you navigate to a page that is out of bounds for the number of courses
				console.error(error);
			});
	}

	componentDidMount() {
		this.searchCourses();
	}

	changeOrder(event) {
		console.log(event.target.value);
		this.setState({
			orderBy: event.target.value,
		});

		this.searchCourses();
	}

	changeSort(event) {
		this.setState({
			sort: event.target.value,
		});

		setTimeout(() => {
			this.searchCourses();
		}, 0);
	}

	changePerPage(event) {
		this.setState({
			perPage: event.target.value,
		});

		setTimeout(() => {
			this.searchCourses();
		}, 0);
	}

	togglePriority(event) {
		this.setState({
			requesting_ratings: !this.state.requesting_ratings,
		});

		setTimeout(() => {
			this.searchCourses();
		}, 0);
	}

	render() {
		const lastBallot = findLastBallot(this.props.ballots);
		const { pagination, courses } = this.state;

		let ballotSummaryActions = [];

		if (lastBallot) {
			ballotSummaryActions = [
				{
					text: 'Edit Ballot',
					url: `/ballot/${lastBallot.course.slug}?entry=${lastBallot.ballot_items_count + 1}`,
				},
				{
					text: 'View Ballots',
					url: `/profile/detail/ballot`,
				},
			];
		}

		return (
			<two-column-layout>
				<first-column>
					<SearchCourses
						largeText={'Search Courses'}
						smallText={'Select a filter and enter search terms:'}
						placeholder={''}
						type={'course'}
						filters={{
							course_name: 'Search By Course Name',
							city: 'Search by City',
							state: 'Search by State',
							country: 'Search by Country',
							era: 'Search by Era',
							architect: 'Search by Architect',
						}}
						searchParams={this.searchParams}
					/>

					<List title="Search Courses">
						<div className="flex items-center mb-4 sm:absolute sm:right-0 sm:top-0 lg:relative xl:absolute xl:right-0 xl:top-0">
							<div className="mr-2">
								<input
									type="checkbox"
									onChange={this.togglePriority.bind(this)}
									value={this.state.requesting_ratings}
									defaultChecked={this.state.requesting_ratings}
								/>{' '}
								Priority Courses
							</div>

							<select
								className="p-2 border borer-darkGrayTint rounded mr-2"
								onChange={this.changeOrder.bind(this)}
								value={this.state.orderBy}
							>
								<option>Order By</option>
								<option value="name">Name</option>
								<option value="courses_revisions-city">City</option>
								<option value="courses_revisions-state">State</option>
								<option value="courses_revisions-country">Country</option>
							</select>

							<select
								className="p-2 border borer-darkGrayTint rounded mr-2"
								onChange={this.changeSort.bind(this)}
								value={this.state.sort}
							>
								<option>Sort</option>
								<option value="asc">Ascending</option>
								<option value="desc">Descending</option>
							</select>

							<select
								className="p-2 border borer-darkGrayTint rounded"
								onChange={this.changePerPage.bind(this)}
								value={this.state.perPage}
							>
								<option>Results Per Page</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
						</div>

						<div className="rows">
							{courses.map((course, i) => {
								const textBlocks = makeCourseSmallBlocks(course);

								return (
									<div className="view-block" block-viewport="small" key={`course-${i}`}>
										<div className="flex">
											<MediumHeadline
												title={course.name}
												url={`/course/detail/${course.slug}`}
												tag="h2"
											/>
											{course.requesting_ratings && <FlagIcon className="w-5 h-5 ml-4" />}
										</div>
										<SmallTextBlocks data={textBlocks} />
									</div>
								);
							})}

							{pagination && (
								<div className="pagination">
									{pagination.prev_page && (
										<a
											href={`/search/results?filter_by=${this.searchParams.filter_by}&search=${
												this.searchParams.search
											}&requesting_ratings=${this.state.requesting_ratings}&page=${
												pagination.prev_page || ''
											}`}
											className="prev self-start"
										>
											Previous
										</a>
									)}
									{pagination.next_page && (
										<a
											href={`/search/results?filter_by=${this.searchParams.filter_by}&search=${
												this.searchParams.search
											}&requesting_ratings=${this.state.requesting_ratings}&page=${
												pagination.next_page || ''
											}`}
											className="next self-end"
										>
											Next
										</a>
									)}
								</div>
							)}
						</div>
					</List>
				</first-column>

				<second-column>
					<UserProfile
						user={this.props.user}
						includeEditActions={false}
						imageRight={true}
						mobileVisible={false}
						includeEmail={false}
					/>

					<BallotSummary data={lastBallot} actions={ballotSummaryActions} />
				</second-column>
			</two-column-layout>
		);
	}
}

export default connect((state) => ({
	user: state.user,
	requestingRating: state.requestingRating,
	coursesNear: state.coursesNear,
	ballots: state.ballots,
}))(SearchResults);
