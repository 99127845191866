// Helper : Make Rater Since

import React from 'react';

function makeRaterSince(user) {
    let string = '';
    if (user.year_joined) string += `Member since ${user.year_joined}`;
    return string;
}
function makeStatusHandicap(user) {
	if (!user.handicap && !user.status) return '';
	if (!user.handicap && user.status!==null ) return `${user.status}`;
	if (user.handicap!==null && !user.status) return `${user.handicap} HC`;
	return `${user.status}, ${user.handicap} HC`;
}
// Export

export default function (user) {
    const raterString = makeRaterSince(user);
	const statusHandicap = makeStatusHandicap(user);
    const summary = [];

    if (raterString !== '') summary.push(raterString);
	if (statusHandicap !== '') summary.push(statusHandicap);

    return summary;
}
