// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Macro : Validation Error

export default function (props) {
    const errorList = props.errorList;

    if (Array.isArray(errorList) && errorList.length > 0) {
        return (
            <div className="errors">
                {errorList.map((error, i) => {
                    if (props.activeError === error.name) {
                        return (
                            <p error-type={error.name} key={`input-error-${i}`}>
                                {error.text}
                            </p>
                        );
                    }
                })}
            </div>
        );
    } else {
        return null;
    }
}
