// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Components

import UserProfile from 'scripts/components/UserProfile';
import BallotList from 'scripts/components/List/Ballot';
import WishList from 'scripts/components/List/Wish';
import MemberList from 'scripts/components/List/Member';
import ClubList from 'scripts/components/List/Club';
import BallotSummary from 'scripts/components/BallotSummary';

// Store

import { connect } from 'react-redux';
import { handleSetLoading, handleRemoveLoading } from 'scripts/store/actions/loader';

// Helpers

import fetch from 'scripts/helpers/fetch';
import filterPropsById from 'scripts/helpers/filterPropsById';
import { deleteWishList } from 'scripts/helpers/api';
import findLastBallot from 'scripts/helpers/findLastBallot';
import makeLocationString from 'scripts/helpers/makeLocationString';
import makeBagArray from 'scripts/helpers/makeBagArray';

// Profile Ballot

class ProfileOther extends React.Component {
	// Constructor

	constructor(props) {
		super(props);

		this.state = {
			user: null,
			othersByLocation: null,
			params: null,
			getMembers: this.getMembers(),
		};
	}

	// Component Did Mount

	componentDidMount() {
		this.getMembers();
	}

	// Component Did Update

	componentDidUpdate(prevProps, prevState) {
		const paramsId = this.props.match.params.id;

		if (paramsId != prevProps.match.params.id) {
			this.getMembers();
		}
	}

	// Get Members

	async getMembers() {
		const params = this.props.match.params;

		this.props.dispatch(handleSetLoading());

		const userResponse = await fetch(`/raters/${params.id}`, 'GET');
		const user = userResponse.content;
		const membersByLocation = await fetch(`/raters-near/${user.id}`, 'GET');
		const filteredByLocaion = membersByLocation.content.raters.filter((member) => member.id !== user.id);

		this.setState({
			user: user,
			othersByLocation: filteredByLocaion,
			params: params.id,
		});

		this.props.dispatch(handleRemoveLoading());
	}

	// Render

	render() {
		if (this.state.user) {
			const user = this.state.user;
			const location = makeLocationString(
				user.city,
				user.state_name ? user.state_name : user.state,
				user.country_name ? user.country_name : user.country
			);
			const otherLocationTitle = `Other Members near ${location}`;
			const bagItems = makeBagArray(user);
			const othersByLocation = this.state.othersByLocation;

			return (
				<two-column-layout>
					<first-column>
						<UserProfile
							user={user}
							includeEditActions={true}
							imageRight={false}
							mobileVisible={true}
							includeEmail={true}
						/>

						{bagItems.length > 0 && <ClubList title={"What's in My Bag"} type={'club'} data={bagItems} />}
					</first-column>

					<second-column>
						{othersByLocation && (
							<MemberList title={otherLocationTitle} type={'member'} data={othersByLocation} />
						)}

						{user.courses &&
							user.courses.map((course) => (
								<MemberList
									key={course.id}
									title={`Other Members of ${course.name}`}
									type={'member'}
									data={this.props.otherMembers.membersByCourse[course.id]}
								/>
							))}
					</second-column>
				</two-column-layout>
			);
		} else {
			return null;
		}
	}
}

export default connect((state) => ({}))(ProfileOther);
