// Imports

import { combineReducers } from 'redux';

import user from './user';
import session from './session';
import loader from './loader';
import otherMembers from './other-members';
import slots from './slots';
import events from './events';
import requestingRating from './requesting-rating';
import wishList from './wish-list';
import coursesNear from './courses-near';
import ballots from './ballots';
import alerts from './alerts';
import handbook from './handbook';

// Export

export default combineReducers({
    user,
    session,
    loader,
    otherMembers,
    slots,
    events,
    requestingRating,
    wishList,
    coursesNear,
    ballots,
    alerts,
    handbook,
});
