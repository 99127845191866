// Imports

import React from 'react';

// Components

import SearchResults from './results';

// Helpers

import { postSearchCourse, postSearchRater } from 'scripts/helpers/api';

// Search

class Search extends React.Component {
    // Constructor

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            value: '',
            showResults: false,
            results: [],
            hasBorder: false,
        };
    }

    // Component Did Mount

    componentDidMount() {
        this.formRef = this.form.current;
        this.resultsReg = this.formRef.querySelector('.results');
        this.inputTextRef = this.formRef.querySelector('input[type="text"]');
        this.body = document.querySelector('body');

        this.resizeEventReference = this.resizeEvent.bind(this);
        window.addEventListener('resize', this.resizeEventReference);
    }

    // Component Will Unmount

    componentWillUnmount() {
        this.resultsReg.style.height = `inherit`;
        this.body.style.overflow = 'inherit';
        this.body.removeAttribute('style');

        window.removeEventListener('resize', this.resizeEventReference);
    }

    // Resize Event

    resizeEvent() {
        if (this.state.showResults) this.sizeResults();
    }

    // Size Results

    sizeResults() {
        const position = this.formRef.getBoundingClientRect();
        const height =
            window.innerHeight - position.top - this.formRef.offsetHeight - 20;

        this.resultsReg.style.height = `${height}px`;
        this.body.style.overflow = 'hidden';
    }

    handleKeyPress(event) {
        if (event.keyCode === 13 || event.which === 13) {
            event.preventDefault();

            this.setState({
                hasBorder: true,
            });

            setTimeout(() => {
                this.setState({
                    hasBorder: false,
                });
            }, 500);
        }
    }

    // Close Event

    closeEvent(event) {
        event.preventDefault();

        this.resultsReg.style.height = `inherit`;
        this.body.style.overflow = 'inherit';

        this.setState({
            showResults: false,
        });
    }

    // Submit Event

    submitEvent(event) {
        event.preventDefault();

        const value = this.inputTextRef.getAttribute('value');
        if (value.length > 0) {
            this.setState({
                showResults: true,
            });
        } else {
            this.inputTextRef.focus();
        }

        this.sizeResults();
    }

    // Input Change

	async inputChange(event) {
		this.setState({
			value: event.target.value,
		});
		// Clears the previously set timer.
		clearTimeout(this.typingTimeout);

		// Reset the timer, to make the http call after 475MS (this.callSearch is a method which will call the search API. Don't forget to bind it in constructor.)
		this.typingTimeout = setTimeout(this.callSearch.bind(this), 475);

		// Setting value of the search box to a state.
		this.setState({ [event.target.name]: event.target.value });

	}


	async callSearch(event) {
		const value = this.state.value;
		const splitValue = value.split('');
		const type = this.props.type;
		let data = [];

		if (splitValue.length > 0) {
			const response =
				type === 'course'
					? await postSearchCourse(value)
					: await postSearchRater(value);

			if (type === 'course')
				data = response.content ? response.content.courses : [];
			if (type === 'member')
				data = response.content ? response.content.raters : [];
			let current_time=response.content.pagination['date']
			if(current_time> this.state.date || this.state.date ===undefined){
				this.setState({
					results: data,
					showResults: true,
					date:current_time
				});
			}else{
				this.setState({
					results: [] ,
					showResults: true,
					date:current_time
				});
			}

			this.sizeResults();

		} else {
			this.setState({
				results: [],
				showResults: false,
			});
		}
	}
	
	renderLink() {
		return (
			<a
				href={`/search/results?filter_by=${this.state.filter_by}&search=${this.state.term}`}
				className="text-brandGreen font-bold"
			>
				View All Results
			</a>
		);
	}

    // Render

    render() {
        return (
            <component-search
                class="view-block"
                with-results={this.state.showResults}
            >
                <section>
                    <h1>{this.props.largeText}</h1>
                    <form ref={this.form}>
                        <label>{this.props.smallText}</label>

                        <div>
                            <input
                                type="text"
                                value={this.state.value}
                                onChange={this.inputChange.bind(this)}
                                placeholder={this.props.placeholder}
                                onKeyPress={this.handleKeyPress.bind(this)}
                            />
                        </div>

                        <SearchResults
                            term={this.state.value}
                            type={this.props.type}
                            data={this.state.results}
                            closeEvent={this.closeEvent.bind(this)}
                            hasBorder={this.state.hasBorder}
							link={this.renderLink()}
                        />
                    </form>
                </section>
            </component-search>
        );
    }
}

export default Search;
