// Imports

import fetch from '../../helpers/fetch';

// Variables

const SET_HANDBOOK = 'SET_HANDBOOK';

// Types

function setHandbook(data) {
    return {
        type: SET_HANDBOOK,
        payload: data,
    };
}

function handleSetHandbook(value) {
    return async (dispatch) => {
        await fetch('/handbook', 'GET')
            .then((response) =>
                dispatch(setHandbook({ handbook: response.content.pdf_link }))
            )
            .then((response) => response)
            .catch((error) => error);
    };
}

// Exports

export { SET_HANDBOOK, handleSetHandbook };
