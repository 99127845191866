// Imports

import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

// Macros

// Global Alerts Block

class GlobalAlert extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            message: props.message,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.message !== nextProps.message) {
            this.setState({
                message: nextProps.message,
            });
        }
    }

    render() {
        const { message } = this.state;

        return (
            message && (
                <ReactCSSTransitionGroup
                    transitionName="fade"
                    transitionAppear={true}
                    transitionAppearTimeout={10000}
                    transitionEnterTimeout={5000}
                    transitionLeaveTimeout={500}
                >
                    <global-alert class="view-block">{message}</global-alert>
                </ReactCSSTransitionGroup>
            )
        );
    }
}

export default GlobalAlert;
