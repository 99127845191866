// Imports

import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

// Wrappers

import Column from 'scripts/wrappers/Column';

// Macros

import SmallTextBlock from 'scripts/macros/SmallTextBlocks';
import MediumHeadline from 'scripts/macros/MediumHeadline';

// Helpers

import makeCourseSmallBlocks from 'scripts/helpers/makeCourseSmallBlocks.js';

// List

function List(props) {
    return props.data.map((course, i) => {
        const textBlocks = makeCourseSmallBlocks(course);

        return (
            <div className="view-block" block-viewport="small" key={`column-${i}`}>
                <MediumHeadline
                    title={course.name}
                    url={`/course/detail/${course.slug}`}
                    tag="h2"
                />
                <SmallTextBlock data={textBlocks} />
            </div>
        );
    });
}

// Regular Columns

class Regular extends React.Component {
    render() {
        const data = this.props.data;

        if (data.length > 0) {
            const list = data.slice(0, 4);

            return (
                <Column {...this.props}>
                    <div>
                        <List data={list} />
                    </div>
                </Column>
            );
        } else {
            return null;
        }
    }
}

export default Regular;
