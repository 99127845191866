// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Components

import UserProfile from 'scripts/components/UserProfile';
import BallotList from 'scripts/components/List/Ballot';
import WishList from 'scripts/components/List/Wish';
import MemberList from 'scripts/components/List/Member';
import BallotSummary from 'scripts/components/BallotSummary';
import OverallBallotSummary from 'scripts/components/OverallBallotSummary';

// Store

import { connect } from 'react-redux';
import { handleWishList } from 'scripts/store/actions/wish-list';
import { handleSetLoading, handleRemoveLoading } from 'scripts/store/actions/loader';

// Helpers

import filterPropsById from 'scripts/helpers/filterPropsById';
import { deleteWishList } from 'scripts/helpers/api';
import findLastBallot from 'scripts/helpers/findLastBallot';
import makeLocationString from 'scripts/helpers/makeLocationString';

// Profile Ballot

class ProfileBallot extends React.Component {
	// Delete From Wish List

	async deleteFromWishList(id) {
		this.props.dispatch(handleSetLoading());
		await deleteWishList(id);
		this.props.dispatch(handleWishList());
		this.props.dispatch(handleRemoveLoading());
	}

	// Render

	render() {
		const pageParams = this.props.match.params;
		const lastBallot = findLastBallot(this.props.ballots);
		const user = this.props.user;
		const location = makeLocationString(
			user.city,
			user.state_name ? user.state_name : user.state,
			user.country_name ? user.country_name : user.country
		);
		const otherLocationTitle = `Other Members near ${location}`;
		let Component;

		let ballotSummaryActions = [];

		if (lastBallot) {
			ballotSummaryActions = [
				{
					text: 'Edit Ballot',
					url: `/ballot/${lastBallot.course.slug}?entry=${lastBallot.ballot_items_count + 1}`,
				},
			];
		}

		if (pageParams.url === 'wish-list') {
			Component = (
				<WishList
					title={'My Wish List'}
					type={'wish'}
					data={this.props.wishList}
					deleteEvent={this.deleteFromWishList.bind(this)}
				/>
			);
		}

		if (pageParams.url === 'ballot') {
			// parse the search and filter params
			const params = new URLSearchParams(this.props.location.search);

			Component = (
				<BallotList
					title={'My Ballots'}
					type={'ballot'}
					year={params.get('year')}
					page={params.get('page')}
					history={this.props.history}
					data={this.props.ballots}
				/>
			);
		}

		return (
			<two-column-layout>
				<first-column>
					<UserProfile
						user={user}
						includeEditActions={false}
						imageRight={false}
						mobileVisible={true}
						includeEmail={false}
					/>

					<OverallBallotSummary user={user} />

					{Component}
				</first-column>

				<second-column>
					<BallotSummary data={lastBallot} actions={ballotSummaryActions} />

					{user.courses.map((course) => (
						<MemberList
							key={course.id}
							title={`Other Members of ${course.name}`}
							type={'member'}
							data={this.props.otherMembers.membersByCourse[course.id]}
						/>
					))}

					<MemberList
						title={otherLocationTitle}
						type={'member'}
						data={this.props.otherMembers.membersByLocation}
					/>
				</second-column>
			</two-column-layout>
		);
	}
}

export default connect((state) => ({
	wishList: state.wishList,
	otherMembers: state.otherMembers,
	ballots: state.ballots,
	user: state.user,
}))(ProfileBallot);
