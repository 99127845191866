// Imports

import fetch from 'scripts/helpers/fetch';

// Variables

const SET_REQUEST_RATING = 'SET_REQUEST_RATING';

// Types

function setRequestRating(data) {
    return {
        type: SET_REQUEST_RATING,
        payload: data,
    };
}

// Dispatch : Handle Set Request Rating From API

function handleRequestRating() {
    return async (dispatch) => {
        await fetch('/courses?requesting_ratings=1&per_page=100&order_by=name&sort=asc', 'GET')
            .then((response) =>
                dispatch(setRequestRating(response.content.courses))
            )
            .catch((error) => console.error(error));
    };
}

// Exports

export { SET_REQUEST_RATING, handleRequestRating };
