// Imports

import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

// Helpers

import fetch from 'scripts/helpers/fetch.js';
import { withRouter } from 'react-router-dom';

import logo from '../../../assets/images/logos/golf-week-light.svg';

// Reset Password

class ResetPassword extends React.Component {
    // Constructor

    constructor(props) {
        super(props);

        this.state = {
            email: null,
            password: null,
            passwordConfirm: null,
            message: null,
        };
    }

    inputChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            message: null,
        });
    }

    async resetPassword(event) {
        event.preventDefault();

        if (this.state.password !== this.state.passwordConfirm) {
            this.setState({
                message: 'Your passwords did not match.',
            });

            return;
        }

        if (this.state.password === null || this.state.email === null) {
            this.setState({
                message: 'All fields are required.',
            });

            return;
        }

        const response = await fetch('/users/reset-password', 'POST', {
            email: this.state.email,
            password: this.state.password,
            token: this.props.match.params.token,
        });

        if (response.error) {
            this.setState({
                message: response.message.error,
            });
        } else {
            this.setState({
                message: response.content
                    ? response.content.message
                    : 'There was an error resetting your password. Please try again later.',
            });

            this.props.onResetPassword();
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <centered-form>
                <div className="lock">
                    <img src={logo} alt="Golfweek Raters Logo" />

                    <form>
                        {this.state.message && (
                            <div className="error">
                                <p>{this.state.message}</p>
                            </div>
                        )}

                        <input-wrapper>
                            <input
                                name="email"
                                type="email"
                                placeholder="Enter email"
                                autoComplete="email"
                                onChange={this.inputChange.bind(this)}
                            />
                        </input-wrapper>

                        <input-wrapper>
                            <input
                                name="password"
                                type="password"
                                placeholder="New Password"
                                onChange={this.inputChange.bind(this)}
                            />
                        </input-wrapper>

                        <input-wrapper>
                            <input
                                name="passwordConfirm"
                                type="password"
                                placeholder="Confirm Password"
                                onChange={this.inputChange.bind(this)}
                            />
                        </input-wrapper>

                        <input
                            type="submit"
                            className="call-to-action"
                            onClick={this.resetPassword.bind(this)}
                        />

                        <Link to="/">Back to Login</Link>
                    </form>
                </div>
            </centered-form>
        );
    }
}

export default withRouter(ResetPassword);
