// Import

import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import qs from 'querystring';

// Store

import { handleGetUser } from 'scripts/store/actions/user';
import { handleCheckSession } from 'scripts/store/actions/session';
import sessionStorage from 'scripts/store/storage/session';
import { handleSetLoading, handleRemoveLoading } from 'scripts/store/actions/loader';

// Components : Common

import Header from 'scripts/components/Header/';

// Components : Login

import Login from 'scripts/pages/Login/';
import ForgotPassword from 'scripts/pages/Login/ForgotPassword';
import ResetPassword from 'scripts/pages/Login/ResetPassword';

// Components : Main Nav

import Dashboard from 'scripts/pages/Dashboard';
import SearchCourses from 'scripts/pages/Search/Courses';
import SearchRaters from 'scripts/pages/Search/Raters';
import SearchRetreats from 'scripts/pages/Search/Retreats';
import SearchResults from 'scripts/pages/Search/Results';
import SearchEventsAttended from 'scripts/pages/Search/EventsAttended';

// Components : Profile Pages

import Profile from 'scripts/pages/Profile/index';
import ProfileEdit from 'scripts/pages/Profile/Edit';
import ProfileDetail from 'scripts/pages/Profile/Detail';
import ProfileOther from 'scripts/pages/Profile/Other';

// Components : Ballot

import Ballot from 'scripts/pages/Ballot';

// Components : Course Pages

import CourseDetail from 'scripts/pages/Course/Detail';
import CourseEvent from 'scripts/pages/Course/Event';

// Components : Wrappers

import Loader from 'scripts/wrappers/Loader';
import ScrollToTopOnPageChange from 'scripts/wrappers/ScrollToTopOnPageChange';

// Wrapper

class Wrapper extends React.Component {
	// Constructor

	constructor(props) {
		super(props);

		this.state = {
			message: null,
			apiLoaded: false,
		};
	}

	// Component Did Mount

	componentDidMount() {
		this.props.dispatch(handleCheckSession());
	}

	// Get State Updates
	// Set Application Data from Session

	static async getDerivedStateFromProps(props, state) {
		if (props.session.authenticated) {
			sessionStorage.updateSessionTimestamp();
		}

		return null;
	}

	// Authenicate User Login

	async authenicate(username, password) {
		this.props.dispatch(handleSetLoading());

		const response = await this.props.dispatch(
			handleGetUser({
				username: username,
				password: password,
			})
		);

		this.props.dispatch(handleRemoveLoading());

		if (response.error) {
			this.setState({
				message: response.message.message,
			});
		}
	}

	onResetPassword() {
		this.setState({
			message: 'Your password has been successfully reset.',
		});
	}

	// Render

	render() {
		const session = this.props.session;
		const loader = this.props.loader;
		let Content;

		if (session.authenticated) {
			Content = (
				<Loader showing={loader.loading}>
					<Header handbook={this.props.handbook.handbook} />

					<main>
						<div className="lock">
							<Switch>
								<Route
									path="/search/courses/:page?"
									render={(match) => {
										let search = match.location.search;
										search = search.slice(1);
										const params = qs.parse(search);
										return <SearchCourses {...params} />;
									}}
								/>

								<Route
									path="/search/retreats"
									render={(match) => {
										const params = new URLSearchParams(match.location.search);
										return <SearchRetreats page={params.get('page') ?? 1} />;
									}}
								/>

								<Route path="/search/results" exact render={() => <SearchResults />} />

								<Route path="/search/raters" exact render={() => <SearchRaters />} />

								<Route path="/search/events-attended" exact render={() => <SearchEventsAttended />} />

								<Route path="/profile" exact render={() => <Profile />} />

								<Route path="/profile/edit" exact render={(match) => <ProfileEdit {...match} />} />

								<Route
									path="/profile/detail/:url/:page?"
									render={(match) => <ProfileDetail {...match} />}
								/>

								<Route path="/profile/other/:id" render={(match) => <ProfileOther {...match} />} />

								<Route path={`/course/detail/:url`} render={(match) => <CourseDetail {...match} />} />

								<Route path={`/course/event/:url`} render={(props) => <CourseEvent {...props} />} />

								<Route path={`/ballot/:url`} render={(match) => <Ballot {...match} />} />

								<Route path="/" render={() => <Dashboard />} />
							</Switch>
						</div>
					</main>
				</Loader>
			);
		}

		if (!session.authenticated) {
			Content = (
				<Loader showing={loader.loading}>
					<Switch>
						<Route path="/forgot-password" exact render={() => <ForgotPassword />} />

						<Route
							path="/reset-password/:token"
							exact
							render={() => <ResetPassword onResetPassword={this.onResetPassword.bind(this)} />}
						/>

						<Route
							path="/"
							render={() => (
								<Login errorMessage={this.state.message} authenicate={this.authenicate.bind(this)} />
							)}
						/>
					</Switch>
				</Loader>
			);
		}

		return (
			<Router>
				<ScrollToTopOnPageChange>{Content}</ScrollToTopOnPageChange>
			</Router>
		);
	}
}

const App = connect((state) => ({
	user: state.user,
	session: state.session,
	loader: state.loader,
	handbook: state.handbook,
}))(Wrapper);

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;
