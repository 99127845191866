// Imports

import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logos/golf-week-light.svg';

// Store

import { connect } from 'react-redux';

// Login

class Login extends React.Component {
    // Constructor

    constructor(props) {
        super(props);
        this.state = {
            username: null,
            password: null,
        };
    }

    // Event : Change

    inputChange(event) {
        const target = event.target;
        const name = target.getAttribute('name');

        this.setState({
            [name]: target.value,
            errorMessage: null,
        });
    }

    // Event : Parent Autenicate

    submit(event) {
        event.preventDefault();
        this.props.authenicate(this.state.username, this.state.password);
    }

    // Render

    render() {
        return (
            <centered-form>
                <div className="lock">
                    <img src={logo} alt="Golfweek Raters Logo" />

                    <form>
                        {this.props.errorMessage && (
                            <div className="error">
                                <p>{this.props.errorMessage}</p>
                            </div>
                        )}

                        <input-wrapper>
                            <input
                                type="text"
                                name="username"
                                placeholder="Enter username"
                                autoComplete="username"
                                onChange={this.inputChange.bind(this)}
                            />
                        </input-wrapper>

                        <input-wrapper>
                            <input
                                type="password"
                                name="password"
                                placeholder="Enter password"
                                autoComplete="password"
                                onChange={this.inputChange.bind(this)}
                            />
                        </input-wrapper>

                        <input
                            type="submit"
                            className="call-to-action"
                            onClick={this.submit.bind(this)}
                        />

                        <Link to="/forgot-password">Forgot Password?</Link>
                    </form>
                </div>
            </centered-form>
        );
    }
}

export default connect((state) => ({
    user: state.user,
}))(Login);
