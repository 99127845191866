// Imports

import React from 'react';
import flagIcon from '../../../assets/images/icons/golfweek-flag.svg';

// Store

import { connect } from 'react-redux';

// Components

import SearchCourses from 'scripts/components/Search/Courses';
import RegularColumn from 'scripts/components/Columns/Regular';
import CourseList from 'scripts/components/List/Course';
import UserProfile from 'scripts/components/UserProfile';
import BallotSummary from 'scripts/components/BallotSummary';

// Helpers

import findLastBallot from 'scripts/helpers/findLastBallot';

// Rate Courses

class RateCourses extends React.Component {
	render() {
		const lastBallot = findLastBallot(this.props.ballots);

		let ballotSummaryActions = [];

		if (lastBallot) {
			ballotSummaryActions = [
				{
					text: 'Edit Ballot',
					url: `/ballot/${lastBallot.course.slug}?entry=${lastBallot.ballot_items_count + 1}`,
				},
				{
					text: 'View Ballots',
					url: `/profile/detail/ballot`,
				},
			];
		}

		return (
			<two-column-layout>
				<first-column>
					<SearchCourses
						largeText={'Search Courses'}
						smallText={'Select a filter and enter search terms:'}
						placeholder={''}
						type={'course'}
						filters={{
							course_name: 'Search By Course Name',
							city: 'Search by City',
							state: 'Search by State',
							country: 'Search by Country',
							era: 'Search by Era',
							architect: 'Search by Architect',
						}}
					/>

					<RegularColumn
						title={'Courses Near You'}
						type={'regular'}
						data={this.props.coursesNear}
						cta={'near-you'}
						borderRadiusModification
					/>

					<CourseList title={'Priority Courses'} titleIcon={flagIcon} type={'course'} {...this.props} />
				</first-column>

				<second-column>
					<UserProfile
						user={this.props.user}
						includeEditActions={false}
						imageRight={true}
						mobileVisible={false}
						includeEmail={false}
					/>

					<BallotSummary data={lastBallot} actions={ballotSummaryActions} />
				</second-column>
			</two-column-layout>
		);
	}
}

export default connect((state) => ({
	user: state.user,
	requestingRating: state.requestingRating,
	coursesNear: state.coursesNear,
	ballots: state.ballots,
}))(RateCourses);
