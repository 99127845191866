// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Store

import { connect } from 'react-redux';

// Macros

import SmallTextBlocks from 'scripts/macros/SmallTextBlocks';
import RatingGraph from 'scripts/macros/RatingGraph';
import CallToActions from 'scripts/macros/CallToActions';

// Helpers

import * as moment from 'moment';
import makeLocationString from 'scripts/helpers/makeLocationString.js';

// Summary

function Summary(props) {
	const course = props.course;

	const location = makeLocationString(course.city, course.state, course.full_country);
	const architect = course.architect || '';
	const data = [location, architect];

	return <SmallTextBlocks data={data} />;
}

// Information

function Information(props) {
	const { ballot } = props;
	const now = moment(new Date());
	const momentDate = moment(ballot.created_at);
	const days = now.diff(momentDate, 'days');
	const date = days === 0 ? `You rated this course today` : `You rated this course ${days} days ago`;
	const raters = `This course has been rated by ${ballot.other_raters} raters`;
	const data = [date, raters];

	return <SmallTextBlocks data={data} />;
}

// Ballot Summary

class BallotSummary extends React.Component {
	render() {
		const { data, actions } = this.props;

		if (data) {
			return (
				<ballot-summary class="view-block">
					<section>
						<div className="info">
							<div>
								<h1>Last Course Rated</h1>
								<h2>{data.course.name}</h2>
								<Summary course={data.course} />
								<Information ballot={data} />
							</div>

							<RatingGraph rating={data.score} />
						</div>

						<CallToActions list={actions} />
					</section>
				</ballot-summary>
			);
		} else {
			return null;
		}
	}
}

export default connect((state) => ({}))(BallotSummary);
