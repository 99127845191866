// Imports

import React from 'react';

// List

class List extends React.Component {
	render() {
		const { title, titleIcon, children, borderRadiusModification, dropdown, viewport } = this.props;
		const blockViewport = viewport ? viewport : "large";
		return (
			<list-items class="view-block" block-viewport={`${blockViewport}`} remove-radius={borderRadiusModification}>
				<section>
					<div className="headline-with-action">
						<h1 className="medium-headline-sans flex">
							{title}

							{titleIcon && <img src={titleIcon} className="title-icon" aria-hidden alt="" />}
						</h1>
					</div>
					{dropdown}
					{children}
				</section>
			</list-items>
		);
	}
}

export default List;
