// Imports

import React from 'react';
import { Link } from 'react-router-dom';

// Macros

import BallotText from 'scripts/macros/BallotText.js';
import BallotActions from 'scripts/macros/BallotActions.js';

// Wrappers

import Accordion from 'scripts/wrappers/Accordion';
import { ReactComponent as AlertIcon } from '../../../assets/images/icons/alert.svg';

const preventNext = ({ _, shouldStop }, state, ballotType) => {
	if (ballotType === 'date' && (state.value === null || state.value === '')) {
		return true;
	}

	return shouldStop;
};

// Actions

function Actions(props) {
	if (props.include) {
		return (
			<BallotActions
				page={props.page}
				lastEntry={props.lastEntry}
				url={props.url}
				shouldStop={props.shouldStop}
				errorMessage={props.errorMessage}
			/>
		);
	} else {
		return null;
	}
}

// Submit Button

function SubmitButton(props) {
	if (props.include && props.show) {
		return (
			<div className="center-action">
				<Link to={`${props.url}?entry=${parseInt(props.id) + 1}`} className="call-to-action">
					Review Your Rating
				</Link>
			</div>
		);
	} else {
		return null;
	}
}

// Ballot

class Ballot extends React.Component {
	// Submit Event - Delete ?

	submitEvent() {
		this.props.update(this.props.ballot.id, this.textAreaRef.value);
	}

	// Render

	render() {
		this.updateEvent = this.props.update;

		const { Tag, ballot, errorMessage, course } = this.props;

		const state = this.props.state.find((item) => item.id === ballot.id);
		const error = ballot.type !== 'date' ? !!this.props.error : errorMessage.shouldStop;
		let isLastEntry = false;
		let placement = null;
		const shouldShowError = !!error || (!!errorMessage.message && ballot.type === 'date');
		const shouldStop = preventNext(errorMessage, state, ballot.type);

		if (Array.isArray(this.props.state)) {
			const entryIndex = this.props.state.findIndex((context) => context.id === ballot.id);
			isLastEntry = entryIndex === this.props.state.length - 1;
			placement = entryIndex + 1;
		}

		if (ballot) {
			let innerContent;

			if (this.props.reviewPage) {
				innerContent = <Accordion hidePreview={true}> {this.props.children} </Accordion>;
			} else {
				innerContent = this.props.children;
			}

			return (
				<>
					<Tag
						class="view-block ballot-entry"
						key={`ballot-component-${ballot.id}`}
						error={error}
						data-id={ballot.id}
					>
						<section>
							<BallotText
								placement={placement}
								data={ballot}
								includeText={true}
								value={state.value}
								showValue={this.props.reviewPage}
								course={course}
								showOverallRating={this.props.reviewPage}
							/>

							{shouldShowError && (
								<p className="flex space-x-2 text-red my-4">
									<AlertIcon className="relative" /> <span>{errorMessage.message}</span>
								</p>
							)}

							{innerContent}

							<Actions
								type={ballot.type}
								url={this.props.match.params.url}
								page={placement}
								lastEntry={this.props.state.length}
								include={this.props.includeActions !== false}
								shouldStop={shouldStop}
								errorMessage={errorMessage.message}
							/>

							<SubmitButton
								show={isLastEntry}
								id={ballot.id}
								url={this.props.match.url}
								include={this.props.includeActions !== false}
							/>
						</section>
					</Tag>
				</>
			);
		} else {
			return null;
		}
	}
}

Ballot.defaultProps = {
	errorMessage: {
		message: 'This field is required',
		shouldStop: false,
	},
};

export default Ballot;
