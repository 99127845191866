// Import

import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../../assets/images/logos/golf-week.svg';
import { ReactComponent as PinIcon } from '../../../assets/images/icons/pin.svg';
import { ReactComponent as StarIcon } from '../../../assets/images/icons/star.svg';
import { ReactComponent as AwardIcon } from '../../../assets/images/icons/award.svg';
import { ReactComponent as MessageIcon } from '../../../assets/images/icons/message.svg';
import { ReactComponent as SwingIcon } from '../../../assets/images/icons/swing.svg';
import { ReactComponent as PencilIcon } from '../../../assets/images/icons/pencil.svg';
import { ReactComponent as BallotIcon } from '../../../assets/images/icons/ballot.svg';
import { ReactComponent as GolfCartIcon } from '../../../assets/images/icons/golf-cart.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/images/icons/logout.svg';

// Components

import Dropdown from './Dropdown.js';
import RaterHandbook from './RaterHandbook';
import { ReactComponent as HandbookIcon } from 'assets/images/icons/handbook.svg';

// Helpers`

// Data

const profileDropdown = {
    id: 'profile',
    buttonTitle: 'Profile',
    links: [
        {
            type: 'navLink',
            image: PencilIcon,
            text: 'View My Profile',
            to: '/profile',
        },
        {
            type: 'navLink',
            image: BallotIcon,
            text: 'My Ballots',
            to: '/profile/detail/ballot',
        },
        {
            type: 'navLink',
            image: GolfCartIcon,
            text: 'My Wish List',
            to: '/profile/detail/wish-list',
        },
        {
            type: 'button',
            image: LogoutIcon,
            text: 'Logout',
            action: 'logout',
        },
    ],
};

// Header

class Header extends React.Component {
    // Constructor

    constructor(props) {
        super(props);
        this.body = document.querySelector('body');
        this.header = React.createRef();
    }

    // Component Did Mount

    componentDidMount() {
        const header = this.header.current;

        if (header !== null) {
            this.mobileButtons = Array.from(
                header.querySelectorAll('.mobile-button')
            );
            this.mobileMenus = Array.from(
                header.querySelectorAll('.mobile-menu')
            );
            this.closeButtons = Array.from(header.querySelectorAll('.close'));
            this.dropdowns = Array.from(
                header.querySelectorAll('header-dropdown')
            );
            this.animationTime = 1100;
            this.currentMobileMenu = null;
            this.currentButton = null;
            this.tabEventReference = this.tabEvent.bind(this);
            this.escapeEventReference = this.escapeEvent.bind(this);
            this.resizeEventReference = this.checkDropDownSize.bind(this);

            window.addEventListener('resize', this.resizeEventReference);
            this.checkDropDownSize();
        }
    }

    // Component Will Unmount

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeEventReference);
    }

    // Check Drop Down on Sizing

    checkDropDownSize() {
        if (window.innerWidth > 767) {
            this.body.removeAttribute('style');

            this.dropdowns.forEach((element) => {
                const elm = element.querySelector('div[mobile-menu="true"]');
                elm.setAttribute('aria-hidden', 'true');
            });

            this.mobileMenus.forEach((block) => {
                block.removeAttribute('aria-hidden');
                block.style.display = 'flex';
            });
        } else {
            this.dropdowns.forEach((element) => {
                const elm = element.querySelector('div[mobile-menu="true"]');
                elm.removeAttribute('aria-hidden');
            });

            this.mobileMenus.forEach((block) => {
                block.style.transition = `none`;
                block.setAttribute('aria-hidden', true);
            });
        }
    }

    // Button State

    buttonState(buttons) {
        function state(bool) {
            buttons.forEach((button) => {
                button.disabled = bool;
            });
        }

        state(true);
        setTimeout(() => {
            state(false);
        }, this.animationTime);
    }

    // Escape Event

    escapeEvent(event) {
        if (
            event.keyCode === 27 ||
            event.key === 'Escape' ||
            event.code === 'Escape'
        ) {
            this.close();

            if (this.currentButton) {
                this.currentButton.focus();
            }
        }
    }

    // Tab Event

    tabEvent(event) {
        if (
            event.keyCode === 9 ||
            event.key === 'Tab' ||
            event.code === 'Tab'
        ) {
            if (!this.currentMobileMenu.contains(event.target)) {
                this.currentButton.focus();
                this.close();
            }
        }
    }

    // Close

    close() {
        if (this.currentMobileMenu) {
            this.currentMobileMenu.setAttribute('aria-hidden', true);
            this.buttonState(this.mobileButtons);

            setTimeout(() => {
                if (this.currentMobileMenu)
                    this.currentMobileMenu.style.display = 'none';
                this.body.removeAttribute('style');
                this.currentMobileMenu = null;
            }, this.animationTime);

            document.removeEventListener('keyup', this.tabEventReference);
            document.removeEventListener('keydown', this.escapeEventReference);
        } else {
            this.dropdowns.forEach((dropdown) => {
                const menu = dropdown.querySelector('[mobile-menu="true"]');
                menu.setAttribute('aria-hidden', true);
            });
        }
    }

    // Open

    open() {
        const firstElement = this.currentMobileMenu.querySelector(
            'button.close'
        );

        this.currentMobileMenu.style.display = 'block';
        this.body.style.overflow = 'hidden';
        this.buttonState(this.mobileButtons);

        setTimeout(() => {
            this.currentMobileMenu.setAttribute('aria-hidden', false);
            this.currentMobileMenu.style.transition = `transform 1s`;
            firstElement.focus();
        }, 100);

        document.addEventListener('keyup', this.tabEventReference);
        document.addEventListener('keydown', this.escapeEventReference);
    }

    // Click Event

    openClickEvent(event) {
        const target = event.target;

        const id = target.getAttribute('aria-controls');
        const element = this.mobileMenus.filter(
            (menu) => menu.getAttribute('id') === id
        );

        let time = 0;

        if (this.currentMobileMenu) {
            this.close();
            time = this.animationTime;
        }

        if (element.length > 0) {
            const current = element[0];
            const isActive = current.hasAttribute('active');

            if (isActive === false) {
                setTimeout(() => {
                    this.currentMobileMenu = current;
                    this.currentButton = target;
                    this.open();
                }, time);
            }
        }
    }

    // Close Button Events

    async closeClickEvent(event) {
        const target = event.target;
        const reference = target.getAttribute('reference');
        const button = this.mobileButtons.filter(
            (button) => button.getAttribute('aria-controls') === reference
        );

        this.close();

        if (button.length > 0) {
            setTimeout(() => {
                button[0].focus();
            }, 1100);
        }
    }

    // Render

    render() {
        return (
            <header ref={this.header}>
                <div className="lock">
                    <div className="mobile-actions">
                        <button
                            aria-controls="profile"
                            className="mobile-button"
                            onClick={this.openClickEvent.bind(this)}
                        />
                        <NavLink
                            exact
                            to="/"
                            onClick={this.closeClickEvent.bind(this)}
                        >
                            <img src={logo} alt="Golfweek" />
                        </NavLink>
                        <button
                            aria-controls="menu"
                            className="mobile-button"
                            onClick={this.openClickEvent.bind(this)}
                        />
                    </div>

                    <nav>
                        <div id="menu" className="mobile-menu">
                            <div className="close-wrapper">
                                <button
                                    className="close"
                                    reference="menu"
                                    onClick={this.closeClickEvent.bind(this)}
                                />
                            </div>
                            <NavLink
                                exact
                                activeClassName="active"
                                to="/"
                                onClick={this.closeClickEvent.bind(this)}
                            >
								<PinIcon className="w-8 h-8 block md:hidden fill-current mr-4" />
                                <span>Home</span>
                            </NavLink>
                            <NavLink
                                exact
                                activeClassName="active"
                                to="/search/courses"
                                onClick={this.closeClickEvent.bind(this)}
                            >
								<StarIcon className="w-8 h-8 block md:hidden fill-current mr-4" />
                                <span>Rate Courses</span>
                            </NavLink>
                            <NavLink
                                exact
                                activeClassName="active"
                                to="/search/raters"
                                onClick={this.closeClickEvent.bind(this)}
                            >
								<SwingIcon className="w-8 h-8 block md:hidden fill-current mr-4" />
                                <span>Search Raters</span>
                            </NavLink>
                            <RaterHandbook handbook={this.props.handbook} />
                            <a
                                href="https://golfweek.usatoday.com/raters/golfweeks-best/"
                                target="_blank"
                            >
								<AwardIcon className="w-8 h-8 block md:hidden fill-current mr-4" />
                                <span>Golfweek's Best</span>
                            </a>
                            <a
                                href="http://ratersforum.golfweek.com/"
                                target="_blank"
                            >
								<MessageIcon className="w-8 h-8 block md:hidden fill-current mr-4" />
                                <span>Message Board</span>
                            </a>
                        </div>

                        <Dropdown
                            data={profileDropdown}
                            closeEvent={this.closeClickEvent.bind(this)}
                        />
                    </nav>
                </div>
            </header>
        );
    }
}

export default Header;
