// Imports

import fetch from 'scripts/helpers/fetch';
import { handleRefreshUser } from 'scripts/store/actions/user';

// Variables

const SET_COURSES_NEAR = 'SET_COURSES_NEAR';

// Types

function setCoursesNear(data) {
    return {
        type: SET_COURSES_NEAR,
        payload: data,
    };
}

// Dispatch : Handle Get Event from API

function handleCoursesNear(user) {
    return async (dispatch) => {
    	dispatch(handleRefreshUser(user));
        await fetch(`/courses-near/${user.latitude}/${user.longitude}`, 'GET')
            .then((response) =>
                dispatch(setCoursesNear(response.content.courses))
            )
            .then((response) => response)
            .catch((error) => error);
    };
}

// Exports

export { SET_COURSES_NEAR, handleCoursesNear };
