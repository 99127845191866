// Imports

import React from 'react';

// Components

import CourseOverview from 'scripts/components/CourseOverview';
import ProgressBar from 'scripts/components/ProgressBar';
import TextAreaBallot from 'scripts/components/Ballot/TextArea';
import SelectionBallot from 'scripts/components/Ballot/Selection';
import SliderBallot from 'scripts/components/Ballot/Slider';
import DateBallot from 'scripts/components/Ballot/Date';

// Store

import { connect } from 'react-redux';

// Ballot Entry

class BallotEntry extends React.Component {
    // Render

    render() {
        const ballot = this.props.ballot;
        const key = `ballot-component-${ballot.id}`;
        const entryIndex = this.props.state.findIndex(
            context => context.id === this.props.ballot.id
        );
        let Component;

        if (ballot.type === 'text') {
            Component = (
                <TextAreaBallot
                    key={key}
                    {...this.props}
                    Tag={'course-comment-rating'}
                />
            );
        }

        if (ballot.type === 'categories') {
            Component = (
                <SelectionBallot
                    key={key}
                    {...this.props}
                    Tag={'course-select-rating'}
                />
            );
        }

        if (ballot.type === 'slider') {
            Component = (
                <SliderBallot
                    key={key}
                    {...this.props}
                    Tag={'course-select-rating'}
                />
            );
        }

        if (ballot.type === 'percentageSlider') {
            Component = (
                <SliderBallot
                    key={key}
                    {...this.props}
                    Tag={'course-select-rating'}
                    flag={true}
                />
            );
        }

        if (ballot.type === 'date') {
            Component = (
                <DateBallot
                    key={key}
                    {...this.props}
                    Tag={'course-select-rating'}
                    flag={true}
                />
            );
        }

        return (
            <two-column-layout>
                <first-column>
                    <CourseOverview course={this.props.course} ballot={ballot}/>
                    {Component}
                </first-column>

                <second-column>
                    <ProgressBar data={entryIndex + 1} total={this.props.state.length} />
                </second-column>
            </two-column-layout>
        );
    }
}

export default connect((state) => ({}))(BallotEntry);
