// Imports

import React from 'react';
import { Link } from 'react-router-dom';

// Macro : Ballot Actions

export default function (props) {
	const value = props.page;
	const url = props.url;

	let NextComponent = (
		<Link className="next" to={`/ballot/${url}?entry=${parseInt(value) + 1}`}>
			<span>Next</span>
		</Link>
	);

	if (props.shouldStop) {
		NextComponent = (
			<a className="next opacity-50">
				<span>Next</span>
			</a>
		);
	}

	return (
		<div className="course-form-actions">
			{value > 1 && (
				<Link
					to={{
						pathname: `/ballot/${url}`,
						search: `?entry=${parseInt(value) - 1}`,
						state: { prevEntry: parseInt(value) },
					}}
				>
					<span>Back</span>
				</Link>
			)}

			{value < props.lastEntry && NextComponent}
		</div>
	);
}
