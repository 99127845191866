// Imports

import React from 'react';
import ReactDOM from 'react-dom';

// Loader

class Loader extends React.Component {
    // Render

    render() {
        const showing = this.props.showing ? 'block' : 'none';
        const style = {
            display: showing,
        };

        return (
            <loading-animation>
                <div className="loader" style={style}>
                    <ball-container>
                        <bouncing-ball></bouncing-ball>
                    </ball-container>
                </div>

                {this.props.children}
            </loading-animation>
        );
    }
}

export default Loader;
