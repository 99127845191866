// Imports

import { SET_LOADING } from 'scripts/store/actions/loader.js';

const initialState = {
    loading: false,
};

// Reducers

export default function user(state = initialState, action) {
    if (action.type === SET_LOADING) {
        return Object.assign({}, state, action.payload);
    }

    return state;
}
