// Imports

import React from 'react';

// Components

import UserProfile from 'scripts/components/UserProfile';
import MemberList from 'scripts/components/List/Member';
import ClubList from 'scripts/components/List/Club';
import RegularColumn from 'scripts/components/Columns/Regular';
import DateColumn from 'scripts/components/Columns/Date';
import BallotSummary from 'scripts/components/BallotSummary';

// Store

import { connect } from 'react-redux';

// Helpers

import * as moment from 'moment';
import makeBagArray from 'scripts/helpers/makeBagArray';
import findLastBallot from 'scripts/helpers/findLastBallot';
import makeLocationString from 'scripts/helpers/makeLocationString';

// Profile

class Profile extends React.Component {
	// Render

	render() {
		const user = this.props.user;
		const now = new Date().getTime();
		const userEvents = this.props.events.userEvents.filter((event) => moment(event.end_date).valueOf() < now);
		const bagItems = makeBagArray(this.props.user);
		const lastBallot = findLastBallot(this.props.ballots);
		const location = makeLocationString(
			user.city,
			user.state_name ? user.state_name : user.state,
			user.country_name ? user.country_name : user.country
		);

		const otherLocationTitle = `Other Members near ${location}`;

		return (
			<two-column-layout>
				<first-column>
					<UserProfile
						user={user}
						includeEditActions={true}
						imageRight={false}
						mobileVisible={true}
						includeEmail={false}
					/>

					{bagItems.length > 0 && <ClubList title={"What's in My Bag"} type={'club'} data={bagItems} />}

					<RegularColumn
						title={'Priority Courses'}
						type={'regular'}
						cta={'requesting'}
						data={this.props.requestingRating}
					/>

					<RegularColumn
						title={'My Wish List'}
						type={'regular'}
						cta={'wish-list'}
						data={this.props.wishList}
					/>

					<DateColumn title={'Events Attended'} type={'date'} events={userEvents} cta={'events-attended'} />
				</first-column>

				<second-column>
					<BallotSummary data={lastBallot} />

					{user.courses.map((course) => (
						<MemberList
							key={course.id}
							title={`Other Members of ${course.name}`}
							type={'member'}
							data={this.props.otherMembers.membersByCourse[course.id]}
						/>
					))}

					{location && (
						<MemberList
							title={otherLocationTitle}
							type={'member'}
							data={this.props.otherMembers.membersByLocation}
						/>
					)}
				</second-column>
			</two-column-layout>
		);
	}
}

export default connect((state) => ({
	user: state.user,
	courses: state.courses,
	wishList: state.wishList,
	requestingRating: state.requestingRating,
	otherMembers: state.otherMembers,
	events: state.events,
	ballots: state.ballots,
}))(Profile);
