// Imports

import React from 'react';
import { Link } from 'react-router-dom';
import SignupLink from '../List/SignupLink';

// Macros

import DateWithUnderline from 'scripts/macros/DateWithUnderline';

// Wrappers

import Column from 'scripts/wrappers/Column';

// Helpers

import makeLocationString from 'scripts/helpers/makeLocationString';

// Headline

function Headline(props) {
	const event = props.event;
	const link = props.event.links.self;

	if (link) {
		return (
			<p>
				<Link to={`/course/event/${event.id}`} className="small-headline-sans">
					{event.name}
				</Link>
			</p>
		);
	} else {
		return <p>{event.name}</p>;
	}
}

// Location

function Location(props) {
	const event = props.event;
	const location = makeLocationString(event.course_city, event.course_state, event.course_country_name);

	return <div className="small-text-blocks">{location}</div>;
}

// List

function List(props) {
	return (
		<div>
			{props.events.map((event, i) => {
				return (
					<div className="view-block" block-viewport="small" key={`column-${i}`}>
						<DateWithUnderline data={event} />
						<Headline event={event} />
						<h3 className="small-headline-serif">{event.course_name}</h3>
						<Location event={event} />
						<p>
							<SignupLink endDate={event.end_date} link={event.link} />
						</p>
					</div>
				);
			})}
		</div>
	);
}

// Date Columns

class Date extends React.Component {
	render() {
		const eventsList = Array.from(this.props.events);

		if (eventsList.length > 0) {
			const events = eventsList.slice(0, 4);

			return (
				<Column {...this.props}>
					<List events={events} />
				</Column>
			);
		} else {
			return null;
		}
	}
}

export default Date;
